export const basics = {
  state: {
     de:{
      routeTags:{
        balance: 'Balance',
        dyno: 'Dyno',
        ueberhang: 'Überhang',
        sloper: 'Sloper',
        mono: 'Mono',
        leisten: 'Leisten',
        reibung: 'Reibung',
        zange: 'Zange',
        kraft: 'Kraft',
        kompression: 'Kompression',
        koordination: 'Koordination',
        kraftausdauer: 'Kraftausdauer',
        traverse: 'Traverse'
      },
      gymTags:{
        bouldern: 'Bouldern',
        klettern: 'Klettern',
        aussenbereich: 'Außenbereich',
        indoor: 'Indoor',
        outdoor: 'Outdoor',
        kinderbereich: 'Kinderbereich',
        trainingsbereich: 'Trainingsbereich',
        shop: 'Shop',
        sauna: 'Sauna',
        speed: 'Speed',
        toprope: 'Toprope',
        spraywall: 'Spraywall',
        moonboard: 'Moonboard',
        kilter_board: 'Kilter Board',
        ninja: 'Ninja'
      },
      scales:{
        farben: 'Farben',
        yds: 'YDS',
        franzoesisch: 'Französisch',
        numerisch: 'Numerisch',
        uiaa: 'UIAA',
        fb: 'FB',
        saechsisch: 'Sächsisch'
      },
      colors:{
        weiss: 'Weiß',
        gelb: 'Gelb',
        gruen: 'Grün',
        blau: 'Blau',
        rot: 'Rot',
        schwarz: 'Schwarz',
        lila: 'Lila',
        pink: 'Pink',
        orange: 'Orange',
        grau: 'Grau',
        tuerkis: 'Türkis',
        braun: 'Braun',
        volumen_weiss: 'Volumen Weiß',
        volumen_gelb: 'Volumen Gelb',
        volumen_gruen: 'Volumen Grün',
        volumen_blau: 'Volumen Blau',
        volumen_rot: 'Volumen Rot',
        volumen_schwarz: 'Volumen Schwarz',
        volumen_lila: 'Volumen Lila',
        volumen_pink: 'Volumen Pink',
        volumen_orange: 'Volumen Orange',
        volumen_grau: 'Volumen Grau',
        volumen_tuerkis: 'Volumen Türkis',
        volumen_braun: 'Volumen Braun'
      }
    },
    en:{
      routeTags:{
        balance: 'Balance',
        dyno: 'Dyno',
        ueberhang: 'Roof',
        sloper: 'Sloper',
        mono: 'Pocket',
        leisten: 'Crimp',
        reibung: 'Slab',
        zange: 'Pinch',
        kraft: 'Power',
        kompression: 'Compression',
        koordination: 'Coordination',
        kraftausdauer: 'Strength endurance',
        traverse: 'Traverse'
      },
      gymTags:{
        bouldern: 'Bouldering',
        klettern: 'Climbing',
        aussenbereich: 'Outdoor area',
        indoor: 'Indoor',
        outdoor: 'Outdoor',
        kinderbereich: 'Kids area',
        trainingsbereich: 'Training area',
        shop: 'Shop',
        sauna: 'Sauna',
        speed: 'Speed',
        toprope: 'Toprope',
        spraywall: 'Spraywall',
        moonboard: 'Moonboard',
        kilter_board: 'Kilter Board',
        ninja: 'Ninja'
      },
      scales:{
        farben: 'Colors',
        yds: 'YDS',
        franzoesisch: 'French',
        numerisch: 'Numeric',
        uiaa: 'UIAA',
        fb: 'FB',
        saechsisch: 'Saxon'
      },
      colors:{
        weiss: 'White',
        gelb: 'Yellow',
        gruen: 'Green',
        blau: 'Blue',
        rot: 'Red',
        schwarz: 'Black',
        lila: 'Purple',
        pink: 'Pink',
        orange: 'Orange',
        grau: 'Gray',
        tuerkis: 'Turquoise',
        braun: 'Brown',
        volumen_weiss: 'Volumes white',
        volumen_gelb: 'Volumes yellow',
        volumen_gruen: 'Volumes green',
        volumen_blau: 'Volumes blue',
        volumen_rot: 'Volumes red',
        volumen_schwarz: 'Volumes black',
        volumen_lila: 'Volumes purple',
        volumen_pink: 'Volumes pink',
        volumen_orange: 'Volumes orange',
        volumen_grau: 'Volumes gray',
        volumen_tuerkis: 'Volumes turquoise',
        volumen_braun: 'Volumes brown'
      }
    }
  }
};
