export const explore = {
  state: {
    de:{
      intro: 'ERKUNDEN',
      sub:'Finde deine Halle oder Klettergebiet.',
      search: 'Halle suchen..',
      searchResults:'SUCHERGEBNISSE',
      places: 'ORTE',
      mapView: 'ZUR KARTENANSICHT',
      addGym: 'Neue Halle hinzufügen',
      newGym: "Neue Halle",
      loadMore: 'Mehr Hallen laden',
      showMore: 'Mehr Ergebnisse anzeigen',
      empty: 'Deine Halle ist nicht dabei? Klicke oben rechts auf das Bearbeiten-Icon um eine neue Halle hinzuzufügen.'
    },
    en:{
      intro: 'EXPLORE',
      sub:'Find your gym or climbing spot',
      search: 'Search gyms..',
      searchResults:'RESULTS',
      places: 'PLACES',
      mapView: 'GO TO MAPVIEW',
      addGym: 'Create new gym',
      newGym: "New Gym",
      loadMore: 'Load more results',
      showMore: 'Show more results',
      empty: 'Nothing found? Click on the pencil icon at the top right corner to create your gym.'
    }
  }
};
