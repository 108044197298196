<template>
  <div v-if="typeof route !== 'undefined'">
  
  
    <!-- BACK BUTTON -->
    <div id="back" class="fixed top-0 left-0 ml-4 mt-4 w-9 h-9 z-20 flex flex-row justify-center items-center" @click="this.$router.push({ path: '/gyms/' + this.$route.params.gymId + '/walls/' + this.$route.params.wallId })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 stroke-current stroke-2 text-purple-600">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </div> 
    <!-- BACK BUTTON -->
    

    <div id="inside">
      <div class="mt-20 flex flex-col justify-center items-center">
      
        <!-- ROUTE IMAGE -->
        <div class="relative w-36 h-36 mb-6">
          <div class="absolute w-full h-full bg-local bg-center bg-no-repeat bg-contain rounded-full shadow-md" 
              v-bind:style="{ backgroundImage: 'url(' + require('../assets/holds/' + umlaut(route.color) + '.jpg') + ')' }"></div>
          <select v-if="editMode" v-model="selectedColor" class="w-full absolute inset-0 appearance-none opacity-0">
            <option disabled value="">{{lang.holdColor}}</option>
            <option v-for="color in holdColors" :value="color">
              {{translateBasics('Colors', color)}}
            </option>
          </select>
          <div class="transition duration-500 ease-in-out w-full h-full rounded-full" 
            :class="{ ' transition duration-500 ease-in-out ring-3 ring-offset-12 ring-yellow-300': editMode }"></div>
          <div class="transition duration-500 ease-in-out absolute bottom-1 right-1 w-7 h-7 rounded-full" 
            :class="{'transition duration-500 ease-in-out ring-3 ring-offset-8 ring-yellow-300': editMode }">
            <div v-if="route.level != 'Level' && route.levelScale !== 'custom'" 
              class="w-full h-full rounded-full shadow-c ring-2 ring-purple-200 bg-white flex flex-row justify-center items-center">
              <div class=" font-ageo font-semibold text-base text-center text-purple-600">{{ route.level }}</div>
            </div>
            <div v-else-if="route.level != 'Level' && route.levelScale == 'custom' && customColors.findIndex(x => x.color === route.level) !== -1" class="w-full h-full rounded-full shadow-c flex flex-row justify-center items-center"
              :style='{backgroundColor : customColors[customColors.findIndex(x => x.color === route.level)].hex }'>
            </div>
            <div v-else-if="route.level == 'Level'" class="w-full h-full rounded-full shadow-c flex flex-row justify-center items-center bg-white">
              <div v-if="editMode">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-purple-600" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </div>
            </div>
            <div v-if="route.level != 'Level' && route.levelScale == 'custom' && customColors.findIndex(x => x.color === route.level) == -1" class="w-full h-full rounded-full shadow-c ring-2 ring-purple-200 bg-white flex flex-row justify-center items-center">
              <div class=" font-ageo font-semibold text-base text-center text-purple-600">{{ route.level }}</div>
            </div>
                  
            <select v-if="editMode" v-model="selectedLevel" class="w-full absolute inset-0 appearance-none opacity-0">
              <optgroup :label="lang.scaleInfo"></optgroup>
              <optgroup v-if="customlevels.length !== 0" :label="gym.name + lang.scale">
                <option v-for="customlevel in customlevels" :value="{scale: 'custom', level: customlevel}">
                {{translateBasics('Colors', customlevel)}}
                </option>
              </optgroup>
              <optgroup v-if="levels.length !== 0" :label="gym.scale">
                <option v-for="level in levels" :value="{scale: gym.scale, level: level}">
                {{ level }}
                </option>
              </optgroup>
            </select>
          </div>
        </div>
        <!-- ROUTE IMAGE -->
       
       
        <div class="w-screen flex flex-col justify-center items-center px-6">
          <div class="relative font-ageo font-normal text-4xl text-gray-800 text-center w-full mt-5" >
            
            
             <!-- ROUTE INFOS -->
              <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false" mode="out-in">
                <div v-if="!editMode">
                  <div v-if="route.name" class="font-ageo font-normal text-2xl text-gray-800">{{route.name}}</div>
                  <div class="min-h-9  flex flex-row flex-wrap justify-center items-center font-ageo font-normal text-2xl text-gray-800">
                    <div v-if="route.tags" v-for="(tag,index) in route.tags" :class="{'text-lg text-gray-400': route.name}">
                      <span v-if="index < 2 && !showAllTags">{{tag}}</span>
                      <span v-if="index < 1 && route.tags.length > 1 && !showAllTags">,&nbsp;</span>
                      <span v-if="index == 2 && route.tags.length >= 2 && !showAllTags">..</span>
                      <span v-if="showAllTags">{{tag}}</span>
                      <span v-if="index < route.tags.length-1  && showAllTags">,&nbsp;</span>
                    </div>
                    <div v-else>{{lang.selectType}}</div>
                    <div v-if="route.tags && route.tags.length > 2 && !showAllTags" class="font-ageo text-xl font-normal ml-3 text-pink-600" @click="showAllTags = true">+{{route.tags.length - 2}}</div>
                  </div>
                </div>
              </transition>
              <!-- ROUTE INFOS -->

            <!-- EDIT MODE ROUTE DETAILS WRAPPER -->
            <transition @enter="editButtonsEnter" @leave="editButtonsLeave" :css="false" mode="out-in">
            <div v-if="editMode">
              
              
            <!-- ROUTE NAME EDIT -->
            <section class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5 overflow-hidden">
              <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.routeName}}</div>
              <div class="flex flex-row justify-between items-center h-full">
                <input v-model="newRouteName" :placeholder="route.name || lang.nameRoute" type="text" 
                  class="h-full px-4 w-full appearance-none outline-none text-base text-purple-600 placeholder-purple-600 text-center"
                  :class="{'w-4/6 text-left' : newRouteName !== ''}"
                  @keyup.enter="changeRouteName(newRouteName)">
                <div v-if="newRouteName !== ''" class="flex flex-row items-center">
                  <div class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="changeRouteName(newRouteName)">
                    <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="newRouteName = ''">
                    <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="resetRouteName()">
                    <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="text-red-400 font-ageo font-normal mt-2 mx-4"> {{ errorMessageName }} </div>
            </section>
            <!-- ROUTE NAME EDIT -->
            
            
            <!-- ROUTE TAG EDIT -->
            <section class="w-full mt-2">
              <div class="w-full font-ageo text-normal text-purple-600 h-9 mt-2 relative flex flex-row justify-between items-center bg-white ring-2 ring-yellow-300 rounded">
                <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-normal text-gray-300">{{lang.tags}}</div>
                <div class="relative h-full w-full flex flex-row justify-center items-center">
                  <span class="absolute text-base">{{translateBasics('RouteTags', routeTags)}}</span>
                  <select v-model="tags" multiple class="w-full absolute inset-0 appearance-none opacity-0">
                    <optgroup>{{lang.routeTags}}</optgroup>
                    <option v-for="type in types" :value="type">
                    {{translateBasics('RouteTags', type)}}
                    </option>
                  </select>
                </div>
              </div>
            </section>
            <!-- ROUTE TAG EDIT -->
          
          
            <!-- ROUTE HOLDS EDIT -->
            <section class="relative font-ageo font-normal text-4xl text-gray-800 text-center w-full mt-5" >
              <div class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5 overflow-hidden">
                <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.holds}}</div>
                <div class="flex flex-row justify-between items-center h-full">
                  <input v-model="holdNames" :placeholder="route.holdNames || lang.holdPlaceholder" type="text" 
                    class="h-full px-4 w-full appearance-none outline-none text-base text-purple-600 placeholder-purple-600 text-center"
                    :class="{'w-4/6 text-left' : holdNames !== ''}"
                    @keyup.enter="changeHoldNames(holdNames)">
                  <div v-if="holdNames !== ''" class="flex flex-row items-center">
                    
                    <div class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="changeHoldNames(holdNames)">
                      <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="holdNames = ''">
                      <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="text-red-400 font-ageo font-normal mt-2 mx-4"> {{ errorMessageHolds }} </div>
              </div>
            </section>
            <!-- ROUTE HOLDS EDIT -->
            
            
            <!-- ROUTE KIDS EDIT -->
            <section class="relative font-ageo font-normal text-4xl text-gray-800 text-center w-full mt-5" >
              <div class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5">
                <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.kids}}</div>
                <div class="flex flex-row justify-center items-center h-full">
                  <div class="font-ageo font-normal text-base text-purple-600 mr-10" :class="{'font-semibold': route.kids == 'Kinder'}" @click="updateKids('Kinder')">{{lang.kids2}}</div>
                  <div class="font-ageo font-normal text-base text-purple-600" :class="{'font-semibold': route.kids == 'Kleinkinder'}" @click="updateKids('Kleinkinder')">{{lang.kids3}}</div>
                </div>
                <div class="text-red-400 font-ageo font-normal mt-2 mx-4"> {{ errorMessageHolds }} </div>
              </div>
            </section>
            <!-- ROUTE KIDS EDIT -->
              
              
            </div>
            </transition>
            <!-- EDIT MODE ROUTE DETAILS WRAPPER -->
            
            
            
              
             
              
              
            
              
            
          </div>
        </div>
      </div>
      <div class="font-ageo mt-10">
      
      
        <!-- ROUTE BUTTONS -->
        <div class="w-screen flex flex-row px-5">
          <div class="button" :class="{ active: route.flashedBy.includes(user.fId)}" @click="click('Flash')">
            <div class="buttonText">{{lang.flash}}</div>
            <div class="buttonText2">{{ route.flashedBy.length }} {{lang.persons}}</div>
          </div>
          <div class="button" :class="{ active: route.toppedBy.includes(user.fId)}" @click="click('Top')">
            <div class="buttonText">{{lang.top}}</div>
            <div class="buttonText2">{{ route.toppedBy.length }} {{lang.persons}}</div>
          </div>
        </div>
        <!-- ROUTE BUTTONS -->
      
      
        <!-- ROUTE RANKING -->
        <transition name="fade" mode="out-in">
        <div v-if="route.flashedBy.includes(user.fId) || route.toppedBy.includes(user.fId)" class="mt-8 flex flex-col items-center justify-center">
          <div class="font-ageo font-normal text-gray-800">{{lang.feedback}}</div>
          <div class="flex flex-row justify-center items-center mb-1">
            <div v-for="(rate,index) in ratings">
              <div @click="changeRating(index+1)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-purple-600" :class="{ 'fill-current': index+1 <= currentRating}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="font-ageo font-normal text-gray-800 mt-5">{{lang.feedback2}}</div>
          <div class="mb-1 w-full flex flex-row justify-around items-center px-10 font-ageo font-normal text-purple-600">
            <div v-for="option in Tendenzen" @click="changeTendenz(option)">
              <div :class="{'font-bold' : currentTendenz == option}">{{checkTendency(option)}}</div>
            </div>
          </div>
        </div>
        </transition>
        <!-- ROUTE RANKING -->
      
      
      <!-- ROUTE DETAILS -->
      <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-1 mt-12 mx-6">{{lang.details}}</div>
      
      
      <!-- DETAILS TOP & FLASH RATE -->
      <div class=" border border-b-0 border-r-0 border-l-0 border-gray-150 mx-6 flex flex-row">
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.begehungen}}</div>
          <div class="flex flex-row items-baseline">
            <div class="font-ageo font-semibold text-gray-800 text-xl">{{this.total}}</div>
            <div v-if="total > 0" class="font-ageo font-normal text-xs text-purple-600 ml-1" @click="showFinisher = !showFinisher">
              <p v-if="!showFinisher">{{lang.showAll}}</p>
              <p v-else="showFinisher">{{lang.hideAll}}</p>
            </div>
          </div>
        </div>
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.flashRate}}</div>
          <div v-if="total && total !== 0" class="font-ageo font-semibold text-gray-800 text-xl">{{this.flashRate}}%</div>
          <div v-else class="font-ageo font-semibold text-gray-800 text-xl">-</div>
        </div>
      </div>
      <!-- DETAILS TOP & FLASH RATE -->
      
      
      <!-- DETAILS FINISHER -->
      <div v-if="showFinisher" class="mx-6">
        <div class="flex flex-row mt-3">
          <div class="font-ageo font-semibold w-1/2"> {{lang.top}}
            <div v-for="topper in route.toppedBy">
              <div v-if="gym.activeUsers[topper]" class="flex flex-row items-center mt-2">
                <div v-if="gym.activeUsers[topper].profile == 'emoji'" class="rounded-full w-6 h-6 bg-yellow-200 p-1 font-ageo text-xs flex flex-row justify-center item-center">{{gym.activeUsers[topper].emoji}}</div>
                <div v-else-if="userImage[userImage.findIndex(x => x.user === topper)]" class="rounded-full w-6 h-6 p-1 bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' +  userImage[userImage.findIndex(x => x.user === topper)].image  + ')' }"></div>
                <div class="ml-2 font-ageo font-normal">{{gym.activeUsers[topper].name}}</div>
              </div>
            </div>
          </div>
          <div class="font-ageo font-semibold w-1/2"> {{lang.flash}}
            <div v-for="flasher in route.flashedBy">
              <div v-if="gym.activeUsers[flasher]" class="flex flex-row items-center mt-2">
                <div v-if="gym.activeUsers[flasher].profile == 'emoji'" class="rounded-full w-6 h-6 bg-yellow-200 p-1 font-ageo text-xs flex flex-row justify-center item-center">{{gym.activeUsers[flasher].emoji}}</div>
                <div v-else-if="userImage[userImage.findIndex(x => x.user === flasher)]" class="rounded-full w-6 h-6 p-1 bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' +  userImage[userImage.findIndex(x => x.user === flasher)].image  + ')' }"></div>
                <div class="ml-2 font-ageo font-normal">{{gym.activeUsers[flasher].name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DETAILS FINISHER -->
      
      
      <!-- DETAILS LEVEL & TENDENCY -->
      <div class="mx-6 mt-3 flex flex-row">
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.level}}</div>
          <div class="font-ageo font-semibold text-gray-800 text-xl flex flex-row">
            <p v-if="route.levelScale !== 'custom' && route.level && route.level !== 'Level'" class="mr-1">{{route.levelScale}} / </p>
            <p v-if="route.level && route.level !== 'Level'">{{translateBasics('Colors', route.level)}}</p>
            <p v-if="route.level && route.level == 'Level'">{{lang.noLevel}}</p>
          </div>
        </div>
        
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.tendenz}}</div>
          <div class="font-ageo font-semibold text-gray-800 text-xl">{{checkTendency(tendenz)}}</div>
        </div>
      </div>
      <!-- DETAILS LEVEL & TENDENCY -->
      
      
      <!-- DETAILS HOLDS & KIDS -->
      <div class="mx-6 mt-3 flex flex-row">
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.holds}}</div>
          <div class="font-ageo font-semibold text-gray-800 text-xl">{{this.route.holdNames || '-'}}</div>
        </div>
        
        <div class="w-1/2 pt-3">
          <div class="flex flex-row">
            <div class="font-ageo font-normal text-gray-800">{{lang.forKids}}</div>
            <div class="rounded-full w-4 h-4 border border-purple-600 text-center font-ageo font-semibold text-xs text-purple-600 ml-2" @click="showKidsText = !showKidsText">i</div>
          </div>
          <div class="font-ageo font-semibold text-gray-800 text-xl">{{this.route.kids || '-'}}</div>
        </div>
      </div>
      <!-- DETAILS HOLDS & KIDS -->
      
      
      <!-- DETAILS KIDS INFO -->
      <transition name="fade" mode="out-in">
        <div v-if="showKidsText" class="mx-6 mt-3 font-ageo">
        {{lang.kidsInfo}}
        </div>
      </transition>
      <!-- DETAILS KIDS INFO -->
      
      
      <!-- DETAILS POINTS -->
      <div class="mx-6 mt-3 flex flex-row">
        <div class="w-1/2 pt-3">
          <div class="flex flex-row">
            <div class="font-ageo font-normal text-gray-800">{{lang.points}}</div>
            <div class="rounded-full w-4 h-4 border border-purple-600 text-center font-ageo font-semibold text-xs text-purple-600 ml-2" @click="changeInfoText('points')">i</div>
          </div>
          <div v-if="total && total !== '0'" class="font-ageo font-semibold text-gray-800 text-xl">{{points}}</div>
          <div v-else class="font-ageo font-semibold text-gray-800 text-xl">1000</div>
        </div>
        <div class="w-1/2 pt-3">
          <div class="flex flex-row">
            <div class="font-ageo font-normal text-gray-800">{{lang.bonus}}</div>
            <div class="rounded-full w-4 h-4 border border-purple-600 text-center font-ageo font-semibold text-xs text-purple-600 ml-2" @click="changeInfoText('bonus')">i</div>
          </div>
          <div v-if="total && total !== '0'" class="font-ageo font-semibold text-gray-800 text-xl">{{flashPoints}}</div>
          <div v-else class="font-ageo font-semibold text-gray-800 text-xl">-</div>
        </div>
      </div>
      <!-- DETAILS POINTS -->
      
      
      <!-- DETAILS POINTS INFO -->
      <transition name="fade" mode="out-in">
      <div v-if="infoText == 'points'" class="mx-6 mt-3 font-ageo">
      {{lang.pointsInfo}} {{route.toppedBy.length + route.flashedBy.length}} {{lang.pointsInfo2}} {{Math.round(1000 / (route.toppedBy.length + route.flashedBy.length))}} {{lang.pointsInfo3}}
      </div>
      <div v-else-if="infoText == 'bonus'" class="mx-6 mt-3 font-ageo">
      {{lang.bonusInfo}}
      </div>
      </transition>
      <!-- DETAILS POINTS INFO -->
      
      
      <!-- DETAILS POINTS & RATING -->
      <div class="mx-6 mt-3 flex flex-row">
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.score}}</div>
          <div class="font-ageo font-semibold text-gray-800 text-xl">{{userPoints}}</div>
        </div>
        
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.rating}}</div>
          <div class="flex flex-row items-baseline">
            <div class="font-ageo font-semibold text-gray-800 text-xl">{{totalRating}}</div>
            <div v-if="ratingCount > 0" class="font-ageo font-normal text-xs text-gray-800 ml-1">({{ratingCount}} {{lang.ratings}})</div>
          </div>
        </div>
      </div>
      <!-- DETAILS POINTS & RATING -->
      
      
      <!-- DETAILS CREATED & AGE -->
      <div class="mx-6 mt-3 flex flex-row">
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.createdOn}}</div>
          <div v-if="route.created" class="font-ageo font-semibold text-gray-800 text-xl">
            <div>{{route.created.toDate().toLocaleDateString('de-DE', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' })}}</div>
            <div v-if="route.createdBy" class="font-ageo font-normal text-gray-500 text-sm leading-3">{{lang.by}} {{createdByUser}}</div>            
          </div>
          <div v-else class="font-ageo font-semibold text-gray-800 text-xl">-</div>
        </div>
        
        <div class="w-1/2 pt-3">
          <div class="font-ageo font-normal text-gray-800">{{lang.age}}</div>
          <div v-if="route.created" class="font-ageo font-semibold text-gray-800 text-xl">{{age}}</div>
          <div v-else class="font-ageo font-semibold text-gray-800 text-xl">-</div>
        </div>
      </div>
      <!-- DETAILS CREATED & AGE -->
      
      
      <!-- ROUTE IMAGE -->
      <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-1 mt-12 mx-6">{{lang.image}}</div>
      <div class="border border-b-0 border-r-0 border-l-0 border-gray-150 mx-6"></div>
      <div v-if="route.routeImage && !route.routeImage.includes('placeholder')" class="font-ageo font-normal text-gray-800 mt-3 mx-6 flex flex-row justify-between">
        <div v-if="!deleteThisImage">{{lang.routeImage}}</div>
        <div v-if="!deleteThisImage" class="text-purple-600" @click="deleteThisImage = !deleteThisImage">{{lang.deleteImage}}</div>
        <div v-else>{{lang.sec}}</div>
        <div v-if="deleteThisImage" class="flex flex-row items-center">
          <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="deleteThisImage = false">
            <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="deleteImage()">
            <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        </div>
      </div>
      <div v-else class="font-ageo font-normal text-gray-800 mt-3 mx-6">{{lang.imageInfo}}</div>
      <div v-if="uploading" class="mx-6 font-semibold">{{lang.fotoUploadInfo}} {{Math.round(uploadProgress)}}%</div>
      <div class="relative  mx-6  mt-2 flex flex-row justify-center items-center">
        <div v-if="route.routeImage && !route.routeImage.includes('placeholder')" 
          class="w-full h-96 rounded-xl bg-center bg-no-repeat bg-cover"
          :style="{ backgroundImage: 'url(' +  route.routeImage  + ')' }"></div>
        <div v-else class="h-12 px-3 rounded-lg bg-gray-800 flex flex-row justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          <div class="pl-2 font-ageo font-normal text-base text-white">{{lang.fotoUpload}}</div>
        </div>
        <input v-if="route.routeImage && route.routeImage.includes('placeholder')" type="file" accept="image/*" class="absolute w-full h-full appearance-none inset-0 opacity-0" @change="changeImage($event)">
      </div>
      <!-- ROUTE IMAGE -->


      <!-- ROUTE COMMENTS -->
      <div class="mx-6 mt-10">
        <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-1 mt-8">{{lang.comments}}</div>
        <div class=" border border-b-0 border-r-0 border-l-0 border-gray-150 mb-5"></div>
        <div v-for="(chat,chatIndex) in route.discussion" class="mb-3">
          <div class="flex flex-row w-full justify-start items-end" :class="{ 'flex-row-reverse' : flipArray[chatIndex] == 'Flipped'  }">
            <div class="chatUser ring-current" :class="{'ml-3 mr-0' : flipArray[chatIndex] == 'Flipped' }" :style='{backgroundColor : colorArray[colorArray.findIndex(x => x.user === chat.user)].light, color : colorArray[colorArray.findIndex(x => x.user === chat.user)].light }'>
              <div v-if="checkUserType(chat.user) == 'emoji'" class="font-ageo font-semibold text-green-600 text-lg" :style='{color : colorArray[colorArray.findIndex(x => x.user === chat.user)].strong }'>{{getEmoji(chat.user)}}</div>
              <div v-else-if="userImage[userImage.findIndex(x => x.user === chat.user)]" class="w-full h-full bg-local bg-center bg-no-repeat bg-cover rounded-full" :style="{ backgroundImage: 'url(' +  userImage[userImage.findIndex(x => x.user === chat.user)].image  + ')' }"></div>
            </div>
            <div class="chatBubble relative" :class="{'rounded-bl-xl rounded-br-none' : flipArray[chatIndex] == 'Flipped' }" :style='{backgroundColor : colorArray[colorArray.findIndex(x => x.user === chat.user)].light, color : colorArray[colorArray.findIndex(x => x.user === chat.user)].strong }' >
              {{chat.text}}
              <div v-if="route.discussion[chatIndex+1] && chat.user !== route.discussion[chatIndex+1].user || chatIndex == route.discussion.length-1" class="w-full absolute left-2 -bottom-7 font-ageo font-normal text-sm text-gray-200 leading-6">{{chat.time.toDate().toLocaleDateString()}}</div>
            </div>
          </div>
          <div v-if="route.discussion[chatIndex+1] && chat.user !== route.discussion[chatIndex+1].user" class="mb-10"></div>
        </div>
      </div>
      <div class="w-full mt-10">
        <div class="mx-5 h-12 flex flex-row justify-between items-center bg-gray-100 rounded-xl overflow-hidden">
          <input v-model="comment" :placeholder="lang.commentPlaceholder" class="appearance-none outline-none px-3 bg-gray-100 font-ageo font-normal text-md text-gray-800 flex-grow">
          <svg class="w-4 h-4 mx-3 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="sendComment(comment)" @keyup.enter="sendComment(comment)">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
          </svg>
        </div>
      </div>
      <!-- ROUTE COMMENTS -->


      <!-- ROUTE DELETE -->
      <transition name="fade" mode="out-in">
      <div v-if="!toggleDelete && editMode" class="w-screen px-5">
        <div class="w-full h-16 flex flex-row items-center justify-center bg-yellow-300 rounded-xl mt-5" @click="toggleDelete = true">
          <div class="font-ageo font-semibold text-lg">{{lang.deleteRoute}}</div>
        </div>
      </div>
      <div v-else-if="toggleDelete && editMode" class="w-screen px-6">
        <div class="w-full h-16 flex flex-row items-center justify-around bg-white ring-2 ring-yellow-300 rounded-xl mt-5">
          <div class="font-ageo font-semibold text-lg text-purple-600">{{lang.sec}}</div>
          <div class="flex flex-row">
            <div class="w-7 h-7 rounded-full bg-purple-200 flex flex-row justify-center items-center" @click="toggleDelete = false">
              <svg class="text-purple-600 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <div class="w-7 h-7 ml-6 rounded-full bg-red-200 flex flex-row justify-center items-center" @click="deleteRoute()">
              <svg class="text-red-500 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </div>
          </div>
        </div>
        <div class="font-ageo text-red-400 px-4 text-center font-normal mt-3">{{ errorMessageRoute || lang.error1 }}</div>
      </div>
      </transition>
      <!-- ROUTE DELETE -->


      </div>
    </div>
    
    
    <!-- BOTTOM SPACE -->
    <div class="h-24 mt-24"></div>
    <!-- BOTTOM SPACE -->
  
  
  </div>
</template>

<script>
import { db } from '../firestore.js';
import { firebaseRoot } from '../firestore.js';
import { storage } from '../firestore.js';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'routes',
  props: ['routeId','currentRouteIndex'],
  data(){
    return{
      selectedType: 'Typ auswählen',
      showKidsText: false,
      userImage:[],
      deleteThisImage: false,
      newRouteName:'',
      holdNames:'',
      infoText:'',
      comment:'',
      ratings: [ 1, 2, 3, 4, 5 ],
      Tendenzen:['Leicht', 'Normal', 'Schwer'],
      tags:['Tags auswählen'],
      selectedColor: 'Farbe',
      selectedLevel: {scale: 'scale', level: 'level'},
      toggleDelete: false,
      showAllTags: false,
      showFinisher:false,
      errorMessageRoute:'',
      errorMessageName:'',
      errorMessageHolds:'',
      colors: [{
        light: '#A7F3D0',
        strong: '#059669'
      },{
        light: '#BFDBFE',
        strong: '#2563EB'
      },{
        light: '#FDE68A',
        strong: '#D97706'
      },{
        light: '#C7D2FE',
        strong: '#4F46E5'
      },{
        light: '#FBCFE8',
        strong: '#DB2777'
      },{
        light: '#DDD6FE',
        strong: '#7C3AED'
      },]
    };
  },
  mounted(){
    this.$store.commit('resetMoveBackwards');
    window.scrollTo(0, 0);
    if(this.gym.activeUsers){
      const stack = this.gym.activeUsers;
      var that = this;
      Object.keys(stack).forEach(function (key, index) {
       var item = stack[key];
           var pathReference = storage.ref(item.image);
             pathReference.getDownloadURL()
              .then((url) => {
                that.userImage.push({
                  user: item.user,
                  image: url
                });
              })
              .catch((error) => {
                console.log("Possible case of deleted user fragments");
                that.$store.dispatch('checkUserFragments', {fId: item.user, gymId: that.gym.id});
              });
     });
    }
    if(typeof this.currentRouteIndex !== 'undefined'){
      this.$store.commit('setLastRouteIndex', this.currentRouteIndex);
    }
    if (this.route.tags){
      this.tags = this.route.tags;
    }
    if (this.route.color){
      this.selectedColor = this.route.color;
    }
    if (this.route.level && this.route.level !== 'Level'){
      this.selectedLevel = {scale: this.route.levelScale, level: this.route.level};
    }
  },
  watch: {
    moveBackwards: function(){
      if(this.moveBackwards){
        this.$router.push({ path: '/gyms/' + this.$route.params.gymId + '/walls/' + this.$route.params.wallId });
        }
    },
     selectedType: function () {
       return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
         type: this.selectedType
       });
     },
     tags: function () {
       return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
          tags: this.tags
        });
     },
      selectedColor: function () {
        return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
          holdColor: this.selectedColor
        });
      },
       selectedLevel: function () {
        return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
          level: this.selectedLevel.level,
          levelScale: this.selectedLevel.scale
        });
      }

  },
  computed: {
    lang(){
      return this.$store.state.lang.routes[this.$store.state.lang.currentLang];
    },
    langBasicRouteTags(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].routeTags;
    },
    langBasicColors(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].colors;
    },
    moveBackwards(){
      return this.$store.state.data.moveBackwards;
    },
    currentRating(){
      if (this.route.rating && this.route.rating[this.user.fId]){
      return this.route.rating[this.user.fId].rating;
      } else {
        return 0;
      }
    },
    currentTendenz(){
      if (this.route.tendenz && this.route.tendenz[this.user.fId]){
      return this.route.tendenz[this.user.fId].tendenz;
      } else {
        return '';
      }
    },
    age(){
      var days = Math.ceil((this.route.created.toDate() - new Date()) / (1000 * 3600 * 24 * -1));
      if (days > 1){
        return days + this.lang.days;
      } else {
        return days + this.lang.day;
      }
    },
    createdByUser(){
     return this.gym.activeUsers[this.route.createdBy].name;
    },
    customColors(){
      var colorIndex = this.$store.state.data.basics.customLevels.findIndex(x => x.name === "Farben");
      return this.$store.state.data.basics.customLevels[colorIndex].level;
    },
    colorArray(){
      var colorByUser = [];
      var ii = 0;
      if(this.flatUserArray){
        for(var i=0; i < this.flatUserArray.length; i++){
          if (ii > this.colors.length-1){
            ii = 1;
          }
          colorByUser.push({
            user: this.flatUserArray[i].user,
            light: this.colors[ii].light,
            strong: this.colors[ii].strong
          });
          ii++;
        }
      }

      return colorByUser;
    },
    flatUserArray(){
      if(this.route.discussion){
      function xy(data, key){
        return[
          ...new Map(
            data.map(x => [key(x), x])
          ).values()
        ];
      }
        return xy(this.route.discussion, chat => chat.user);
      }
    },
    flipArray(){
      var before = "Normal";
      var flippedArray = [];
      for(var i=0; i < this.route.discussion.length; i++){
        if (i > 0 && this.route.discussion[i].user.localeCompare(this.route.discussion[i-1].user) !== 0){
          //Wenn nicht der selbe Nutzer
          if (before == "Flipped"){
            //wenn vorher geflipped
            before = "Normal";
            flippedArray.push(before);
          } else {
            //wenn vorher normal
            before = "Flipped";
            flippedArray.push(before);
          }

        } else {
          //Wenn der selbe Nutzer
          flippedArray.push(before);
        }
      }
      return flippedArray;
    },
    routeTags(){
      var newTags = '';
      if (this.route.tags && this.route.tags.length > 0){
        newTags = this.route.tags[0];
        if (this.route.tags.length > 1){
          newTags = newTags + ", " + this.route.tags[1];
          if (this.route.tags.length > 2){
            newTags = newTags + "..";
          }
        }
      } else {
        newTags = this.lang.selectRouteTags;
      }
      return newTags;
    },
    gym(){
        return this.$store.state.data.homeGym;
    },
    wall(){
      return this.$store.state.data.walls[this.$store.state.data.orientation.currentWallIndex];
    },
    types(){
      return this.$store.state.data.basics.types;
    },
    holdColors(){
      return this.$store.state.data.basics.holdColors;
    },
    levels(){
      var index = this.$store.state.data.basics.levels.findIndex(x => x.name === this.gym.scale);
      if (index !== -1){
        return this.$store.state.data.basics.levels[index].level;
      } else {
        return [];
      }
    },
    customlevels(){
      return this.$store.state.data.homeGym.customScale;
    },
    uploading(){
      return this.$store.state.data.uploadingImage;
    },
    uploadProgress(){
      return this.$store.state.data.uploadProgress;
    },
    orientationRouteIndex(){
      return this.$store.state.data.orientation.currentRouteIndex
    },
    route(){
      if(typeof currentRouteIndex !== 'undefined'){
        return this.$store.state.data.routes[this.currentRouteIndex];
      } else {
        return this.$store.state.data.routes[this.orientationRouteIndex];
      }
    },
    total(){
      return this.route.toppedBy.length + this.route.flashedBy.length;
    },
    flashRate(){
      return Math.round((100 / (this.total) * this.route.flashedBy.length)*100)/100;
    },
    points(){
      return Math.round(1000 / (this.total));
    },
    flashPoints(){
      return Math.round(this.points * (1 - this.flashRate / 100));
    },
    ratingCount(){
      var count = 0
      if (this.route.rating){
        Object.values(this.route.rating).forEach(value => {
          if(value.rating > 0){
            count++;
          }
        });
      }
      return count;
    },
    totalRating(){
    var total = 0;
    var count = 0;
    if (this.route.rating){
    Object.values(this.route.rating).forEach(value => {
    if(value.rating > 0){
      total = total + value.rating;
      count++;
    }
    });
    }
    if (total !== 0){
    var roundedRating = Math.round(total / count * 10) / 10;
    return roundedRating;
    } else {
      return '-'
    }
    
    },
    userPoints(){
      if (this.route.flashedBy.includes(this.user.fId)){
        return this.points + this.flashPoints;
      } else if(this.route.toppedBy.includes(this.user.fId)){
        return (this.points)
      } else {
        return '-';
      }
    },
    orientation (){
      return this.$store.state.data.orientation;
    },
    editMode (){
      return this.$store.state.data.editMode;
    },
    user(){
      return this.$store.state.data.user;
    },
    tendenz(){
      var easy = 0;
      var medium = 0;
      var hard = 0;
      var list = []
    if (this.route.tendenz){
      Object.values(this.route.tendenz).forEach(value => {
        if(value.tendenz == 'Leicht'){
        easy++;
        } else if (value.tendenz == 'Normal'){
          medium++;
        } else if (value.tendenz == 'Schwer'){
          hard++;
        }
    });
    }
    list.push({type: 'Leicht', value: easy},{type: 'Normal', value: medium},{type: 'Schwer', value: hard});
    list.sort((a, b) => (a.value > b.value) ? 1 : -1);
    if(list[2].value !== 0 && list[2].value == list[1].value){
      return list[2].type + ' bis ' + list[1].type;
    } else {
      if (list[2].value == 0){
        return '-';
      } else {
      return list[2].type;
      }
    }
    }
  },
  methods: {
    
    //ANIMATIONS
    //
    
    fadeInGrowEnter(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: [0,1],
        'max-height': '500px',
        complete: done
      });
    },
    fadeInGrowLeave(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: 0,
        'max-height': '0px',
        complete: done
      });
    },
    
    
    editButtonsEnter(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
      var tl = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 250
      });
      tl
      .add({
        targets: el,
        'max-height': '500px',
      })
      .add({
        targets: sections,
        opacity: [0,1],
        delay: anime.stagger(100),
      })
    },
    editButtonsLeave(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
       var tl = anime.timeline({
          easing: 'easeInOutCubic',
          duration: 250
        });
        tl
        .add({
          targets: sections.reverse(),
          opacity: 0,
          //delay: anime.stagger(100),
        })
        .add({
          targets: el,
          'max-height': '0px',
          complete: done
        })
    },
    
    
    checkTendency(tendency){
      console.log("checking for -> " + tendency)
      let x = "tendenzen"
       console.log(this.lang['tendenzen'][tendency])
      return this.lang.tendenzen[tendency]
    },
    
    translateBasics(aim, item){
      let tag = item.toLowerCase();
      tag = tag.replace('ü', 'ue');
      tag = tag.replace('ä', 'ae');
      tag = tag.replace('ö', 'oe');
      tag = tag.replace('ß', 'ss');
      tag = tag.replace(' ', '_');
      if(this['langBasic' + aim][tag]){
      return this['langBasic' + aim][tag]
      } else {
        return item
      }
    },
    
    resetRouteName(){
      
      var currentRoute = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes");
          return currentRoute.doc(this.routeId).update({
            name: firebaseRoot.firestore.FieldValue.delete()
          })
          .then(() => {
           this.newRouteName = '';
         });
    },
    
    
    updateKids(value){
      var aim = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes");
      if (value == this.route.kids){
        aim.doc(this.routeId).update({
            kids: ''
          });
      } else {
        aim.doc(this.routeId).update({
           kids: value
         });
      }
    },
    deleteImage(){
    var path = this.gym.id + "/" + this.wall.id + "/" + this.route.id + "/";
    var aim = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId)
    this.$store.dispatch('deleteImage', {path:path, aim:aim});
    this.deleteThisImage = false;
    },
    changeTendenz(option){
      var setTendenz = option;
      if(this.currentTendenz == option){
        setTendenz = '';
      }
      var aim = "tendenz." + this.user.fId;
      var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId);
      this.$store.dispatch('updateTendenz', {aim:aim, ref:ref, user: this.user.fId, tendenz: setTendenz});
    },
    changeRating(rating){
      var setRating = rating;
      if(this.currentRating == rating){
        setRating = 0;
      }
      var aim = "rating." + this.user.fId;
      var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId);
      this.$store.dispatch('updateRating', {aim:aim, ref:ref, user: this.user.fId, rating: setRating});
    },
    changeInfoText(value){
      if (this.infoText == value){
        this.infoText = '';
      } else {
        this.infoText = value;
      }
    },
    changeImage(e){
      var d = new Date();
      var ms = d.getMilliseconds();
      var aim = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId);
      var path = this.gym.id + "/" + this.wall.id + "/" + this.route.id + "/"
      this.$store.commit('toggleUploading');
      this.$store.dispatch('changeGymImage', {e:e, ref:this.wallId, ms:ms, aim:aim, path:path});
    },
    changeRouteName(newRouteName){
      var check = newRouteName.replace(/\s/g,'').length;
      if (check == 0 || newRouteName == ''){
        this.errorMessage = this.lang.error2
      } else {
         var currentRoute = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes");
          return currentRoute.doc(this.routeId).update({
            name: newRouteName
          })
          .then(() => {
           this.newRouteName = '';
         });
      }
    },
    changeHoldNames(holdNames){
      if (holdNames == ''){
        this.errorMessageHolds = this.lang.error2;
      } else {
         var currentRoute = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes");
          return currentRoute.doc(this.routeId).update({
            holdNames: holdNames
          })
          .then(() => {
           this.holdNames = '';
         });
      }
    },
    checkUserType(userId){
      if(this.gym.activeUsers[userId]){
      return this.gym.activeUsers[userId].profile;
      } else {
        return "missing";
      }
    },
    getEmoji(userId){
      if(this.gym.activeUsers[userId]){
      return this.gym.activeUsers[userId].emoji
      } else {
      return "";
      }
    },
     umlaut(value){
      var newColor = value.replace('ü', 'ue');
      newColor = newColor.replace('ä', 'ae');
      newColor = newColor.replace('ö', 'oe');
      newColor = newColor.replace(' ', '_');
     return newColor;
    },
    sendComment(comment){
      if (typeof comment !== 'undefined'){
        this.$store.commit('updateActiveUser', {gym: this.gym.id, update:false});
           return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
           discussion: firebaseRoot.firestore.FieldValue.arrayUnion({
             user: this.user.fId,
             time: new Date(),
             text: comment
           })
         })
         .then(() => {
           this.comment = '';
         })
      }
    },
    deleteRoute(){
      this.$router.push({ path: '/gyms/' + this.$route.params.gymId + '/walls/' + this.$route.params.wallId });
    
      this.toggleDelete = false;
      db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId)
      .delete().then(() => {
        console.log("Document successfully deleted!");
        return db.collection("Gyms/" + this.gym.id + "/walls").doc(this.wall.id).update({
          routesTotal: firebaseRoot.firestore.FieldValue.increment(-1)
        });
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });
    },
    click(status) {
      this.$store.commit('updateActiveUser', {gym: this.gym.id, update:false});
      if (status == "Flash"){
        if (this.route.flashedBy.includes(this.user.fId)){
          //schon geflashed -> Offen
          return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
             flashedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
           })
           .then(() => {
            //remove rating & tendenz
            if(this.currentRating !== 0){
              var aim = "rating." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId);
              this.$store.dispatch('updateRating', {aim:aim, ref:ref, user: this.user.fId, rating: 0});
            }
            if(this.currentTendenz !== ''){
              var aim = "tendenz." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId)
              this.$store.dispatch('updateTendenz', {aim:aim, ref:ref, user: this.user.fId, tendenz: ''});
            }
            //add user stats
            var updateObj = {};
            if (this.route.tags){
            for(var i = 0; i < this.route.tags.length; i++){
             updateObj['stats.' + this.route.tags[i] + '.flashed'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
            }
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj);
           
         });
        } else {
          var preTopped = false;
           if (this.route.toppedBy.includes(this.user.fId)){
              preTopped = true;
            }
        return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
           flashedBy: firebaseRoot.firestore.FieldValue.arrayUnion(this.user.fId),
           toppedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
         })
         .then(() => {
            //add user stats
            var updateObj = {};
            if (this.route.tags){
            for(var i = 0; i < this.route.tags.length; i++){
             updateObj['stats.' + this.route.tags[i] + '.flashed'] = firebaseRoot.firestore.FieldValue.increment(1) ;
            }
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj)
            .then(() => {
              if (preTopped == true){
                //Remove 'topped' stats if it was topped before
              var updateObj = {};
              if (this.route.tags){
              for(var i = 0; i < this.route.tags.length; i++){
              updateObj['stats.' + this.route.tags[i] + '.topped'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
              }
              }
              return db.collection("Users").doc(this.user.fId).update(updateObj);
              }
            });
            
           
         });
       }
      }

      if (status == "Top"){
        if (this.route.toppedBy.includes(this.user.fId)){
          //schon getoppt -> Offen
          return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
             toppedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
           })
           .then(() => {
             //remove rating & tendenz
            if(this.currentRating !== 0){
              var aim = "rating." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId);
              this.$store.dispatch('updateRating', {aim:aim, ref:ref, user: this.user.fId, rating: 0});
            }
            if(this.currentTendenz !== ''){
              var aim = "tendenz." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId);
              this.$store.dispatch('updateTendenz', {aim:aim, ref:ref, user: this.user.fId, tendenz: ''});
            }
            //add user stats
            var updateObj = {};
            for(var i = 0; i < this.route.tags.length; i++){
             updateObj['stats.' + this.route.tags[i] + '.topped'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj);
           
         });
        } else {
          var preFlashed = false;
           if (this.route.flashedBy.includes(this.user.fId)){
              preFlashed = true;
            }
        return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.routeId).update({
           toppedBy: firebaseRoot.firestore.FieldValue.arrayUnion(this.user.fId),
           flashedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
         })
         .then(() => {
            //add user stats
            var updateObj = {};
            for(var i = 0; i < this.route.tags.length; i++){
             updateObj['stats.' + this.route.tags[i] + '.topped'] = firebaseRoot.firestore.FieldValue.increment(1) ;
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj)
            .then(() => {
              if (preFlashed == true){
                //Remove 'flashed' stats if it was flashed before
              var updateObj = {};
              for(var i = 0; i < this.route.tags.length; i++){
              updateObj['stats.' + this.route.tags[i] + '.flashed'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
              }
              return db.collection("Users").doc(this.user.fId).update(updateObj)
              }
            });
           
         });
       }
      }
    }
  }
};

</script>

<style>
.chatUser{
  @apply w-8 h-8 rounded-full bg-green-100 flex-shrink-0 mr-3 ring-2 ring-offset-2 ring-green-100 flex flex-row justify-center items-center
}
.chatBubble{
  @apply bg-green-100 font-ageo font-semibold text-green-600 p-3 rounded-t-xl rounded-br-xl
}

.editModeTextColor{
  @apply transition duration-500 ease-in-out text-yellow-300
}
.button{
  @apply transition duration-500 ease-in-out w-1/2 h-32 m-1 border border-gray-300 rounded-2xl flex flex-col pl-4 justify-center
}

.active{
  @apply transition duration-500 ease-in-out bg-purple-600 text-white
}

.buttonText{
  @apply font-ageo font-semibold text-xl
}

.buttonText2{
  @apply font-ageo font-normal text-lg
}
</style>
