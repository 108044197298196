import { db } from '../../firestore.js'
import { auth } from './auth.js'
import { basics } from './basics.js'
import { community } from './community.js'
import { explore } from './explore.js'
import { gyms } from './gyms.js'
import { home } from './home.js'
import { intro } from './intro.js'
import { map } from './map.js'
import { navbar } from './navbar.js'
import { profile } from './profile.js'
import { ranking } from './ranking.js'
import { routes } from './routes.js'
import { settings } from './settings.js'
import { update } from './update.js'
import { walls } from './walls.js'

export const lang = {
  modules: {
    auth: auth,
    basics: basics,
    community: community,
    explore: explore,
    gyms: gyms,
    home: home,
    intro: intro,
    map: map,
    navbar: navbar,
    profile: profile,
    ranking:ranking,
    routes:routes,
    settings: settings,
    update:update,
    walls:walls
  },
  state: {
    currentLang:'de',
    fallbackLang:'en',
    availableLang:[
      {
        sprache: 'Deutsch',
        code: 'de'
      },
      {
        sprache: 'English',
        code: 'en'
      },
      ],
  },
  mutations:{
    
    setLang(state, lang){
      console.log("Setting lang to -> " + lang)
      const found = state.availableLang.find(element => element.code == lang);
        if(typeof found !== undefined){
          //Wenn die native Sprache unterstützt wird
          state.currentLang = lang
        } else {
          //Wenn nicht, Fallback Sprache verwenden
          state.currentLang = state.fallbackLang
        }
    },
    
  },
  actions: {
    
    updateLang({commit, state}, payload){
      
      return db.collection("Users").doc(payload.fId).update({
        language: payload.lang
      })
      .then(() => {
          state.currentLang = payload.lang
      })
      .catch((error) => {
          console.error("Error updating document: ", error);
      });
      
    }
      
  },
}
