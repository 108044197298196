import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './css/tailwind.css'
import store from './store'
import './firestore.js'
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";


Sentry.init({
  dsn: "https://2d99c3cea88e4ba9bf2315bbdda23a4b@o557485.ingest.sentry.io/5689783",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});




createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
