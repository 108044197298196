export const settings = {
  state: {
    de:{
      einstellungen: 'Einstellungen',
      benachrichtigungen: 'BENACHRICHTIGUNGEN',
      routen: 'Neu geschraubte Routen',
      updates: 'App Updates',
      benachrichtigungen2 :'Um Benachrichtigungen zu erhalten ändere die App-Einstellungen in deinem Smartphone.',
      feedback: 'FEEDBACK',
      sendFeedback: 'Feedback schicken',
      feedbackPlaceholer: 'Ideen, Bugs oder einfach nur Feedback? Lass es uns wissen!',
      account: 'ACCOUNT',
      sprache: 'Sprache',
      spracheSel: 'Sprache auswählen',
      loggedIn: 'Du bist aktuell eingeloggt als',
      loggedIn2: 'über deinen Google Account',
      loggedIn3: 'über deinen Apple Account',
      ausloggen: 'Ausloggen',
      delete: 'Account löschen',
      passwortPlaceholer: 'Passwort erneut eingeben',
      reLogin: 'Neu einloggen',
      delete2: 'Um deinen Account zu löschen musst du dich erneut einloggen.'
    },
    en:{
      einstellungen: 'Settings',
      benachrichtigungen: 'NOTIFICATIONS',
      routen: 'New routes',
      updates: 'App updates',
      benachrichtigungen2 :'To receive notifications change the app settings at your smartphone',
      feedback: 'FEEDBACK',
      sendFeedback: 'Send feedback',
      feedbackPlaceholer: 'Ideas, bugs or just feedback? Let us know what you think!',
      account: 'ACCOUNT',
      sprache: 'Language',
      spracheSel: 'Select language',
      loggedIn: 'You are currently logged in as',
      loggedIn2: 'with your Google account',
      loggedIn3: 'with your Apple account',
      ausloggen: 'Logout',
      delete: 'Delete account',
      passwortPlaceholer: 'Enter password again',
      reLogin: 'Login again',
      delete2: 'To delete your account you have to login again.'
    }
  }
};
