<template>
  <div>
    
    <!-- SETTINGS BUTTON -->
    <div class="fixed top-0 left-0 ml-4 mt-4 w-9 h-9 z-20 flex flex-row justify-center items-center" @click="this.$router.replace({ path: '/Settings' })">
      <svg class="w-6 h-6 text-purple-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
      </svg>
    </div> 
    <!-- SETTINGS BUTTON -->
    

    <!-- USER INFO WRAPPER -->
    <div class="mt-20 h-auto flex flex-col justify-center items-center">
      
      
      <!-- USER IMAGE -->  
      <div class="w-36 h-36 mb-6" :class="{ 'animate-pulse': uploading }">
        <transition name="fade" mode="out-in">
          <div v-if="profileType == 'foto'" class="transition duration-500 ease-in-out relative w-full h-full bg-local bg-center bg-no-repeat bg-cover rounded-full shadow-md"
              v-bind:style="{ backgroundImage: 'url(' +  user.image  + ')' }"
              :class="{ 'transition duration-500 ease-in-out ring-3 ring-offset-12 ring-yellow-300': editMode }">
              <div v-if="user.image && user.image.includes('placeholder')" class="w-full h-full flex flex-row justify-center items-center font-ageo font-semibold text-xl text-gray-800 defaultBg rounded-full shadow-md"></div>
                <input type="file" accept="image/*" class="uploadImage" @change="changeImage($event)"
                    :class="{ 'block': editMode }">
          </div>
          <div v-else class="transition duration-500 ease-in-out w-full h-full relative rounded-full shadow-md bg-yellow-200 flex flex-row justify-center items-center"
              :class="{ 'transition duration-500 ease-in-out ring-3 ring-offset-12 ring-yellow-300': editMode }" @click="editEmoji()">
            <div class="font-ageo font-semibold text-7xl pt-4">
              <p>{{user.emoji}}</p>
            </div>
          </div>
        </transition>
      </div>
      <!-- USER IMAGE --> 
    
    
      <!-- EMOJI PICKER --> 
      <transition name="fade" mode="out-in">
        <div v-if="editMode && emojiPicker" class="flex flex-col items-center relative mt-6">
          <div class="absolute w-7 h-7 -top-7">
            <svg class="text-yellow-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="grid grid-cols-7 gap-5 bg-yellow-200 p-4 rounded-xl mb-3">
            <div v-for="emoji in emojis">
              <div class="font-ageo text-xl" @click="changeEmoji(emoji)">{{emoji}}</div>
            </div>
          </div>
        </div>
      </transition>
      <!-- EMOJI PICKER -->
    
    
      <!-- INPUT FIELDS WRAPPER -->
      <div class="w-screen flex flex-col justify-center items-center px-6">
        
          
          <!-- EMOJI FOTO SWITCH -->
          <div v-if="editMode" class="h-16 relative font-ageo font-normal text-4xl text-gray-800 text-center w-full mt-5" >
            <transition name="fade" mode="out-in">
              <div v-if="editMode" class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5 overflow-hidden">
                <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.profilbild}}</div>
                <div class="flex flex-row justify-center items-center h-full font-ageo font-normal text-base text-purple-600 placeholder-purple-600 text-center">
                  <div v-if="profileType !== 'emoji'" @click="updateProfileType('emoji')">{{lang.switch}}</div>
                  <div v-else @click="updateProfileType('foto')">{{lang.switch2}}</div>
                </div>
              </div>
            </transition>
          </div>
          <!-- EMOJI FOTO SWITCH -->
      
            
          <!-- USER NAME -->
          <div class="h-16 relative font-ageo font-normal text-4xl text-gray-800 text-center w-full" :class="{'mt-5' : !editMode}" >
            <transition name="fade" mode="out-in">
              <div v-if="user.name && !editMode" class="font-ageo font-normal text-2xl text-gray-800">
                <div class="font-ageo font-normal text-4xl text-gray-800">{{user.name}}</div>
                <div class="font-ageo font-normal text-lg text-center text-gray-400">{{lang.beginner}}</div>
              </div>
              <div v-else-if="editMode" class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5 overflow-hidden">
                <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.name}}</div>
                <div class="flex flex-row justify-between items-center h-full">
                  <input v-model="newUserName" :placeholder="user.name || lang.name" type="text" 
                    class="h-full px-4 w-full appearance-none outline-none text-base text-purple-600 placeholder-purple-600 text-center"
                    :class="{'w-4/6 text-left' : newUserName !== ''}"
                    @keyup.enter="changeUserName(newUserName)">
                  <div v-if="newUserName !== ''" class="flex flex-row items-center">
                    <div class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="changeUserName(newUserName)">
                      <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="newUserName = ''">
                      <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="text-red-400 font-ageo font-normal mt-2 mx-4"> {{ errorMessage }} </div>
              </div>
            </transition>
          </div>
          <!-- USER NAME -->
        
        
      </div>
      <!-- INPUT FIELDS WRAPPER -->
    
              
    </div>
    <!-- USER INFO WRAPPER -->
    
    
    <!-- STATISTIK -->        
    <div class="w-full mt-16">  
      <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-10 text-center">{{lang.stats}}</div>
      <div v-if="!user.stats" class="mt-8 px-12 mb-5 w-full font-ageo font-normal text-lg text-gray-400 text-center">
        <span>{{lang.statsEmpty}}</span>
      </div>
      <div v-for="(tag,key,index) in user.stats">
        <div class="flex flex-row justify-start items-center w-full px-9 mt-2" @click="activeStats = index">
          <div class="w-1.5 h-1.5 rounded-full ring-2 mr-3 flex-shrink-0" :class="'ring-' + color(index) +'-400'"></div>
          <div class="font-ageo font-normal w-44 pt-0.5">{{translateBasics(key)}}</div>
          <div class="relative h-1 rounded-lg w-full bg-gray-200 ">
            <div class="h-2 rounded-lg absolute inset-0 -top-0.5" :class="['bg-' + color(index) + '-400 ', width(tag.flashed, tag.topped)]"></div>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="activeStats == index" class="px-9 flex flex-row justify-start items-center w-full">
            <div class="w-44"></div>
            <div class="w-full ml-5 flex flex-row justify-between items-center font-ageo font-normal text-sm text-purple-600">
              <div>Flash {{tag.flashed || 0}}</div>
              <div>Top {{tag.topped || 0}}</div>
              <div>Total {{(tag.flashed || 0) + (tag.topped || 0)}}</div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- STATISTIK -->        
            
            
    <!-- BOTTOM SPACE -->
    <div class="h-24 mt-24"></div>
    <!-- BOTTOM SPACE -->
    
    
  </div>
</template>

<script>
import { db } from '../firestore.js';

export default {
  name: 'Profile',
   data(){
    return{
      emojiPicker:false,
      newUserName:'',
      errorMessage:'',
      profileType:'',
      activeStats: null,
      emojiIndex:'',
      colorArray:[
        'yellow',
        'red',
        'purple',
        'green',
        'blue',
        'pink',
        'indigo'
        ]
    };
   },
  beforeMount(){
    if(this.user.profile == 'emoji'){
      this.profileType = 'emoji'
    } else {
      this.profileType = 'foto';
    }
  },
  mounted(){
    this.$store.commit('resetMoveBackwards');
  },
  computed:{
    lang(){
      return this.$store.state.lang.profile[this.$store.state.lang.currentLang];
    },
    langBasics(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].routeTags;
    },
    
    emojis(){
      return this.$store.state.data.basics.emojis.icon;
    },
    user () {
      return this.$store.state.data.user;
    },
    detach(){
      return this.$store.state.data.detach;
    },
    uploading(){
      return this.$store.state.data.uploadingImage;
    },
    editMode(){
      return this.$store.state.data.editMode;
    }
  },
  methods:{
    
    translateBasics(routeTag){
      let tag = routeTag.toLowerCase();
      tag = tag.replace('ü', 'ue');
      tag = tag.replace('ä', 'ae');
      tag = tag.replace('ö', 'oe');
      return this.langBasics[tag];
    },
    
    
    editEmoji(){
      if (this.editMode){
        this.emojiPicker = !this.emojiPicker;
      }
    },
    
    
    updateProfileType(type){
      this.profileType = type
      console.log(type)
      
      return db.collection("Users/").doc(this.user.fId).update({
            profile: type
          })
          .then(() => {
            this.$store.commit('updateActiveUser', {gym: this.user.homeGym, update:true, profile: type});
          })
    },
    
    
    width(flashed, topped){
      if (typeof flashed == 'undefined'){
        flashed = 0
      }
      if (typeof topped == 'undefined'){
        topped = 0
      }
      var total = flashed + topped
      console.log(flashed + '+ ' + topped + '+ ' +total)
      if (total !== 0 && total > 0 && flashed > 0){
        var progress = Math.round(100 / total * flashed * 1.2 / 10)
        return 'w-' + progress + '/12'
      } else {
        return 'w-0'
      }
    },
    
    
    color(index){
      return this.colorArray[index] || this.colorArray[index-this.colorArray.length]
    },
    
    
     changeUserName(newUserName){
      var check = newUserName.replace(/\s/g,'').length;
      if (check == 0 || newUserName == ''){
        this.errorMessage = this.lang.errorName;
      } else {
         var currentUser = db.collection("Users/");
          return currentUser.doc(this.user.fId).update({
            name: newUserName
          })
          .then(() => {
            this.newUserName = '';
            this.$store.commit('updateActiveUser', {gym: this.user.homeGym, update:true, name: newUserName});
          })
      }
    },
    
    
    changeEmoji(emoji){
      return db.collection("Users").doc(this.user.fId).update({
        emoji: emoji
      })
      .then(() => {
            this.$store.commit('updateActiveUser', {gym: this.user.homeGym, update:true, emoji: emoji});
          })
    },
    
    
     changeImage(e){
      var d = new Date();
      var ms = d.getMilliseconds();
      var aim = db.collection("Users").doc(this.user.fId);
      var path = this.user.fId + "/"
      this.$store.commit('toggleUploading');
      this.$store.dispatch('changeGymImage', {e:e, ref:this.user.fId, ms:ms, aim:aim, path:path, updateUser:true, userGym: this.user.homeGym});
    }
  }
}
</script>

<style>
  .feedbackWrapper{
    @apply h-7 mr-1 mt-1 px-2 font-ageo font-normal bg-pink-200 rounded-lg flex flex-row justify-center items-center
  }
</style>
