<template>
  <div>
    
    <!-- BACK BUTTON -->
    <div class="fixed top-0 w-6 h-6 mt-5 ml-5" @click="this.$router.push({ path: '/' })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current stroke-2 text-purple-600">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </div>
    <!-- BACK BUTTON -->

    <div id="inside">
      <div class="mt-20 h-auto flex flex-col justify-center items-center">
      
      
        <!-- GYM IMAGE -->
        <div class="relative w-36 h-36 mb-6" :class="{ 'animate-pulse': uploading }">
          <div v-if="gym.image" class="absolute w-full h-full bg-local bg-center bg-no-repeat bg-cover rounded-full shadow-md"
              v-bind:style="{ backgroundImage: 'url(' +  gym.image  + ')' }">
              <div v-if="gym.image && gym.image.includes('placeholder')" class="w-full h-full flex flex-row justify-center items-center font-ageo font-semibold text-xl text-gray-800 defaultBg rounded-full shadow-md"></div>
              <input type="file" accept="image/*" class="uploadImage" @change="changeImage($event)" :class="{ 'block': editMode }">
          </div>
          <div class="transition duration-500 ease-in-out w-full h-full rounded-full" 
              :class="{ ' transition duration-500 ease-in-out ring-3 ring-offset-12 ring-yellow-300': editMode }"></div>
        </div>
        <!-- GYM IMAGE -->
  
  
        <div class="w-screen flex flex-col justify-center items-center px-6">
          <div class="relative font-ageo font-normal text-4xl text-gray-800 text-center w-full mt-5">
        
          
            <!-- GYM DETAILS -->
            <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false" mode="out-in">
              <div v-if="!editMode">
                <div class="font-ageo font-normal text-4xl text-gray-800"> {{ checkGymName(gym.name) }}</div>
                <div class="font-ageo font-normal text-lg text-center text-gray-400">{{ checkLocation(gym.city) }}</div> 
              </div>
            </transition>
            <!-- GYM DETAILS -->
            
            <!-- EDIT MODE ROUTE DETAILS WRAPPER -->
            <transition @enter="editOptionsEnter" @leave="editOptionsLeave" :css="false" mode="out-in">
              <div v-if="editMode">
                
                
                <!-- GYM NAME EDIT -->
                <section class="w-full mt-2 relative">
                  <div class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5 overflow-hidden">
                    <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.gymName}}</div>
                    <div class="flex flex-row justify-between items-center h-full">
                      <input v-model="newGymName" :placeholder="checkGymName(gym.name) || lang.nameGym" type="text" 
                        class="h-full px-4 w-full appearance-none outline-none text-base text-purple-600 placeholder-purple-600 text-center"
                        :class="{'w-4/6 text-left' : newGymName !== ''}"
                        @keyup.enter="changeGymName(newGymName)">
                      <div v-if="newGymName !== ''" class="flex flex-row items-center">
                        <div class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="changeGymName(newGymName)">
                          <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="newGymName = ''; errorMessage = ''">
                          <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-red-400 font-ageo font-normal text-base mt-2"> {{ errorMessage }} </div>
                </section>
                <!-- GYM NAME EDIT -->
                
                
                <!-- GYM LOCATION EDIT -->
                <section class="w-full mt-2 relative">
                  <div class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5 overflow-hidden">
                    <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.add}}</div>
                    <div class="flex flex-row justify-center items-center h-full" @click="goToMaps()">
                      <div class="font-ageo font-normal text-base text-purple-600 text-center">{{checkLocation(gym.city) || lang.add2}}</div>
                    </div>
                  </div>
                </section>
                <!-- GYM LOCATION EDIT -->
                
      
                <!-- GYM SCALES -->
                <section class="w-full mt-2 relative">
                  <div class="w-full font-ageo text-normal text-purple-600 h-9 mt-3 relative flex flex-row justify-between items-center bg-white ring-2 ring-yellow-300 rounded">
                    <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-normal text-gray-300">{{lang.scales}}</div>
                    <div class="relative h-full w-1/2 flex flex-row justify-center items-center">
                      <span class="absolute font-ageo font-normal text-base text-purple-600">{{checkScale(gym.scale)}}</span>
                      <select v-model="officialScale" class="absolut w-full h-full inset-0 appearance-none opacity-0">
                        <option disabled value="">{{lang.scale2}}</option>
                        <option v-for="scale in scales" :value="scale">
                          {{translateBasics('Scales', scale)}}
                        </option>
                      </select>
                    </div>
                    <div class="text-gray-300 font-ageo font-normal text-base">/</div>
                    <div class="relative h-full w-1/2 flex flex-row justify-center items-center">
                      <span class="absolute font-ageo font-normal text-base text-purple-600">{{customLevelsDisplay}}</span>
                      <select v-model="customScale" multiple class="absolut w-full h-full inset-0 appearance-none opacity-0">
                        <optgroup :label=lang.level></optgroup>
                        <optgroup v-for="(customLevel, index) in customLevels" :label="translateBasics('Scales', customLevel.name)">
                          <option v-if="customLevel.name == 'Farben'" v-for="level in customLevel.level" :value="level.color">
                            {{translateBasics('Colors', level.color)}}
                          </option>
                          <option v-else v-for="level in customLevel.level">
                            {{level}}
                          </option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div class="text-red-400 font-ageo font-normal text-base mt-2">{{ scaleErrorMessage }}</div>
                </section>
                <!-- GYM SCALES -->
      
      
                <!-- GYM TAGS -->
                <section class="w-full mt-5 relative">
                  <div class="w-full font-ageo text-normal text-purple-600 h-9 relative flex flex-row justify-between items-center bg-white ring-2 ring-yellow-300 rounded">
                    <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-normal text-gray-300">{{lang.tags}}</div>
                    <div class="relative h-full w-full flex flex-row justify-center items-center">
                      <span class="absolute font-ageo font-normal text-base text-purple-600">{{gymTagsDisplay}}</span>
                    <select v-model="tags" multiple class="absolut w-full h-full inset-0 appearance-none opacity-0">
                      <optgroup>Tags</optgroup>
                      <option v-for="tag in gymTags" :value="tag">
                        {{translateBasics('Tags', tag)}}
                      </option>
                    </select>
                  </div>
                  </div>
                </section>
                <!-- GYM TAGS -->
            
            
              </div>
            </transition>
            <!-- EDIT MODE ROUTE DETAILS WRAPPER -->
  
          </div>
        </div>
      </div>
  
      <div class="mt-12">
        
        
        <!-- WALL LIST HEADING -->
        <div  class="w-full flex flex-row justify-between pl-4 pr-4 items-center">
          <div class="ml-3 mr-6">
            <svg class="w-5 h-5 text-purple-600 transform -rotate-90" viewBox="0 0 120 120">
              <circle class="opacity-25" cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12"/>
              <circle cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12" stroke-dasharray="339.292" :stroke-dashoffset='circleIndicator' stroke-linecap="round"/>
            </svg>
          </div>
          <div class="flex-grow font-ageo font-normal text-lg text-gray-800">{{lang.walls}}</div>
          <div class="font-ageo font-normal text-lg text-gray-400">{{walls.filter(w => !w.section).length}} {{lang.walls}}  / {{routesTotal}} {{lang.routes}}</div>
        </div>
        <!-- WALL LIST HEADING -->
        
  
        <div  class="flex flex-col mt-4 mr-5 ml-9 border-l border-dashed border-gray-200">
  
  
          <!-- WALL EDITOR -->
          <transition @enter="editButtonsEnter" @leave="editButtonsLeave" :css="false">
            <div v-if="editMode && entryType == ''">
            <section class="flex flex-row mt-4 ml-9" @click="entryType = 'wall'">
              <div class="w-16 h-16 mr-5 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center shadow-c">
                <svg class="w-12 h-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <div class="font-ageo font-semibold text-lg text-gray-800">{{lang.addWall}}</div>
              </div>
            </section>
            <section class="flex flex-row mt-4 ml-9" @click="entryType = 'section'">
              <div class="w-16 h-16 mr-5 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center shadow-c" >
                <svg class="w-12 h-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <div class="font-ageo font-semibold text-lg text-gray-800">{{lang.addSection}}</div>
              </div>
            </section>
            <section class="flex flex-row mt-4 ml-9" @click="entryType = 'move'">
              <div class="w-16 h-16 mr-5 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center shadow-c" >
                <svg class="w-10 h-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <div class="font-ageo font-semibold text-lg text-gray-800">{{lang.changePos}}</div>
              </div>
            </section>
          </div>
        </transition>
        <!-- WALL EDITOR -->
        
        
        <!-- WALL EDITOR STEP 2 -->
        <transition name="fade" mode="out-in">
          <div v-if="editMode && entryType == 'wall'"
          class="relative mt-2 ml-9 mr-5 p-2 px-4 ring-1 ring-gray-800 rounded font-ageo font-normal text-base text-gray-800">
          {{lang.wallHelp1}}
          <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-normal text-gray-300">{{lang.wallHelp2}}</div>
          <div class="absolute -right-5 -top-3" @click="entryType = ''">
            <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3">
              <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
        </div>
        </transition>
  
        <transition name="fade" mode="out-in">
          <div v-if="editMode && entryType == 'section'"
          class="relative mt-2 ml-9 mr-5 p-2 px-4 ring-1 ring-gray-800 rounded font-ageo font-normal text-base text-gray-800">
          {{lang.sectionHelp1}}
          <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-normal text-gray-300">{{lang.sectionHelp2}}</div>
          <div class="absolute -right-5 -top-3" @click="entryType = ''">
            <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3">
              <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
        </div>
        </transition>
  
        <transition name="fade" mode="out-in">
          <div v-if="editMode && entryType == 'move'"
          class="relative mt-2 ml-9 mr-5 p-2 px-4 ring-1 ring-gray-800 rounded font-ageo font-normal text-base text-gray-800">
          {{lang.posHelp1}}
          <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-normal text-gray-300">{{lang.posHelp2}}</div>
          <div class="absolute -right-5 -top-3" @click="entryType = '', currentIndex = null">
            <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3">
              <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
        </div>
        </transition>
        <!-- WALL EDITOR STEP 2 -->
  
  
        <!-- WALL EDITOR START BUTTON -->
        <transition name="fade" mode="out-in">
          <div v-if="editMode && entryType !== '' && entryType !== 'move'" class="relative">
            <div class="absolute -bottom-5 -left-3 w-6 h-6 rounded-full bg-white border-2 border-yellow-300 flex flex-row justify-center items-center shadow-c z-20">
              <div class="w-full h-full font-ageo font-normal flex flex-row justify-center items-center" @click="addObj('start')">
                <svg class="w-5 h-5 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
            </div>
          </div>
        </transition>
        <!-- WALL EDITOR START BUTTON -->
  
  
        <!-- WALL LIST -->
        <div v-for="(w, index) in walls" class="relative">
          
            
          <!-- WALL ITEM -->
          <div v-if="!w.section" class="mt-4 ml-9 flex" :class="{ 'pointer-events-none opacity-75': entryType == 'move' && editMode}" @click="goToWalls(w.id, index)">
            <div class="w-16 h-16 mr-5 rounded-2xl flex flex-row justify-center items-center shadow-c bg-center bg-cover bg-no-repeat overflow-hidden" v-bind:style="{ backgroundImage: 'url(' +  w.image  + ')' }">
              <div v-if="w.image.includes('placeholder')" class="w-full h-full flex flex-row justify-center items-center font-ageo font-semibold text-xl text-gray-800  defaultBg"></div>
            </div>
            <div class="flex flex-col justify-center">
              <div class="font-ageo font-semibold text-lg text-gray-800"> {{ checkWallName(w.name) }} </div>
              <div class="font-light text-gray-400">{{ w.total || 0 }} {{lang.routes}}</div>
            </div>
          </div>
          <!-- WALL ITEM -->
          
          
          <!-- SECTION ITEM -->
          <div v-if="w.section">
            <div class="flex flex-row items-center relative mt-7 mb-2" :class="{ 'pointer-events-none opacity-75': editMode && entryType !== '' && entryType !=='section'}">
              <div class="absolute -left-1 w-2 h-2 rounded-full bg-purple-500 shadow"></div>
                <transition name="fade" mode="out-in">
                  <div v-if="currentIndex == index && currentIndex !== null && entryType !== 'move' && editMode"
                        class="w-full ml-9 h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 ring-2 ring-yellow-300 rounded overflow-hidden">
                    <div class="absolute left-11 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.section}}</div>
                    <div class="flex flex-row justify-between items-center h-full">
                      <input v-model="w.newName" :placeholder="checkSectionName(w.name) || lang.nameSection" type="text" 
                        class="h-full pl-4 w-4/6 appearance-none outline-none placeholder-purple-600 text-base"
                        @keyup.enter="hideSectionEdit(w.newName, w.id)">
                      <div class="flex flex-row items-center">
                        <div v-if="!toggleDeleteSection" class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="hideSectionEdit(w.newName, w.id)">
                          <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <div v-if="!toggleDeleteSection" class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="currentIndex = null; w.newName = ''">
                          <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                        <div v-if="!toggleDeleteSection" class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="toggleDeleteSection = !toggleDeleteSection; w.newName = lang.sec2">
                          <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </div>
                        <div v-if="toggleDeleteSection" class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="toggleDeleteSection = !toggleDeleteSection; currentIndex = null; w.newName = ''">
                          <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                        <div v-if="toggleDeleteSection" class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="deleteSection(index); toggleDeleteSection = !toggleDeleteSection; currentIndex = null; w.newName = ''">
                          <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="section" :class="{ editModeTextColor: editMode && entryType =='' || editMode && entryType =='section' }">
                      <div>{{checkSectionName(w.name)}}</div>
                      <div class="absolute w-full h-full inset-0 z-10" @click="editSection(index)"></div>
                    </div>
                  </div>
                </transition>
              </div>
              <div v-if="toggleDeleteSection && currentIndex == index && currentIndex !== null && entryType !== 'move' && editMode" class="font-ageo text-red-400 pl-8 text-center font-normal mt-3">{{lang.sec}}</div>
            </div>
            <!-- SECTION ITEM -->
  
  
          <!-- WALL EDITOR BUTTONS -->
          <transition name="fade" mode="out-in">
            <div v-if="editMode && entryType !== '' && entryType !== 'move'" class="relative">
              <div class="absolute -bottom-5 -left-3 w-6 h-6 rounded-full bg-white border-2 border-yellow-300 flex flex-row justify-center items-center shadow-c z-20">
                <div class="w-full h-full font-ageo font-normal flex flex-row justify-center items-center" @click="addObj(index)">
                  <svg class="w-5 h-5 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </div>
              </div>
            </div>
  
            <div v-else-if="editMode && entryType == 'move' && currentIndex == null" class="relative">
               <div class="absolute bottom-4 -left-3 w-6 h-6 rounded-full bg-white border-2 border-yellow-300 flex flex-row justify-center items-center shadow-c z-20">
                <div class="w-full h-full font-ageo font-normal flex flex-row justify-center items-center" @click="currentIndex = index">
                  <svg class="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
              </div>
            </div>
  
            <div v-else-if="editMode && entryType == 'move' && currentIndex == index" class="relative">
              <div class="absolute bottom-0 -left-3 w-6 h-16  flex flex-col justify-between items-center  z-20">
                <div class="w-6 h-6 rounded-full bg-white border-2 border-yellow-300 shadow-c font-ageo font-normal flex flex-row justify-center items-center"
                      :class="{ 'opacity-25 pointer-events-none' : currentIndex == 0}" @click="moveObj(index, -1)">
                  <svg class="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                </div>
                <div class="w-6 h-6 rounded-full bg-white border-2 border-yellow-300 shadow-c font-ageo font-normal flex flex-row justify-center items-center"
                      :class="{ 'opacity-25 pointer-events-none' : currentIndex == wallsLength-1}" @click="moveObj(index, +1)">
                  <svg class="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>
          </transition>
          <!-- WALL EDITOR BUTTONS -->
  
  
        </div>
        <!-- WALL LIST -->
        
        
      </div>
  
  
      <!-- WALL LIST EMPTY TEXT -->
      <div class="mt-8 px-12 w-full font-ageo font-normal text-lg text-gray-400 text-center">
        <span v-if="walls.length == 0">{{lang.empty}}</span>
      </div>
      <!-- WALL LIST EMPTY TEXT -->
        
        
      <!-- WALL DELETE -->
      <transition name="fade" mode="out-in">
        <div v-if="!toggleDelete && editMode" class="w-screen px-9">
          <div class="w-full h-16 flex flex-row items-center justify-center bg-yellow-300 rounded-xl mt-5" @click="toggleDelete = true">
            <div class="font-ageo font-semibold text-lg">{{lang.deleteGym}}</div>
          </div>
        </div>
        <div v-else-if="toggleDelete && editMode" class="w-screen px-9">
          <div class="w-full h-16 flex flex-row items-center justify-around bg-white ring-2 ring-yellow-200 rounded rounded-3xl mt-5">
            <div class="font-ageo font-semibold text-lg text-purple-600">{{lang.sec2}}</div>
            <div class="flex flex-row">
            <div class="w-7 h-7 rounded-full bg-purple-200 flex flex-row justify-center items-center" @click="toggleDelete = false">
              <svg class="text-purple-600 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <div class="w-7 h-7 ml-6 rounded-full bg-red-200 flex flex-row justify-center items-center" @click="deleteGym()">
              <svg class="text-red-500 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </div>
          </div>
          </div>
          <div class="font-ageo text-red-400 px-4 text-center font-normal mt-3">{{ errorMessageGym || lang.error }}</div>
        </div>
      </transition>
      <!-- WALL DELETE -->
    
        
    </div>
    
    
    <!-- BOTTOM SPACE -->
    <div class="h-24 mt-24"></div>
    <!-- BOTTOM SPACE -->
  
  </div>
</div>
</template>

<script>
import { db } from '../firestore.js'
import { functions } from '../firestore.js';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'App',
  props:['gymId', 'newGym'],
  data(){
    return{
      customScale: 'Eigene Skala' || this.lang.customScale,
      officialScale: 'Offizielle Skala' || this.lang.officialScale,
      tags: 'Tags auswählen' || this.lang.chooseTags,
      entryType: '',
      currentIndex: null,
      editGymName: false,
      newGymName: '',
      errorMessage:'',
      scaleErrorMessage:'',
      errorMessageGym: '',
      toggleDelete: false,
      toggleDeleteSection: false
      }
  },
  computed: {
    lang(){
      return this.$store.state.lang.gyms[this.$store.state.lang.currentLang];
    },
    langBasicScales(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].scales;
    },
    langBasicColors(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].colors;
    },
    langBasicTags(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].gymTags;
    },
    moveBackwards(){
      return this.$store.state.data.moveBackwards;
    },
    gymTags(){
      return this.$store.state.data.basics.gymTags
    },
    gymTagsDisplay(){
      if (this.gym.tags && this.gym.tags.length !== 0){
        var first;
        var second;
        if (this.gym.tags.length == 1){
          return this.translateBasics('Tags', this.gym.tags[0]);
        } else if(this.gym.tags.length == 2){
          first =  this.translateBasics('Tags', this.gym.tags[0]);
          second =  this.translateBasics('Tags', this.gym.tags[1]);
          return first + ", " + second
        } else if(this.gym.tags.length > 2){
          first =  this.translateBasics('Tags', this.gym.tags[0]);
          second =  this.translateBasics('Tags', this.gym.tags[1]);
          return first + ", " + second + ".."
      }
    } else {
      return this.lang.chooseTags
    }
    },
    scales(){
      return this.$store.state.data.basics.scales
    },
    customLevels(){
      return this.$store.state.data.basics.customLevels
    },
    customLevelsDisplay(){
      if (this.gym.customScale.length !== 0){
        if (this.gym.customScale.length == 1){
        return  this.translateBasics('Colors', this.gym.customScale[0]);
        //return this.gym.customScale[0]
        } else {
        var first = this.translateBasics('Colors', this.gym.customScale[0]);
        var second = this.translateBasics('Colors', this.gym.customScale[1]);
        return first + ", " + second + "..";
      }
      } else {
        return this.lang.customScale;
      }

    },
    routesTotal(){
      var i;
      var x = 0;
      for (i = 0; i < this.$store.state.data.walls.length; i++) {
        if (typeof this.$store.state.data.walls[i].total !== 'undefined'){
          x = x + this.$store.state.data.walls[i].total
        }
      }
      return x
    },
    circleIndicator(){
      return 339.292 * (1 - ((100 / this.routesTotal * this.routesTotal)/100))
    },
    uploading(){
      return this.$store.state.data.uploadingImage
    },
    detach(){
      return this.$store.state.data.detach
    },
    user () {
      return this.$store.state.data.user
    },
    walls () {
      return this.$store.state.data.walls
    },
    wallsLength () {
      return this.$store.state.data.walls.length
    },
    gym(){
        return this.$store.state.data.homeGym
    },
    editMode(){
      return this.$store.state.data.editMode
    },
    orientation(){
      return this.$store.state.data.orientation
    }
  },
  watch: {
    moveBackwards: function(){
      if(this.moveBackwards){
        this.$router.push({ path: '/' })
        }
    },
    tags: function () {
      return db.collection("Gyms/").doc(this.gym.id).update({
         tags: this.tags
       })
    },
     officialScale: function () {
       if (this.gym.scale == this.officialScale){
         console.log("Noch keine Scale festgelegt")
         return db.collection("Gyms/").doc(this.gym.id).update({
            scale: this.officialScale
          })
       } else {
         console.log("Gibt schon eine Skala")
         if (this.gym.scale !== this.officialScale){
           //Nur wenn andere Skala als aktuelle ausgewählt
           //Prüfen ob alte Skala noch verwendet wird
           var total = this.walls.length - 1;
           var that = this;
           function checkScaleUse(gym, walls, index){
             db.collection("Gyms/" + gym.id + "/walls/" + walls[index].id + "/routes").where("levelScale", "==", gym.scale)
             .get()
             .then((querySnapshot) => {
               if (querySnapshot.size == 0 && index < total){
                 checkScaleUse(gym, walls, index+1)
               }
               if (querySnapshot.size !== 0 && index <= total){
                 that.scaleErrorMessage = that.lang.error2
               }
               if (querySnapshot.size == 0 && index == total){
                 return db.collection("Gyms/").doc(gym.id).update({
                    scale: that.officialScale
                  })
               }
             })
             .catch((error) => {
               console.log("Error getting documents: ", error);
             });
           }
           checkScaleUse(this.gym, this.walls, 0)
         }
       }
     },
     customScale: function () {
       var before = this.gym.customScale.length;
       var after = this.customScale.length
       if (before < after){
         //Level wird hinzugefügt
         console.log("Füge Level hinzu")
         return db.collection("Gyms/").doc(this.gym.id).update({
            customScale: this.customScale
          })
       } else if (before > after){
         //Level wird entfernt
         //Prüfen, ob altes Level noch verwendet wird
         var difference = this.gym.customScale.filter(x => !this.customScale.includes(x));
         var item = difference[0];
         var total = this.walls.length - 1;
         var that = this;
         function checkScaleUse(gym, walls, index){
           db.collection("Gyms/" + gym.id + "/walls/" + walls[index].id + "/routes").where("levelScale", "==", "custom").where("level", "==", item)
           .get()
           .then((querySnapshot) => {
             if (querySnapshot.size == 0 && index < total){
               checkScaleUse(gym, walls, index+1)
             }
             if (querySnapshot.size !== 0 && index <= total){
               that.customScale = gym.customScale;
               that.scaleErrorMessage = that.lang.error2
             }
             if (querySnapshot.size == 0 && index == total){
               return db.collection("Gyms/").doc(gym.id).update({
                  customScale: that.customScale
                })
             }
           })
           .catch((error) => {
             console.log("Error getting documents: ", error);
           });
         }
         checkScaleUse(this.gym, this.walls, 0)

       }
     }
   },
  mounted(){
      
    this.$store.commit('resetMoveBackwards');
    if (this.newGym == 'true'){
      //Wenn Gym noch nicht geladen wurde
      console.log("loading new Gym")
      if (this.detach.walls !== null){
        //Wenn noch alte Wände geladen sind
        this.$store.commit('detachWalls');
        this.$store.commit('clearWalls');
      }
      this.$store.dispatch('loadWallsforCurrentGym', db.collection("Gyms/"+ this.gymId +"/walls"));
    } else {
      //Wenn Gym vorher geladen wurde
      console.log("loading old Gym")
      if (this.detach.walls == null){
        this.$store.dispatch('loadWallsforCurrentGym', db.collection("Gyms/"+ this.gymId +"/walls"));
      }
    }
    if (this.gym.customScale){
      this.customScale = this.gym.customScale;
    }
    if (this.gym.tags){
      this.tags = this.gym.tags;
    }
  },
  methods:{
    //ANIMATIONS
    //
    
    fadeInGrowEnter(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: [0,1],
        'max-height': '500px',
        complete: done
      });
    },
    fadeInGrowLeave(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: 0,
        'max-height': '0px',
        complete: done
      });
    },
    
    editButtonsEnter(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
      var tl = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 250
      });
      tl
      .add({
        targets: el,
        'max-height': '500px',
      })
      .add({
        targets: sections,
        opacity: [0,1],
        delay: anime.stagger(100),
      })
    },
    editButtonsLeave(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
       var tl = anime.timeline({
          easing: 'easeInOutCubic',
          duration: 250
        });
        tl
        .add({
          targets: sections.reverse(),
          opacity: 0,
          delay: anime.stagger(100),
        })
        .add({
          targets: el,
          'max-height': '0px',
          complete: done
        })
    },
    
    
    editOptionsEnter(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
      var tl = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 250
      });
      tl
      .add({
        targets: el,
        'max-height': '500px',
      })
      .add({
        targets: sections,
        opacity: [0,1],
        delay: anime.stagger(100),
      })
    },
    editOptionsLeave(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
       var tl = anime.timeline({
          easing: 'easeInOutCubic',
          duration: 250
        });
        tl
        .add({
          targets: sections.reverse(),
          opacity: 0,
          //delay: anime.stagger(100),
        })
        .add({
          targets: el,
          'max-height': '0px',
          complete: done
        })
    },
    
    translateBasics(aim, item){
      let tag = item.toLowerCase();
      tag = tag.replace('ü', 'ue');
      tag = tag.replace('ä', 'ae');
      tag = tag.replace('ö', 'oe');
      tag = tag.replace('ß', 'ss');
      tag = tag.replace(' ', '_');
      return this['langBasic' + aim][tag]
    },
    
    checkGymName(name){
      if(name && name == 'Neue Halle'){
        return this.lang.newGym;
      } else {
        return name
      }
    },
    
    checkWallName(name){
      if(name && name == 'Neue Wand'){
        return this.lang.newWall;
      } else {
        return name
      }
    },
    
    checkSectionName(name){
      if(name && name == 'Neuer Abschnitt'){
        return this.lang.newSection;
      } else {
        return name
      }
    },
    
    checkLocation(add){
      if(add && add == 'Ort auswählen'){
        return this.lang.location;
      } else {
        return add
      }
    },
    
    checkScale(scale){
      if(scale && scale == 'Offizielle Skala'){
        return this.lang.scale2;
      } else {
        return scale
      }
    },
    
    deleteGym(){
      
      var deleteGym = functions.httpsCallable('deleteGym');
      deleteGym({ 
        gymId: this.gym.id
      })
      .then((result) => {
        if(result.data){
          this.errorMessageGym = this.lang.error4
        }
      });
      
    },
    goToMaps(){
      this.$router.push({name: 'Map', params:{ type: 'search', comingFrom: this.gym.id}})
    },
    goToWalls(wallId, index){
    /*  if (this.orientation.currentWallIndex == index){
      //Zurück zur alten Wand
      console.log("goto old wall")
      this.$router.push({name: 'Walls', params:{ gymId: this.gym.id, wallId: wallId, newWall: false}})

    } else {*/
      //Neue Wand laden
      console.log("goto new wall")
      this.$store.commit('orientationSetCurrentWallIndex', index);
      this.$router.push({name: 'Walls', params:{ gymId: this.gym.id, wallId: wallId, newWall: true }})
  //  }
    },
    changeGymName(newGymName){
      var check = newGymName.replace(/\s/g,'').length;
      if (check == 0 || newGymName == ''){
        this.errorMessage = this.lang.error3
      } else {
         var currentGym = db.collection("Gyms/");
          return currentGym.doc(this.gym.id).update({
            name: newGymName
          })
          .then(() => {
            this.editGymName = false;
            this.newGymName = '';
            this.errorMessage = '';
            this.$store.dispatch('loadCluster' ,{gymId: this.gym.id, geo: null, name:newGymName});
          })
      }
    },
    deleteSection(index){
      var currentWall = db.collection("Gyms/" + this.gym.id + "/walls");
      var currentSection = this.walls[index].id;
      this.$store.dispatch('deleteSection', {aim:currentWall, section:currentSection});
      this.currentIndex = '';
    },
    editSection(index){
      if (this.editMode == true){
        this.currentIndex = index;
      }
    },
    hideSectionEdit(newName, id){
      var check = newName.replace(/\s/g,'').length;
      if (check !== 0 && newName !== '' && typeof newName !== 'undefined'){
        var currentWall = db.collection("Gyms/" + this.gym.id + "/walls");
        return currentWall.doc(id).update({
          name: newName
        })
        .then(() => {
          this.currentIndex = null;
        })
      }
    },
    moveObj(index, direction){
      var selectedOrder = this.walls[index].order;
      var selectedId = this.walls[index].id;
      var moveToOrder = this.walls[index+direction].order;
      var moveToId = this.walls[index+direction].id;
      var aim = db.collection("Gyms/" + this.gym.id + "/walls");
      //Erstes Objekt ändern
      this.$store.dispatch('moveWallorSection', {
        order: selectedOrder,
        id: moveToId,
        aim: aim
      });
      //Zweites Objekt ändern
      this.$store.dispatch('moveWallorSection', {
        order: moveToOrder,
        id: selectedId,
        aim: aim
      });
      this.currentIndex = this.currentIndex + direction;
    },
    addObj(index){
      var newIndex = 0;
      if (index == "start"){
      //Entrypoint Neue Leere Halle
      if (typeof this.walls[0] === 'undefined'){
        var drunter = "0";
        var drueber = "1";
      } else {
        //Entrypoint Start
        var drunter = "0";
        var drueber = this.walls[0].order;
      }

    } else {
      if (typeof this.walls[index+1] === 'undefined'){
        //Entrypoint Ende
        var drunter = this.walls[index].order;
        var drueber = this.walls[index].order;
      } else {
        //Entrypoint Mitte
        var drunter = this.walls[index].order;
        var drueber = this.walls[index+1].order;
      }
      newIndex = index +1;
    }
      var aim = db.collection("Gyms/" + this.gym.id + "/walls");
      this.$store.dispatch('addWallOrSection', {drunter:drunter, drueber:drueber, type:this.entryType, aim:aim, gym: this.gym.id, index: newIndex});
    },
    changeImage(e){
      var d = new Date();
      var ms = d.getMilliseconds();
      var aim = db.collection("Gyms").doc(this.gym.id);
      var path = this.gym.id + "/"
      this.$store.commit('toggleUploading');
      this.$store.dispatch('changeGymImage', {e:e, ref:this.gym.id, ms:ms, aim:aim, path:path});
    }
  }
}

</script>


<style>
.defaultBg{
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%237c3aed' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.section{
  @apply relative w-full h-9 ml-9 font-ageo font-normal text-lg text-gray-800 flex flex-row justify-between items-center
}

.uploadImage{
  @apply absolute w-full h-full inset-0 appearance-none hidden opacity-0
}

</style>
