<template>
  <div>


    <!-- MAP SETTINGS -->
    <div class="fixed top-0 px-5 mt-5 z-20 flex flex-row justify-between w-full">
      
      
      <!-- BACK BUTTON -->
      <div class="w-12 h-12 z-20 bg-white shadow-c rounded-2xl flex flex-row justify-center items-center flex-shrink-0" @click="goBack()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 stroke-current stroke-2 text-purple-600">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
      </div> 
      <!-- BACK BUTTON -->
      
      
      <!-- SEARCH BUTTON -->
      <div class="relative ml-3 w-12 h-12 bg-white shadow-c rounded-2xl flex flex-row justify-between items-center" ref="searchWrapper">
        <div class="flex-grow w-full">
          <input v-if="toggleSearch" ref="clusterSearchObj" v-model="clusterSearch" :placeholder="lang.search" class="w-full appearance-none outline-none px-3 bg-white font-ageo font-normal text-md text-gray-800 flex-grow" @keyup.enter="searchCluster(clusterSearch)" @blur="resetTop">
        </div>
        <div v-if="clusterSearch == ''" class="w-12 h-12 flex flex-row justify-center items-center flex-shrink-0" @click="searchGrow">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 stroke-current stroke-2 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
        <div v-else class="w-12 h-12 flex flex-row justify-center items-center flex-shrink-0" @click="searchCluster(clusterSearch)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-purple-600 origin-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
          </svg>
        </div>
      </div>
   
      <!-- SEARCH BUTTON -->
      
      
    </div>
    <!-- MAP SETTINGS -->
    
    
    <!-- CREATE NEW GYM POPUP -->
    <transition name="fade" mode="out-in">
      <div v-if="toggleEmptySearch" class="fixed top-0 z-20 px-5 mt-20 w-full">
        <div class="h-auto bg-white rounded-2xl w-full p-3 shadow-c flex flex-col justify-center items-center">
          <div v-if="type !== 'search'" class="font-ageo font-normal text-md text-gray-900">{{lang.noResult1}} "{{lastSearch}}" {{lang.noResult2}}</div>
          <div v-if="type == 'search'" class="font-ageo font-normal text-md text-gray-900">{{lang.noResult3}} "{{lastSearch}}" {{lang.noResult4}}.</div>
          <div v-if="type !== 'search'" class="w-full flex flex-row justify-between items-center mt-1">
            <div class="flex flex-row" @click="createNewGym()">
              <div class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-2">
                <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div class="font-ageo font-normal text-purple-600">{{lang.createGym}}</div>
            </div>
            <div class="flex flex-row" @click="toggleEmptySearch = false; clusterSearch = ''">
              <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-2">
                <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              <div class="font-ageo font-normal text-red-600">{{lang.cancel}}</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- CREATE NEW GYM POPUP -->
    
    
    <!-- MAP PREVIEW -->
    <div class="fixed bottom-0 mb-28 z-20 flex flex-col justify-center items-center w-full px-5">
      <div class="w-full mb-3 flex flex-row-reverse">
        
        
       <!-- MAP BUTTON -->
      <div class="w-12 h-12 bg-white shadow-c rounded-2xl flex flex-row justify-center items-center flex-shrink-0" @click="changeMapType()">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 stroke-current stroke-2 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
        </svg>
      </div> 
      <!-- MAP BUTTON -->
      
      
      </div>
      
      <!-- SELECT PREVIEW -->
      <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
        <div v-if="gym.name && type !== 'search'" class="h-24 shadow-c bg-white rounded-3xl w-full flex flex-row justify-start items-center px-5" @click="goToGym(gym.id)">
          <div v-if="gym.image" class="flex-shrink-0 mr-5 w-16 h-16 rounded-2xl bg-local bg-center bg-no-repeat bg-cover" 
          v-bind:style="{ backgroundImage: 'url(' +  gym.image  + ')' }"></div>
          <div class="flex flex-col">
            <div class="font-ageo font-semibold text-lg text-gray-800 leading-5">{{checkGymName(gym.name)}}</div>
            <div class="font-ageo text-gray-700 mt-0.5">{{checkLocation(gym.city)}}</div>
          </div>
        </div>
        <div v-else-if="type !== 'search' && filteredResults.length > 1" class="h-24 shadow-c bg-white rounded-3xl w-full flex flex-row justify-start items-center px-5">
          
          <div class="absolute font-ageo font-semibold text-lg leading-5 text-gray-900 w-auto h-auto flex flex-row justify-center items-center">
            <div class="flex flex-col">
              <div v-if="!clusterResults" class="w-full h-full">{{filteredResults.length}} {{lang.results1}}</div>
              <div v-else class="w-full h-full">{{lang.results2}} {{filteredResults.length}} {{lang.results3}}</div>
              <div class="font-ageo font-normal text-purple-600 mt-1">Alle anzeigen</div>
            </div>
            <select v-model="resultSelect" class="absolute flex-grow w-full h-full z-30 text-lg outline-none appearance-none opacity-0">
              <option disabled value="">{{lang.searchResults}}</option>
              <option v-for="(result, index) in filteredResults" :value="index">
                {{ result.name }}
              </option>
            </select>
          </div>
       
        </div>
      </transition>
      <!-- SELECT PREVIEW -->
      
      
      <!-- SEARCH PREVIEW -->
      <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
        <div v-if="type == 'search' && !gym.name && filteredResults.length == 0" class="h-24 shadow-c bg-white rounded-3xl w-full flex flex-row justify-start items-center px-5">
          <div class="flex flex-col">
            <div class="font-ageo font-semibold text-lg text-gray-800 leading-5">{{lang.searchAddress}}</div>
            <div class="font-ageo text-gray-700 mt-0.5">{{lang.searchAddress2}}</div>
          </div>
        </div>
        <div v-else-if="type == 'search' && gym.name && filteredResults.length == 0" class="h-24 shadow-c bg-white rounded-3xl w-full flex flex-row justify-between items-center px-5" @click="setNewGeo(gym.add, gym.geo)">
          <div class="flex flex-col">
            <div class="font-ageo font-semibold text-lg text-gray-800 leading-5">{{checkGymName(gym.name)}}</div>
            <div class="font-ageo text-gray-700 mt-0.5">{{checkLocation(gym.add)}}</div>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 text-purple-600 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg> 
        </div>
        <div v-else-if="type == 'search' && filteredResults.length > 1" class="h-24 shadow-c bg-white rounded-3xl w-full flex flex-row justify-start items-center px-5">
          <div class="absolute font-ageo font-semibold text-lg leading-5 text-gray-900 w-auto h-auto flex flex-row justify-center items-center">
            <div class="flex flex-col">
              <div v-if="!clusterResults" class="w-full h-full">{{filteredResults.length}} {{lang.results1}}</div>
              <div v-else class="w-full h-full">{{lang.results2}} {{filteredResults.length}} {{lang.results3}}</div>
              <div class="font-ageo font-normal text-purple-600 mt-1">Alle anzeigen</div>
            </div>
            <select v-model="resultSelect2" class="absolute flex-grow w-full h-full z-30 text-lg outline-none appearance-none opacity-0">
              <option disabled value="">{{lang.searchResults}}</option>
              <option v-for="(result, index) in filteredResults" :value="index">
                {{ result.name }}
              </option>
            </select>
          </div>
       
        </div>
      </transition>
      <!-- SEARCH PREVIEW -->
      
      
    </div>
    <!-- MAP PREVIEW -->
    
    
    <!-- MAP -->
    <div id="inside">
      <div id="mapContainer" class="w-screen h-screen"></div>
    </div>
    <!-- MAP -->
  
  
  </div>
</template>

<script>
import { db } from '../firestore.js'
import anime from 'animejs/lib/anime.es.js';

var map;
var geocoder = new mapkit.Geocoder({
    language: "de",
    getsUserLocation: false
});
var annotations;
var annotationStyle = function(coordinate, options) {
        var div = document.createElement("div");
        div.className = "circle-annotation";
        var inner = div.appendChild(document.createElement("div"));
        inner.className = "circle-annotation-inner";
        return div;
    };
var annotationClusterStyle = function(coordinate, data) {
          var div = document.createElement("div");
            div.className = "cluster-annotation";
            div.textContent = data.members;
          return div;
      };

export default {
  name: 'Map',
  props: ['type', 'comingFrom'],
  data(){
    return{
      mapType : "Standard",
      toggleSearch: false,
      toggleEmptySearch: false,
      clusterSearch: '',
      lastSearch:'',
      filteredResults:[],
      resultSelect:'',
      resultSelect2:'',
      clusterResults: false
    }
  },
  computed:{
    lang(){
      return this.$store.state.lang.map[this.$store.state.lang.currentLang];
    },
    mapInit(){
      return this.$store.state.data.map
    },
    cluster(){
      return this.$store.state.data.cluster
    },
    user(){
      return this.$store.state.data.user
    },
    gym(){
        return this.$store.state.data.mapGym
    },
    detach(){
      return this.$store.state.data.detach
    },
    moveBackwards(){
      return this.$store.state.data.moveBackwards;
    }
  },
  watch:{
    moveBackwards: function(){
      if(this.moveBackwards){
        if(this.type !== 'search'){
          this.$router.push({ path: '/Explore' })
        } else {
          this.$router.push({ path: '/gyms/' + this.$route.params.gymId });
        }
        }
    },
    resultSelect2(){
      if(this.type == 'search' && this.resultSelect2 !== ''){
        var newCenter = this.filteredResults[this.resultSelect2].geo;
        var span = new mapkit.CoordinateSpan(0.005,0.045);
        var region = new mapkit.CoordinateRegion(newCenter, span);
        map.setRegionAnimated(region, true);
        console.log(this.filteredResults)
        var newName = this.filteredResults[this.resultSelect2].name;
        var newAdd = this.filteredResults[this.resultSelect2].add;
        this.$store.commit('setMapGym', {name: newName, add: newAdd, geo: newCenter});
        this.filteredResults = [];
      }
    },
    
    resultSelect(){
      if(this.type !== 'search' && this.resultSelect !== ''){
        var gymId = this.filteredResults[this.resultSelect].refId;
        var newCenter = new mapkit.Coordinate(this.filteredResults[this.resultSelect].geo.latitude, this.filteredResults[this.resultSelect].geo.longitude)
        var span = new mapkit.CoordinateSpan(0.005,0.045);
        var region = new mapkit.CoordinateRegion(newCenter, span);
        map.setRegionAnimated(region, true);
        this.$store.dispatch('loadMapGym', gymId);
      } 
    },
    cluster(){
      console.log("cluster loaded")
      //BEI HALLENSUCHE
      
      if (this.type == 'select'){
      var that = this;
      var newLandmarks = []

      var i = 0;
      for (i = 0; i < this.cluster.length; i++) {
        var geo = this.cluster[i].geo
        if (geo !== null){
        newLandmarks.push({ coordinate: new mapkit.Coordinate(geo.latitude, geo.longitude), name: this.cluster[i].name, refId: this.cluster[i].refId})
      }}

      annotations = newLandmarks.map(function(landmark) {
        var annotation = new mapkit.Annotation(landmark.coordinate, annotationStyle, {
          clusteringIdentifier: "gym"
        });
        annotation.landmark = landmark;
        annotation.addEventListener("select", that.selectedAnnotiation);
        annotation.addEventListener("deselect", that.deselectedAnnotiation);
        return annotation;
      });
      
      
      map.showItems(annotations);
      
      map.addEventListener("single-tap", this.mapClick);
      
      map.annotationForCluster = function(clusterAnnotation) {
        if (clusterAnnotation.clusteringIdentifier === "gym") {
          var data = {
            members: clusterAnnotation.glyphText
          };
          var cluster = new mapkit.Annotation(clusterAnnotation.coordinate, annotationClusterStyle, data);
          cluster.addEventListener("select", that.selectedCluster);
          return cluster;
        }
      };
      
      
    }
  }
  },
  beforeMount(){
    if (!this.mapInit){
      mapkit.init({
        authorizationCallback: function(done) {
            done('eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkZOREM2U0pEQzQifQ.eyJpc3MiOiI5WTdMNTJBVTJOIiwiaWF0IjoxNjE3MzgxMTg0LCJleHAiOjE5MDkzMjY3ODR9.QTmXjVCZ1HUgS_gTQqFSd0XdZRdyQF4u4c5nFjly9Jg0FzypE2KQfWmrqxJzNVBCvWsrBUz3DOXSoHwBbQb6-Q');
        }
      });
      this.$store.commit('setMap');
    }
  },
  
  
  mounted(){
    this.$store.commit('resetMoveBackwards');
    window.scrollTo(0, document.body.scrollHeight +35);
     
    var Home = new mapkit.CoordinateRegion(
      new mapkit.Coordinate(53.551086, 9.993682),
      new mapkit.CoordinateSpan(0.167647972, 0.354985255)
    );
        
    map = new mapkit.Map("mapContainer");
    map.showsMapTypeControl = false;
    map.showsZoomControl = false;
    map.region = Home;
    map.addEventListener("long-press", this.longPress);
    this.$store.dispatch('loadCluster');
  },
  
  
  beforeUnmount(){
    this.$store.commit('clearMapGym');
    map.destroy();
  },
  
  
  methods:{
  
  //ANIMATIONS
  //
  searchGrow(el){
    
    this.toggleEmptySearch = false;
    var that = this;
    el = this.$refs.searchWrapper;
    if (!this.toggleSearch){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 500,
        'flex-grow': '1',
        complete: function(anim) {
          console.log("done")
          that.toggleSearch = !that.toggleSearch;
        }
      });
    } else {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 500,
        'flex-grow': '0',
         begin: function(anim) {
          that.toggleSearch = !that.toggleSearch;
        }
      });
    }
  },
  
  fadeInGrowEnter(el, done){
    anime({
      targets: el,
      easing: 'easeInOutCubic',
      duration: 500,
      opacity: [0,1],
      'max-height': '500px',
      complete: done
    });
  },
  fadeInGrowLeave(el, done){
    anime({
      targets: el,
      easing: 'easeInOutCubic',
      duration: 500,
      opacity: 0,
      'max-height': '0px',
      complete: done
    });
  },
  
  //FUNCTIONS
  //
  
  checkGymName(name){
      if(name && name == 'Neue Halle'){
        return this.lang.newGym;
      } else {
        return name
      }
    },
    
    checkLocation(add){
      if(add && add == 'Ort auswählen'){
        return this.lang.location;
      } else {
        return add
      }
    },
  
  resetTop(e){
  setTimeout(function(){ 
    window.scrollTo(0, document.body.scrollHeight +35);
  }, 150);
  
  },
  
  longPress(e){
    var that = this;
    if(this.type == 'search'){
      this.filteredResults = [];
      map.removeAnnotations(map.annotations);
      var coordinate = map.convertPointOnPageToCoordinate(new DOMPoint(e.pointOnPage.x, e.pointOnPage.y));
      var annotation = new mapkit.Annotation(coordinate, annotationStyle, {
        clusteringIdentifier: "result"
      });
      geocoder.reverseLookup(coordinate, function(err, data) {
          var place = data.results[0].formattedAddress;
          that.$store.commit('setMapGym', {name: "Hinzugefügte Adresse", add: place, geo: coordinate});
      });
        map.addAnnotation(annotation);
    }
  },
  
  goBack(){
    if(this.type == 'search'){
      this.$router.push({ path: '/gyms/' + this.comingFrom });
    } else {
       this.$router.push({ path: '/Explore' });
    }
  },
  
  mapClick(){
    this.$store.commit('clearMapGym');
    this.filteredResults = [];
  },
  
  createNewGym(){
    this.$store.commit('clearHomeGym');
    this.$store.commit('detachHomeGym');
    this.$store.commit('detachGyms');
    this.$store.dispatch('createNewGym'); 
  },
  
  searchCluster(searchText){
    var el = this.$refs.clusterSearchObj;
    this.toggleEmptySearch = false;
    this.lastSearch = searchText;
    this.clusterResults = false;
    
    if(this.type !== 'search'){
      this.$store.commit('clearMapGym');
      
      var result = this.cluster.filter(word => word.name.toUpperCase().includes(searchText.toUpperCase()));
      console.log(result)
      if (result.length == 0) {
        this.toggleEmptySearch = true;
      }
      if (result.length == 1){
        el.blur();
        var gymId = result[0].refId;
        var newCenter = new mapkit.Coordinate(result[0].geo.latitude, result[0].geo.longitude)
        var span = new mapkit.CoordinateSpan(0.005,0.045);
        var region = new mapkit.CoordinateRegion(newCenter, span);
        map.setRegionAnimated(region, true);
        this.$store.dispatch('loadMapGym', gymId);
        
      }
      if (result.length > 1){
        el.blur();
        this.filteredResults = result;
      }
    } else {
      
    
    var that = this;
    var search = new mapkit.Search({
      language: "de",
      getsUserLocation: false,
      region: map.region,
      includeAddresses: true,
      includePointsOfInterest: true
    });
    
    search.search(searchText, function(error, data) {
      if (error) {
          console.log(error)
          return;
      }
      
      
      if(data.places.length == 0){
        console.log("kein treffer")
        that.toggleEmptySearch = true;
      } else if(data.places.length == 1){
        el.blur();
        var newCenter = data.boundingRegion.center;
        var span = new mapkit.CoordinateSpan(0.005,0.045);
        var region = new mapkit.CoordinateRegion(newCenter, span);
        map.setRegionAnimated(region, true);
      } else {
        el.blur();
      }

      
      annotations = data.places.map(function(place) {
        var annotation = new mapkit.Annotation(place.coordinate, annotationStyle, {
          clusteringIdentifier: "result"
        });
        annotation.place = place;
        annotation.addEventListener("select", that.selectedAnnotiation);
        annotation.addEventListener("deselect", that.deselectedAnnotiation);
        that.$store.commit('setMapGym', {name: place.name, add: place.formattedAddress, geo:place.coordinate});
        return annotation;
      });

        map.showItems(annotations);
        
        map.annotationForCluster = function(clusterAnnotation) {
        if (clusterAnnotation.clusteringIdentifier === "result") {
          var data = {
            members: clusterAnnotation.glyphText
          };
          var cluster = new mapkit.Annotation(clusterAnnotation.coordinate, annotationClusterStyle, data);
          cluster.addEventListener("select", that.selectedCluster);
          return cluster;
        }
      };
    });
    }
  },
  
  selectedAnnotiation(e){
    if(this.type == 'search'){
      this.$store.commit('setMapGym', {name: e.target.place.name, add: e.target.place.formattedAddress, geo: e.target.place.coordinate});
      this.filteredResults = [];
    } else {
      var gymId = e.target.landmark.refId;
      this.$store.dispatch('loadMapGym', gymId);
    }
  },
  
  deselectedAnnotiation(e){
    this.$store.commit('clearMapGym');
  },
  
  selectedCluster(e){
    this.$store.commit('clearMapGym');
    if(e.target.memberAnnotations.length <= 3){
      if(this.type !== 'search'){
      this.resultSelect = '';
      var results = [];
      e.target.memberAnnotations.forEach(element => results.push({
        geo: {
          latitude: element.landmark.coordinate.latitude,
          longitude:element.landmark.coordinate.longitude
        },
        name: element.landmark.name,
        refId: element.landmark.refId
      }));
      this.filteredResults = results;
      this.clusterResults = true;
      } else {
        this.resultSelect2 = '';
       var results = [];
      e.target.memberAnnotations.forEach(element => results.push({
        geo: element.coordinate,
        name: element.place.name,
        add: element.place.formattedAddress
      }));
      this.filteredResults = results;
      this.clusterResults = true; 
      }
    } else {
      var newCenter = e.target.coordinate;
      var span = new mapkit.CoordinateSpan((map.region.span.latitudeDelta / 2),(map.region.span.longitudeDelta / 2));
      var region = new mapkit.CoordinateRegion(newCenter, span);
      map.setRegionAnimated(region, true);
    }
  },
  
  goToGym(gymId){
    this.$store.commit('clearHomeGym');
    this.$store.commit('detachHomeGym');
    this.$store.dispatch('loadHomeGym', gymId);
    if (this.detach.gyms !== null){
      this.$store.commit('detachGyms');
    }
    this.$store.dispatch('setUserHomeGym', {gymId: gymId, fId: this.user.fId});
    this.$router.push({name: 'Gyms', params:{ gymId: gymId, newGym: true }});
  },
  
  changeMapType(){
    if(this.mapType == "Standard"){
      map.mapType = mapkit.Map.MapTypes.Hybrid;
      this.mapType = "Hybrid";
    } else {
      map.mapType = mapkit.Map.MapTypes.Standard; 
      this.mapType = "Standard";
    }
  },
  
  setNewGeo(address, geo){

    var shortAddress = address.split(',');
    var length = shortAddress.length;
    var withNoDigits = shortAddress[length-2].replace(/[0-9]/g, '');
    var short = shortAddress[0] + ", " + withNoDigits.trim()

    //Change address of gym
    return db.collection("Gyms").doc(this.comingFrom).update({
      city: short
    })
    .then(() => {
      console.log("Document successfully updated!");
      this.$store.commit('updateCluster', {gymId: this.comingFrom, geo: geo, name: null});
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });

  },
}
}

</script>

<style>
  .circle-annotation {
    @apply transition duration-500 ease-in-out w-5 h-5 rounded-full bg-purple-300 bg-opacity-40 flex flex-row justify-center items-center hover:bg-purple-300 transform hover:-translate-y-1 hover:scale-110
}

.circle-annotation-inner {
    @apply w-1.5 h-1.5 rounded-full bg-purple-600 
}

.cluster-annotation {
    @apply transition duration-500 ease-in-out shadow-2xl font-ageo font-bold text-purple-600 w-6 h-6 rounded-full bg-white ring ring-purple-300 flex flex-row justify-center items-center pt-1 transform hover:-translate-y-1 hover:scale-110
}


.hidde-callout {
  @apply w-4 h-4 bg-yellow-300
}

</style>