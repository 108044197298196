<template>
  <div>

    <!-- HEADING -->
    <div class="mt-20 mb-5 mx-9">
      <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-2">{{lang.intro}}</div>
      <div class="w-3/4 font-ageo font-semibold text-2xl text-gray-800 leading-7">{{lang.sub}}</div>
    </div>
    <!-- HEADING -->

    <div class="w-full">
      
      <!-- SEARCH BAR -->
      <div class="mx-9 h-10 flex flex-row justify-between items-center bg-gray-100 rounded-xl overflow-hidden">
        <div class="w-5 h-5 mx-3">
          <div v-if="filteredGyms.length == 0 && searchText !== '' && more !== gyms.length">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div v-else>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-current stroke-2 text-purple-600">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
        <input ref="searchInput" v-model="searchText" :placeholder=lang.search class="appearance-none outline-none px-3 bg-gray-100 font-ageo font-normal text-md text-gray-800 flex-grow" @focus="toggleFocus = true" @keyup.enter="blur()">
        <router-link :to="{name: 'Map', params:{ type:'select'}}">
          <svg class="w-5 h-5 mr-3 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
          </svg>
        </router-link>
      </div>
      <!-- SEARCH BAR -->


      <!-- SEARCH TAGS -->
      <transition name="fade" mode="out-in">
        <div v-if="toggleFocus" class="flex flex-wrap flex-row justify-start items-center mx-9 mb-6">
          <div v-for="(tag, searchTagIndex) in gymTags">
            <div v-if="searchTagIndex < 6 || tagSearchToggle" class="tag" :class="{ 'bg-green-200' : tagSearch.includes(tag)}" @click="addFilterObject(tag)">
              {{translateBasics(tag)}}
              <div v-if="tagSearch.includes(tag)" class="flex flex-row justify-center items-center ml-2">
                <svg class="w-3 h-3 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="!tagSearchToggle" class="font-ageo font-semibold text-base text-pink-600 bg-pink-200 rounded-lg px-2 py-1 mr-1 mt-1" @click="tagSearchToggle = true">+ {{gymTags.length - 6}}</div>
        </div>
      </transition>
      <!-- SEARCH TAGS -->


      <!-- SEARCH RESULTS -->
      <div v-if="searchText !== ''" class="mr-5">
        <div class="relative w-full pl-9 mt-5 font-ageo font-semibold text-purple-600 text-xs tracking-widest">{{lang.searchResults}}</div>
      </div>
      <!-- SEARCH RESULTS -->

      
      <!-- GYM LIST WRAPPER -->
      <div v-if="homeGym && homeGym.name && searchText == ''" class="relative w-full px-9 mt-5 font-ageo font-semibold text-purple-600 text-xs tracking-widest flex flex-row justify-between items-center">
        <div>{{lang.places}}</div>
        <div @click="this.$router.push({name: 'Map', params:{ type:'select'}})">{{lang.mapView}}</div>
      </div>
      <div class="flex flex-col mr-5 pt-2">
        
        
        <!-- GYM LIST EDITOR -->
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="editMode" class="flex flex-row mt-4 ml-9">
            <div class="w-16 h-16 mr-5 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center shadow-c" @click="addGym()">
              <div class="h-full font-ageo font-light text-7xl text-white">+</div>
            </div>
            <div class="flex flex-col justify-center">
              <div class="font-ageo font-semibold text-lg text-gray-800">{{lang.addGym}}</div>
            </div>
          </div>
        </transition>
        <!-- GYM LIST EDITOR -->
        
        
        <!-- GYM LIST -->
        <div v-for="(g, index) in filteredGyms">
          <div class="mt-4 ml-9 flex flex-row">
            <div class="flex-shrink-0 w-16 h-16 mr-5 rounded-2xl flex flex-row justify-center items-center shadow-c bg-center bg-cover bg-no-repeat overflow-hidden" v-bind:style="{ backgroundImage: 'url(' +  g.image  + ')' }"  @click="goToGyms(g.id, index)">
              <div v-if="g.image.includes('placeholder')" class="w-full h-full flex flex-row justify-center items-center font-ageo font-semibold text-xl text-gray-800  defaultBg">
                <div>{{g.short}}</div>
              </div>
            </div>
            <div class="flex flex-col justify-center justify-start">
              <div class="font-ageo font-semibold text-lg text-gray-800 leading-5" @click="goToGyms(g.id, index)"> {{checkGymName(g.name)}} </div>
              <div class="flex flex-wrap flex-row justify-start items-center">
                <div v-for="(tag, tagindex) in g.tags">
                  <div v-if="tagindex < 2 || tagToggleIndex === index" class="font-ageo font-normal text-base text-purple-600 bg-purple-200 rounded-lg px-2 py-1 mr-1 mt-1">{{translateBasics(tag)}}</div>
                </div>
                <div v-if="g.tags && g.tags.length > 2 && tagToggleIndex !== index" class="font-ageo font-semibold text-base text-pink-600 bg-pink-200 rounded-lg px-2 py-1 mr-1 mt-1" @click="tagToggleIndex = index">+ {{g.tags.length - 2}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- GYM LIST -->


        <!-- GYM LIST LOAD MORE -->
        <div v-if="typeof last !== 'undefined' && searchText == ''" class="mt-8 font-ageo font-normal text-lg text-purple-600 text-center" @click="loadMoreGyms()">{{lang.loadMore}}</div>
        <div v-if="typeof last !== 'undefined' && tagSearch.length != 0" class="mt-8 font-ageo font-normal text-lg text-purple-600 text-center" @click="forceLoading()">{{lang.showMore}}</div>
        <!-- GYM LIST LOAD MORE -->
        
        
      </div>
      <!-- GYM LIST WRAPPER -->
      
      
      <!-- GYM LIST EMPTY -->
      <div class="mt-8 px-12 mb-5 w-full font-ageo font-normal text-lg text-gray-400 text-center">
        <span v-if="typeof last == 'undefined'">{{lang.empty}}</span>
      </div>
      <!-- GYM LIST EMPTY -->


    </div>
    
    
    <!-- BOTTOM SPACE -->
    <div class="h-24"></div>
    <!-- BOTTOM SPACE -->
    
    
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'Explore',
  data(){
    return{
      searchText: '',
      forceLoad: false,
      oldResults: 0,
      blocker: false,
      i:0,
      tagToggleIndex:'',
      toggleFocus: false,
      tagSearchToggle: false,
      tagSearch:[]
    };
  },
  computed: {
    lang(){
      return this.$store.state.lang.explore[this.$store.state.lang.currentLang];
    },
    langBasics(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].gymTags;
    },
    gymTags(){
      return this.$store.state.data.basics.gymTags;
    },
    last(){
      return this.$store.state.data.last;
    },
    detach(){
      return this.$store.state.data.detach;
    },
    more(){
      return this.$store.state.data.moreToLoad;
    },
    homeGym(){
      return this.$store.state.data.homeGym;
    },
    user(){
      return this.$store.state.data.user;
    },
    editMode(){
      return this.$store.state.data.editMode;
    },
    gyms () {
      return this.$store.state.data.gyms;
    },
    gymLength(){
      return this.gyms.length;
    },
    filteredGyms () {
      if (this.tagSearch.length > 0){
        this.searchText = "Suche nach Tags..";
        var that = this;

        function tagFilter(gym) {
          //filter where all tags from tagSearch are included
          var total = 0;
          for (var i = 0; i < that.tagSearch.length; i++) {
            if (gym.tags && gym.tags.includes(that.tagSearch[i])){
              //true for this one
              total++;
            }
          }
          if (total == that.tagSearch.length){
            //if all tags are included
            return gym
          }
        }
        return this.gyms.filter(tagFilter);
      } else {
        return this.gyms.filter(word => word.name.toUpperCase().includes(this.searchText.toUpperCase()))
      }
    }
  },
  mounted(){
    this.$store.commit('resetMoveBackwards');
    document.body.style.backgroundColor = "white";
    this.$store.dispatch('loadGyms', null);
   
},
beforeUnmount(){
  this.$store.commit('detachGyms');
},
watch: {

  gymLength(){
    if(this.gymLength == this.more){
      this.blocker = false;
    }
  },
  
  filteredGyms(){
    if(this.blocker == false){
     if (this.filteredGyms.length == 0 && this.searchText !== '' && this.more == this.gyms.length){
        if(typeof this.last !== 'undefined'){
          this.blocker = true;
          this.$store.dispatch('loadGyms', this.last);
        }
       } 
    } 
  }
},
methods:{
  
  //ANIMATIONS
  //
  fadeInGrowEnter(el, done){
    anime({
      targets: el,
      easing: 'easeInOutCubic',
      duration: 500,
      opacity: [0,1],
      'max-height': '500px',
      complete: done
    });
  },
  fadeInGrowLeave(el, done){
    anime({
      targets: el,
      easing: 'easeInOutCubic',
      duration: 500,
      opacity: 0,
      'max-height': '0px',
      complete: done
    });
  },
  
  
  //FUNCTIONS
  //
  
  translateBasics(gymTag){
      let tag = gymTag.toLowerCase();
      tag = tag.replace('ü', 'ue');
      tag = tag.replace('ä', 'ae');
      tag = tag.replace('ö', 'oe');
      tag = tag.replace('ß', 'ss');
      tag = tag.replace(' ', '_');
      return this.langBasics[tag];
    },
    
  checkGymName(name){
      if(name && name == 'Neue Halle'){
        return this.lang.newGym;
      } else {
        return name
      }
    },
    
  blur(){
    var el = this.$refs.searchInput;
     el.blur();
  },
  
  addFilterObject(tag){
      if (this.tagSearch.includes(tag)){
        console.log("removing tag" + tag)
        this.tagSearch.splice(this.tagSearch.indexOf(tag), 1)
        if (this.tagSearch.length == 0){
          this.searchText = ''
        }
      } else {
        console.log("adding tag " + tag);
        //this.searchText = "Suche nach Tags.."
        this.tagSearch.push(tag);
      }

  },
  goToGyms(gymId, index){
    if (this.user.homeGym){
      //HomeGym exisitiert bereits

        //Bei Klick auf Gym aus der Liste
        this.$store.commit('clearHomeGym');
        this.$store.commit('detachHomeGym');
        this.$store.dispatch('loadHomeGym', gymId);
        this.$store.commit('detachGyms');
        this.$store.dispatch('setUserHomeGym', {gymId: gymId, fId: this.user.fId});
        this.$router.push({name: 'Gyms', params:{ gymId: gymId, newGym: true }})

    } else {
      //HomeGym existiert noch nicht
      this.$store.dispatch('loadHomeGym', gymId);
      this.$store.commit('detachGyms');
      this.$store.dispatch('setUserHomeGym', {gymId: gymId, fId: this.user.fId});
      this.$router.push({name: 'Gyms', params:{ gymId: gymId, newGym: true }})
    }
  },
  addGym(){
    this.$store.commit('clearHomeGym');
    this.$store.commit('detachHomeGym');
    this.$store.commit('detachGyms');
    this.$store.dispatch('createNewGym');
  },
  loadMoreGyms(){
    this.$store.dispatch('loadGyms', this.last);
  },
  forceLoading(){
    console.log("forcing more")
    this.forceLoad = true;
    this.oldResults = this.filteredGyms.length;
    console.log("AKTUELL I -> " + this.i);
    this.i = 0;
    this.$store.dispatch('loadGyms', this.last);
  }
}
}
</script>

<style>
.tag{
  @apply font-ageo font-normal text-base text-purple-600 bg-purple-200 rounded-lg px-2 py-1 mr-1 mt-1 flex flex-row
}


.defaultBg{
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%237c3aed' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
</style>
