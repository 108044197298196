export const routes = {
  state: {
    de:{
      holdColor: 'Grifffarbe' ,
      scaleInfo:'Skalen können auf der Hallen-Seite bearbeitet werden',
      scale:' Skala',
      selectType:'Typ auswählen',
      routeName:'Routenname',
      nameRoute:'Route benennen',
      error1:'Dieser Schritt kann danach nicht mehr rückgängig gemacht werden.',
      error2: 'Bitte gib einen gültigen Namen ein.',
      routeTags:'Routen-Tags',
      tags:'Tags',
      holdPlaceholder:'Optional, z.B. Unit Holds',
      holds:'Griffe',
      kids:'Speziell für Kinder geschraubt',
      kids2:'Kinder',
      kids3:'Kleinkinder',
      flash:'Flash',
      top:'Top',
      persons:'Total',
      feedback:'Wie fandest du die Route?',
      feedback2:'Die Route ist eher..',
      details:'DETAILS',
      begehungen:'Begehungen',
      showAll:'alle anzeigen',
      hideAll:'alle ausblenden',
      flashRate:'Flash Rate',
      level:'Schwierigkeit',
      noLevel:'Nicht gewählt',
      tendenz:'Tendenz',
      forKids:'Für Kids geschraubt',
      kidsInfo:'Die meisten Routen können je nach Leistungsniveau auch von Kindern geklettert werden. Diese Kennzeichnung bedeutet nur, dass diese Route speziell für Kinder oder Kleinkinder entworfen wurde.',
      points:'Punkte',
      bonus:'Flash Bonus',
      pointsInfo:'Jede Route ist 1000 Punkte wert. Diese Punktzahl wird unter allen Begehungen aufgeteilt. Diese Route ist also bei aktuell',
      pointsInfo2:'Begehungen für jede Person',
      pointsInfo3:'Punkte wert.',
      bonusInfo:'Bei einem Flash bekommst du zusätzliche Punkte. Die Bonuspunkte hängen von der Flash Rate ab - je weniger Personen die Route geflasht haben, desto mehr Bonuspunkte bekommst du.',
      score:'Deine Punktzahl',
      rating:'Bewertung',
      ratings:'Bewertungen',
      createdOn:'Erstellt am',
      by:'von',
      age:'Alter',
      image:'FOTO',
      routeImage:'Routenfoto',
      deleteImage:'Foto löschen',
      sec:'Ganz sicher?',
      imageInfo:'Füge ein Foto von der Route hinzu, damit andere sie besser zuordnen können. Achte darauf, dass keine Personen im Foto zu sehen sind.',
      fotoUploadInfo:'Foto wird hochgeladen..',
      fotoUpload:'Foto hochladen',
      comments:'KOMMENTARE',
      commentPlaceholder:'Schreibe einen Kommentar..',
      deleteRoute:'Route löschen',
      days:' Tage',
      day:' Tag',
      selectRouteTags:'Routen-Tags auswählen',
      tendenzen:{
        Leicht:'Leicht',
        Normal:'Mittel',
        Schwer:'Schwer'
      }
    },
    en:{
      holdColor: 'Hold color' ,
      scaleInfo:'Scales can be edited on the gym-page.',
      scale:' Scale',
      selectType:'Select type',
      routeName:'Route name',
      nameRoute:'Set route name',
      error1:'This step cannot be reversed afterwards.',
      error2: 'Please enter a valid name',
      routeTags:'Route tags',
      tags:'Tags',
      holdPlaceholder:'Optional, e.g. Unit Holds',
      holds:'Holds',
      kids:'Set especially for children',
      kids2:'Kids',
      kids3:'Toddlers',
      flash:'Flash',
      top:'Top',
      persons:'Total',
      feedback:'How did you like the route',
      feedback2:'This route is rather..',
      details:'DETAILS',
      begehungen:'Total',
      showAll:'show all',
      hideAll:'hide all',
      flashRate:'Flashrate',
      level:'Difficulty',
      noLevel:'Not set',
      tendenz:'Tendency',
      forKids:'Set for kids',
      kidsInfo:'Most of the routes can be climbed by kids depending on their individual level. This label is only an indicator if a route was especially set for kids.',
      points:'Points',
      bonus:'Flash bonus',
      pointsInfo:'Every route is worth 1000 points. These points will be split up by all finishers. This route, finished by',
      pointsInfo2:'people, is currently worth',
      pointsInfo3:'points',
      bonusInfo:'You are getting extra points for every flash. The amount of points depends on the flash rate - the less people have flashed this route, the more points you will get.',
      score:'Your score',
      rating:'Rating',
      ratings:'Ratings',
      createdOn:'Created on',
      by:'by',
      age:'Age',
      image:'IMAGE',
      routeImage:'Route image',
      deleteImage:'Delete image',
      sec:'Are you sure?',
      imageInfo:'Add a photo of the route so that others can better relate it. Make sure that nobody can be seen in the photo.',
      fotoUploadInfo:'Uploading..',
      fotoUpload:'Upload image',
      comments:'COMMENTS',
      commentPlaceholder:'Add a new comment..',
      deleteRoute:'Delete route',
      days:' Days',
      day:' Day',
      selectRouteTags:'Select route tags',
      tendenzen:{
        Leicht:'Easy',
        Normal:'Medium',
        Schwer:'Hard'
      }
    }
  }
};
