import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import { getMessaging, getToken } from "firebase/messaging";

firebase.initializeApp({
 	apiKey: "AIzaSyCosa0ckhHZxrdg8RxVQMNSeAoZXtxjuaE",
    authDomain: "app.reclimb.io",
    projectId: "boulderdb-db354",
    storageBucket: "boulderdb-db354.appspot.com",
    messagingSenderId: "1047627723537",
    appId: "1:1047627723537:web:5e6dc51c4b544d19b992b0",
    measurementId: "G-ZLXC6FMF85"
});


export const db = firebase.firestore();
export const base = firebase.firestore;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firebaseRoot = firebase;
export const functions = firebase.functions();

