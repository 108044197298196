<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" class="overflow-hidden"/>
    </router-view>
    <Navbar />
</template>


<script>
import { auth } from './firestore.js';
import Navbar from './components/Navbar.vue';
import { db } from './firestore.js';

export default {
  name: 'Master',
  components: { Navbar },
  computed: {
    fallbackLang(){
      return this.$store.state.lang.fallbackLang;
    },
    currentLang(){
      return this.$store.state.lang.currentLang;
    },
    moveBackwards(){
      return this.$store.state.data.moveBackwards;
    },
    user(){
        return this.$store.state.data.user;
      },
    fId(){
      return this.$store.state.data.user.fId;
    }
  },
  watch: {
     fId: function () {
       //when logged in & not on first visit
       if(!this.user.firstVisit){
        this.$store.dispatch('getFCMPermission');
       }
       //update language
       console.log(this.user)
       if(this.user.language){
         console.log("Language -> " + this.user.language)
         this.$store.commit('setLang', this.user.language);
       } else {
         this.$store.dispatch('updateLang', {fId: this.fId, lang: this.currentLang});
       }
     }
  },
  mounted(){

    if(window.webkit && window.webkit.messageHandlers){
        window.webkit.messageHandlers.getLang.postMessage('Getting Language..');
    }
        
    window['addFcm'] = (token)=>{
    this.addFcm(token);
    };
    
    window['addLang'] = (lang)=>{
    this.$store.commit('setLang', lang);
    };

    var that = this;
    that.$store.dispatch('getAppVersion');
    auth.onAuthStateChanged(function(user) {
      if (user) {
        var provider = "";
        if(user.providerData[0].providerId == "apple.com"){
          provider = "apple";
        }
        if(user.providerData[0].providerId == "google.com"){
          provider = "google";
        }
        console.log("signed in");
        that.$store.dispatch('loadBasics');
        that.$store.dispatch('getUser', user);
        that.$store.commit('signedIn', {signedIn: true, provider: provider});
      } else {
        console.log("signed out");
        that.$store.commit('authStateChanged', null);
      }
    });
    


  },
  methods:{
    
    //----------------------------------------------//
    //--------------------METHODS-------------------//
    //----------------------------------------------//
    
    addFcm(token){
      console.log("received FCM -> " + token);
      console.log(this.user.fcm);
      console.log(token);
      if(this.user.fcm !== token){
        console.log("updating user fcm" + this.user.fId);
        return db.collection("Users").doc(this.user.fId).update({
          fcm: token
        })
        .then(() => {
            console.log("Document successfully updated!");
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
      }
    }
  }
  };
</script>

<style>

html{
  -webkit-tap-highlight-color: transparent;
}

body{
  @apply w-full h-full select-none
}



.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
 transition: transform 0.0000000001s;
}

.none-enter-active,
.none-leave-active {
  visibility: hidden;
}

.none-enter-from,
.none-leave-to {
  visibility: hidden;
}

.fadeFast-enter-active,
.fadeFast-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.fadeFast-enter-from,
.fadeFast-leave-to {
  opacity: 0;
}



.fade2-enter-active,
.fade2-leave-active {
  transition: all 0.5s ease;
  max-height: 100px;
  opacity: 1;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
