<template>
  <div class="w-screen h-screen superfull fixed bg-purple-600 relative">
    <div class="w-full absolute bottom-12 px-6 mb-16 flex flex-col justify-center items-center">


      <!-- HEADING -->
      <transition name="fadeFast" mode="out-in">
      <div v-if="type == ''">
        <div class="w-full pt-20 font-ageo font-black text-6xl text-white text-left">Hello,<br> let's climb.</div>
        <div class="text-lg text-white font-ageo font-normal leading-6 mt-1 mb-5">{{lang.intro}}</div>
      </div>
      <div v-else>
        <div class="font-ageo font-normal text-lg text-white">{{ error }}</div>
      </div>
      </transition>
      <!-- HEADING -->


      <!-- SIGN IN WITH GOOGLE && EMAIL -->
      <div class="w-full h-16 mt-3 border border-purple-600 bg-white rounded-lg shadow-lg">
        <transition name="fadeFast" mode="out-in">
        <div v-if="type == ''" class="w-full h-full flex flex-row justify-center items-center" @click="auth('google')">
          <svg v-if="!loginLoaderGoogle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" class="w-6 h-6">
            <defs>
              <path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/>
            </defs>
            <clipPath id="b">
              <use xlink:href="#a" overflow="visible"/>
            </clipPath>
            <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/>
            <path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/>
            <path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/>
            <path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>
          </svg>
          <div v-if="!loginLoaderGoogle" class="font-ageo font-semibold text-lg text-gray-900 ml-3">{{lang.login1}}</div>
          <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <div v-else class="w-full h-full flex flex-row justify-start items-center">
          <input type="email" v-model="email" :placeholder=lang.email
          class="mx-5 w-full appearance-none outline-none font-ageo font-normal text-lg text-gray-900 placeholder-gray-900">
        </div>
        </transition>
      </div>
      <!-- SIGN IN WITH GOOGLE && EMAIL -->
      
      
      <!-- SIGN IN WITH APPLE && PASSWORD -->
      <div v-if="type !== 'reset'" class="w-full h-16 mt-3 border border-purple-600 bg-white rounded-lg shadow-lg">
        <transition name="fadeFast" mode="out-in">
        <div  v-if="type == ''" class="w-full h-full flex flex-row justify-center items-center" @click="auth('apple')">
          <svg v-if="!loginLoaderAppe" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1200 1200" class="w-6 h-6 text-gray-900 mb-0.5">
            <path fill-rule="evenodd" d="M702 960c-54.2 52.6-114 44.4-171 19.6-60.6-25.3-116-26.9-180 0-79.7 34.4-122 24.4-170-19.6-271-279-231-704 77-720 74.7 4 127 41.3 171 44.4 65.4-13.3 128-51.4 198-46.4 84.1 6.8 147 40 189 99.7-173 104-132 332 26.9 396-31.8 83.5-72.6 166-141 227zM423 237C414.9 113 515.4 11 631 1c15.9 143-130 250-208 236z"/>
          </svg>
          <div v-if="!loginLoaderAppe" class="font-ageo font-semibold text-lg text-gray-900 ml-3">Mit Apple anmelden</div>
          <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <div v-else class="w-full h-full flex flex-row justify-start items-center">
          <input type="password" v-model="pass" :placeholder=lang.passwort
          class="mx-5 w-full appearance-none outline-none font-ageo font-normal text-lg text-gray-900 placeholder-gray-900">
        </div>
        </transition>
      </div>
      <!-- SIGN IN WITH APPLE && PASSWORD -->
      
      
      <!-- SIGN IN WITH EMAIL AND PASSWORD -->
      <div class="w-full mt-3 h-16 border border-purple-600 bg-white rounded-lg shadow-lg">
      <transition name="fadeFast" mode="out-in">
        <div  v-if="type == ''" class="w-full h-full flex flex-row justify-center items-center" @click="this.type = 'login'">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
          </svg>
          <div class="font-ageo font-semibold text-lg text-gray-900 ml-3">{{lang.login2}}</div>
        </div>
        <div  v-else-if="type == 'login'" class="h-full flex flex-col justify-center items-center"
            @click="login(email, pass)">
          <div v-if="!loginLoader" class="font-ageo font-semibold text-lg text-gray-900">{{lang.login3}}</div>
          <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        <div  v-else-if="type == 'new'" class="h-full flex flex-col justify-center items-center"
            @click="signUp(email, pass)">
          <div class="font-ageo font-semibold text-lg text-gray-900">{{lang.account}}</div>
        </div>
        <div  v-else-if="type == 'reset'" class="h-full flex flex-col justify-center items-center"
            @click="resetPass(email)">
          <div class="font-ageo font-semibold text-lg text-gray-900">{{lang.reset}}</div>
        </div>
      </transition>
      </div>
      <!-- SIGN IN WITH EMAIL AND PASSWORD -->
      
      
      <!-- CREATE ACCOUNT -->
      <div class="w-full flex flex-col justify-between items-center pt-3 px-1 h-12">
        <transition name="fadeFast" mode="out-in">
        <div v-if="type == ''" class="w-full flex flex-row justify-between">
          <div class="font-ageo font-normal text-lg text-white">{{lang.create1}}</div>
          <div class="font-ageo font-bold text-lg text-white" @click="this.type = 'new'">{{lang.create2}}</div>
        </div>
        <div v-else class="w-full flex flex-row justify-between">
          <div class="font-ageo font-normal text-lg text-white " @click="this.type = ''; this.error = ''" >{{lang.back}}</div>
          <div v-if="type == 'login'" class="font-ageo font-bold text-lg text-white" @click="type = 'reset'">{{lang.passwordForgot}}</div>
        </div>
        </transition>
      </div>
      <!-- CREATE ACCOUNT -->


    </div>
  </div>
</template>

<script>
import { auth } from '../firestore.js';
import { firebaseRoot } from '../firestore.js';

export default {
  name: 'Auth',
  data(){
    return{
      current:'',
      type:'',
      error:'',
      name:'',
      email:'',
      pass:'',
      provider:'',
      loginLoader: false,
      loginLoaderAppe: false,
      loginLoaderGoogle: false
    };
  },
  computed: {
    lang(){
      return this.$store.state.lang.auth[this.$store.state.lang.currentLang];
    },
    userData () {
      return this.$store.state.data.user;
    },
    user () {
      return this.$store.state.data.user.auth;
    },
    signedIn(){
      return this.$store.state.data.user.signedIn;
    },
    version(){
      return this.$store.state.data.version.version;
    }
  },
  mounted(){
    document.body.style.backgroundColor = "#7C3AED";
  },
  watch: {
     user: function () {
       //if already logged in goto Home
       if (this.user.auth !== null){
            this.$router.replace({ name:'Home' })
       }
     },
    signedIn: function () {
      if(this.userData.provider == 'apple'){
          this.loginLoaderAppe = true;
        }
        if(this.userData.provider == 'google'){
          this.loginLoaderGoogle = true;
        }
      if(typeof this.version !== 'undefined' && this.version !== '' && this.signedIn){
         //Version geladen und User eingeloggt
        this.signIn();
      }
    },
    version: function () {
       if(this.userData.provider == 'apple'){
          this.loginLoaderAppe = true;
        }
        if(this.userData.provider == 'google'){
          this.loginLoaderGoogle = true;
        }
      if(typeof this.version !== 'undefined' && this.version !== '' && this.signedIn){
         //Version geladen und User eingeloggt
        this.signIn();
      }
    }
   },
  methods: {
    signIn(){
      auth.getRedirectResult()
        .then((result) => {
          console.log("RES")
          console.log(result);
          this.$store.commit('updateUserSession', result.credential);
          if(result.additionalUserInfo.providerId == "apple.com"){
            this.loginLoaderAppe = true;
          } else {
            this.loginLoaderGoogle = true;
          }
          if(result.additionalUserInfo.isNewUser){
            console.log("creating new user");
            this.$store.dispatch('createNewUser', {version:this.version, uid:result.user.uid} );
          } else {
            console.log("logging in user");
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    
    
    auth(provider){
      if(provider == 'apple'){
        this.provider = new firebaseRoot.auth.OAuthProvider('apple.com');
        this.provider.addScope('email');
        this.provider.addScope('name');
      }
      if(provider == 'google'){
        this.provider = new firebaseRoot.auth.GoogleAuthProvider();
      }
      firebaseRoot.auth().signInWithRedirect(this.provider);
    },
    
    
    signUp(email, pass){
      if(typeof email === 'undefined' || typeof pass === 'undefined'){
        this.error = this.lang.error1;
      } else {
      auth.createUserWithEmailAndPassword(email, pass)
      .then((userCredential) => {
        console.log("hussa, user created");
        var user = userCredential.user;
        this.$store.dispatch('createNewUser', {version:this.version, uid:user.uid} );
      })
      .catch((error) => {
        console.log(error);
        this.error = error.message;
      });
    }
    },
    
    
    login(email, pass){
      this.loginLoader = true;
        auth.signInWithEmailAndPassword(email, pass)
        .then((userCredential) => {
          console.log("hussa, user logged in");
        })
        .catch((error) => {
          this.loginLoader = false;
          this.error = error.message;
        });
    },

    
    resetPass(email){
      if(typeof email !== 'undefined'){
      auth.sendPasswordResetEmail(email)
      .then(() => {
        this.error = this.lang.success1
      })
      .catch((error) => {
        console.log(error)
        this.error = error.message;
      });
    }
    }
  }
};

</script>
