export const intro = {
  state: {
    de:{
      welcome1: "willkommen an Board!",
      welcome2: "Unter welchem Namen dürfen dich andere Nutzer sehen?",
      name: "Dein Name",
      error1: "Dein Name darf nicht leer sein.",
      intro1: "Nur noch ein paar kurze Infos, dann geht es los.",
      intro2: "Die komplette App wird von der Community gesteuert, du kannst deine Halle also selbst eintragen und konfigurieren.",
      intro3: "Du kannst Wände, Routen & Abschnitte hinzufügen, indem du auf das kleine Bearbeiten-Symbol in der rechten oberen Ecke klickst.",
      intro4: "Wir schicken dir Push-Notifications, wenn in deiner Halle eine Wand umgeschraubt wurde - ist das OK?",
      intro5: "Das war's schon!",
      intro6: "Viel Spaß beim Klettern.",
      button1: "Namen bestätigen",
      button2: "OK!",
      button3: "Alles klar.",
      button4: "Auf jeden Fall!",
      button5: "Los geht's!",
      button6: "Erst mal nicht..",
      back: "Zurück"
    },
    en:{
      welcome1: "welcome on board!",
      welcome2: "Choose a name that will be visible for others.",
      name: "Your name",
      error1: "Your name cannot be empty.",
      intro1: "Just a quick guide and you're ready to start.",
      intro2: "The whole app is managed by the cummunity which means you can create and configure your own gym.",
      intro3: "You can create wall, routes and sections by clicking on the tiny pencil icon at the top right corner",
      intro4: "We'd like to send you push notifications once a wall in your gym got reset - is that OK?",
      intro5: "That's it!",
      intro6: "Have fun climbing.",
      button1: "Confirm your name",
      button2: "OK!",
      button3: "Allright.",
      button4: "Yes, of course!",
      button5: "Let's go!",
      button6: "Not yet..",
      back: "Go back"
    }
  }
};
