export const community = {
  state: {
    de:{
      intro: 'COMMUNITY' ,
      sub: 'In Kürze verfügbar.'
    },
    en:{
      intro: 'COMMUNITY' ,
      sub: 'Coming soon.'
    }
  }
};
