export const update = {
  state: {
    de:{
      update: 'UPDATE',
      update2: 'Wir haben ein paar Dinge an der App verbessert.',
      bugs: 'Fehlerbehebungen',
      improvements: 'Verbesserungen',
      features: 'Neue Features',
      upcoming: 'Als nächstes',
      version: 'Neue Version laden'
    },
    en:{
      update: 'UPDATE',
      update2: 'We recently made some changes to improve our app.',
      bugs: 'Bug fixes',
      improvements: 'Improvements',
      features: 'New features',
      upcoming: 'Upcoming',
      version: 'Load new version'
    }
  }
};
