import { storage } from './firestore.js'
import { db } from './firestore.js'

function getImageUrlFromStorage(docImage, callback, fallbackImage) {
  if(docImage && fallbackImage){
  var pathReference = storage.ref(docImage);
  pathReference.getDownloadURL()
    .then((url) => {
      callback(url);
    })
    .catch((error) => {
      console.log("Trying with fallback Image..");
      setTimeout(function() {
        getImageUrlFromStorage(fallbackImage, callback, fallbackImage);
      }, 1000);
    });
  } else {
    callback('placeholder')
  }
}

export { getImageUrlFromStorage };
