export const ranking = {
  state: {
    de:{
      ranking: 'Ranking',
      newGym: 'Neue Halle',
      noGym: 'Du musst zuerst eine Halle auswählen um hier das Ranking zu sehen.',
      score: 'DEIN PLATZ',
      routes: 'Routen',
      flashrate: 'Flashrate',
      flash: 'Flash',
      top: 'Top',
      rankingType: 'RANKING NACH',
      points: 'Pkt.',
      empty1: 'Es sind noch keine Ranking Daten für',
      empty2: 'vorhanden',
      begehungen: 'BEHEGUNGEN',
      punkte: 'PUNKTEN'
    },
    en:{
      ranking: 'Ranking',
      newGym: 'New Gym',
      noGym: 'You need to choose your gym first to see the ranking.',
      score: 'YOUR RANK',
      routes: 'Routes',
      flashrate: 'Flashrate',
      flash: 'Flash',
      top: 'Top',
      rankingType: 'RANKING BY',
      points: 'Pts.',
      empty1: 'No ranking data for',
      empty2: 'available',
      begehungen: 'TOTAL ROUTES',
      punkte: 'POINTS'
    }
  }
};
