<template>
  <div>

    <!-- HEADING -->
    <div class="mt-20 mb-5 mx-9">
      <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-2">{{lang.update}}</div>
      <div class="w-full font-ageo font-semibold text-2xl text-gray-800 leading-7">Hey {{user.name}}! <br> {{lang.update2}}</div>
    </div>
    <!-- HEADING -->


    <div class="flex flex-row justify-center items-center">
      <div class="w-10/12 flex flex-col justify-center items-center mt-3">


        <!-- BUG FIXES -->
        <div v-if="bugFixes" class="font-ageo font-semibold text-red-600 bg-red-200 rounded px-3 py-1 mt-5 text-xl">{{lang.bugs}}</div>
        <div v-for="bugFix in bugFixes" class="w-auto">
          <div v-if="bugFix.version > userVersion" class="flex flex-row justify-between items-center mt-3">
            <svg class="w-6 h-6 text-red-600 bg-red-200 rounded-full p-1 mr-3 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            <div class="font-ageo font-normal text-lg text-center">{{ bugFix.name }}</div>
          </div>
        </div>
        <!-- BUG FIXES -->


        <!-- IMPROVEMENTS -->
        <div v-if="improvements" class="font-ageo font-semibold text-purple-600 bg-purple-200 rounded px-3 py-1 mt-12 text-xl">{{lang.improvements}}</div>
        <div v-for="improvement in improvements" class="w-auto">
          <div v-if="improvement.version > userVersion" class="flex flex-row justify-between items-center mt-3">
          <svg class="w-6 h-6 text-purple-600 bg-purple-200 rounded-full p-1 mr-3 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
          <div class="font-ageo font-normal text-lg text-center">{{ improvement.name }}</div>
          </div>
        </div>
        <!-- IMPROVEMENTS -->


        <!-- FEATURES -->
        <div v-if="features" class="font-ageo font-semibold text-green-600 bg-green-200 rounded px-3 py-1 mt-12 text-xl">{{lang.features}}</div>
        <div v-for="feature in features" class="w-auto">
          <div v-if="feature.version > userVersion" class="flex flex-row justify-between items-center mt-3">
          <svg class="w-6 h-6 text-green-600 bg-green-200 rounded-full p-1 mr-3 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
            <div class="font-ageo font-normal text-lg text-center">{{ feature.name }}</div>
          </div>
        </div>
        <!-- FEATURES -->
  
        
        <!-- UPCOMING -->
        <div v-if="upcoming" class="font-ageo font-semibold text-pink-600 bg-pink-200 rounded px-3 py-1 mt-12 text-xl">{{lang.upcoming}}</div>
        <div v-for="up in upcoming" class="w-auto">
          <div class="flex flex-row justify-between items-center mt-3">
          <svg class="w-6 h-6 text-pink-600 bg-pink-200 rounded-full p-1 mr-3 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
            <div class="font-ageo font-normal text-lg text-center">{{ up }}</div>
          </div>
        </div>
        <!-- UPCOMING -->


        <!-- BUTTON -->
        <div class="fixed h-24 bottom-0 w-full bg-gray-50 rounded-t-2xl shadow-c p-5 flex flex-row justify-center items-center">
          <div class="font-ageo font-semibold text-lg text-white w-auto text-center bg-gray-800 rounded-lg px-9 py-3" @click="refresh()">{{lang.version}}</div>
        </div>
        <!-- BUTTON -->


        <!-- BOTTOM SPACE -->
        <div class="h-24 mt-24"></div>
        <!-- BOTTOM SPACE -->
        
        
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '../firestore.js';

export default {
  name: 'Update',
  computed: {
    lang(){
      return this.$store.state.lang.update[this.$store.state.lang.currentLang];
    },
    user () {
      return this.$store.state.data.user;
    },
    userVersion () {
      return this.$store.state.data.user.version;
    },
    version(){
      return this.$store.state.data.version.version;
    },
    bugFixes(){
      return this.$store.state.data.version.bugFixes;
    },
    improvements(){
      return this.$store.state.data.version.improvements;
    },
    features(){
      return this.$store.state.data.version.features;
    },
    upcoming(){
      return this.$store.state.data.version.upcoming;
    }
  },
  mounted(){
    document.body.style.backgroundColor = "white";
    console.log("version -> " + this.version);
    console.log("userVersion -> " + this.userVersion);
    if(this.version == '4.9'){
      console.log("Updating Notifications");
       db.collection("Users/").doc(this.user.fId).update({
          routeNotifications: true,
          updateNotifications: true
        })
        .then(() => {
          console.log("Subsribing User to Topic..");
          //subscribe new user to App Updates
          this.$store.dispatch('subscribeTopic', {fcm: this.fcm, topic: "updates"});
          this.$store.dispatch('subscribeTopic', {fcm: this.fcm, topic: this.user.homeGym});
        });
    }
  },
  watch: {
        version() {
            if (this.userVersion == this.version && this.userVersion !== '' && this.version !== ''){
              this.$router.push('/')
            }

        },
        userVersion() {
            if (this.userVersion == this.version && this.userVersion !== '' && this.version !== ''){
              //Move to Home
              this.$router.push('/')
            }

        }
    },
  methods:{
    refresh(){
      console.log("refreshing");
      this.$store.dispatch('updateUserVersion', {version:this.version, fId:this.user.fId} );
    }
  }
}
</script>
