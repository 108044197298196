<template>
  <div>
    
    <div class="w-screen h-screen superfull fixed bg-purple-600 relative">
      <div class="w-full absolute bottom-12 px-6 mb-16 flex flex-col justify-center items-center">
        
        
        <!-- PAGE WRAPPER -->
        <transition name="fade" mode="out-in">
          
          <div class="w-full" v-if="count == '1'">
            <div v-if="nameError == ''" class="pt-20 font-ageo font-bold text-3xl text-white text-left">Hey,</div>
            <div v-if="nameError == ''" class="text-lg text-white font-ageo font-normal leading-6 mt-1">{{lang.welcome1}} <br> {{lang.welcome2}}</div>
            <div v-if="nameError !== ''" class="font-ageo font-normal text-lg text-white">{{nameError}}</div>
            <div class="w-full h-16 mt-3 flex flex-row justify-center items-center bg-white rounded-lg shadow-lg">
              <input type="text" v-model="name" :placeholder=lang.name 
              class="mx-5 w-full appearance-none outline-none font-ageo font-normal text-lg text-gray-900 placeholder-gray-600">
            </div>
          </div>

          <div v-else-if="count == '2'" class="w-full">
            <div class="text-lg text-white font-ageo font-normal leading-6 mt-1">{{lang.intro1}} <br> <br> {{lang.intro2}}</div>
          </div>

          <div v-else-if="count == '3'" class="w-full">
            <div class="text-lg text-white font-ageo font-normal leading-6 mt-1">{{lang.intro3}}</div>
          </div>

          <div v-else-if="count == '4'" class="w-full">
            <div class="text-lg text-white font-ageo font-normal leading-6 mt-1">{{lang.intro4}}</div>
          </div>

          <div v-else-if="count == '5'" class="w-full">
            <div class="text-lg text-white font-ageo font-normal leading-6 mt-1">{{lang.intro5}} <br>{{lang.intro6}}</div>
          </div>

        </transition>
        <!-- PAGE WRAPPER -->
        
  
        <!-- FORWARD BUTTON -->
       
        <div class="w-full mt-3 h-16 flex flex-row justify-center items-center bg-white rounded-lg shadow-lg font-ageo font-semibold text-lg text-gray-900"
            @click="progress(true)">
          <transition name="fade" mode="out-in">
            <div v-if="count == '1'">{{lang.button1}}</div>
            <div v-else-if="count == '2'">{{lang.button2}}</div>
            <div v-else-if="count == '3'">{{lang.button3}}</div>
            <div v-else-if="count == '4'">{{lang.button4}}</div>
            <div v-else-if="count == '5'">{{lang.button5}}</div>
          </transition>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="count == '4'" class="w-full mt-3 h-16 flex flex-row justify-center items-center bg-white rounded-lg shadow-lg font-ageo font-semibold text-lg text-gray-900"
            @click="progress(false)">
            <div>{{lang.button6}}</div>
          </div>
          <div v-else></div>
        </transition>
        <!-- FORWARD BUTTON -->
  
  
        <!-- BACK BUTTON -->
        <div class="w-full h-12">
          <transition name="fade" mode="out-in">
            <div v-if="count >= '3'" class="w-full pt-3 font-ageo font-normal text-lg text-white" @click="this.count--" >{{lang.back}}</div>
          </transition>
        </div>
        <!-- BACK BUTTON -->
  
  
      </div>
    </div>
    
  </div>
</template>

<script>
import { db } from '../firestore.js';

export default {
  name: 'Hello',
  data(){
    return{
      count: 1,
      name:'',
      nameError:'',
      editModePreview: false
    };
  },
  watch: {
     fcm: function () {
       //when user granted permission and fcm was received
       //enable notifications
       console.log("Updating Notifications")
       db.collection("Users/").doc(this.user.fId).update({
          routeNotifications: true,
          updateNotifications: true
        })
        .then(() => {
          console.log("Subsribing User to Topic..")
          //subscribe new user to App Updates
          this.$store.dispatch('subscribeTopic', {fcm: this.fcm, topic: "updates"});
        });
    },
  },
  computed: {
    lang(){
      return this.$store.state.lang.intro[this.$store.state.lang.currentLang];
    },
    user () {
      return this.$store.state.data.user;
    },
    fcm () {
      return this.$store.state.data.user.fcm;
    }
  },
  mounted(){
    if (this.user.firstVisit == false){
      this.$router.push('/');
    }
  },
  methods: {
    progress(options){
      if(this.count == '1'){
        var check = this.name.replace(/\s/g,'').length;
        if (check == 0){
          this.nameError = this.lang.error1;
        } else {
          return db.collection("Users/").doc(this.user.fId).update({
          name: this.name
          })
          .then(() => {
            this.count++;
         });
        }
      } else if(this.count == '4'){
        if(options){
          this.$store.dispatch('getFCMPermission');
        }
        this.count++;
      } else if (this.count == '5'){
          this.$store.dispatch('changeUserFirstVisit', this.user.fId);
          this.$router.push('/');
      } else {
        this.count++;
      }
    }
  }
}
</script>

