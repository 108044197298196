export const gyms = {
  state: {
    de:{
       gymName: "Hallenname",
       newGym: "Neue Halle",
       newWall: 'Neue Wand',
       newSection: 'Neuer Abschnitt',
       location: "Ort auswählen",
       nameGym: "Halle benennen",
       error: "Dieser Schritt kann danach nicht mehr rückgängig gemacht werden.",
       error2: "Die Skala kann erst geändert werden, wenn sie von keiner Route mehr genutzt wird.",
       error3: "Bitte gib einen gültigen Namen ein.",
       error4: "Die Halle kann nicht gelöscht werden, solange noch Wände vorhanden sind.",
       add: "Adresse",
       add2: "Adresse angeben",
       scales: "Skalen (Offiziell / Eigene)",
       scale2: "Offizielle Skala",
       level: "Welche Level sind in deiner Halle verfügbar?",
       tags: "Tags",
       walls: "Wände",
       routes: "Routen",
       addWall: "Wand hinzufügen",
       addSection: "Abschnitt hinzufügen",
       changePos: "Reihenfolge ändern",
       wallHelp1: "Klicke auf ein Plus-Icon um eine neue Wand einzufügen.",
       wallHelp2: "Wand einfügen",
       sectionHelp1: "Klicke auf ein Plus-Icon um einen neuen Abschnitt einzufügen.",
       sectionHelp2: "Abschnitt einfügen",
       posHelp1: "Wähle die Wand oder den Abschnitt über das kleine Icon aus, den du verschieben möchtest.",
       posHelp2: "Reihenfolge ändern",
       section: "Abschnitt",
       nameSection: "Abschnitt benennen",
       sec: "Dieser Schritt kann nicht rückgängig gemacht werden.",
       empty: "Du bist der erste hier! Klicke oben rechts auf das Bearbeiten-Icon um eine neue Wand hinzuzufügen.",
       deleteGym: "Halle löschen",
       sec2: "Ganz sicher?",
       customScale: "Eigene Skala",
       officialScale: "Offizielle Skala",
       chooseTags: "Tags auswählen"
    },
    en:{
       gymName: "Gym name",
       newGym: "New Gym",
       newWall: 'New Wall',
       newSection: 'New Section',
       location: "Pick location",
       nameGym: "Set gym name",
       error: "This step cannot be reversed afterwards.",
       error2: "This scale can not be changed if there are still routes using it.",
       error3: "Please enter a valid name",
       error4: "This gym cannot be deleted if there are still walls existing.",
       add: "Address",
       add2: "Set address",
       scales: "Scales (Official / custom)",
       scale2: "Official scale",
       level: "Which difficulties are available at your gym?",
       tags: "Tags",
       walls: "Walls",
       routes: "Routes",
       addWall: "Add wall",
       addSection: "Add section",
       changePos: "Change order",
       wallHelp1: "Klick on a plus icon to create a new wall",
       wallHelp2: "Insert wall",
       sectionHelp1: "Klick on a plus icon to create a new section",
       sectionHelp2: "Insert section",
       posHelp1: "Klick on the icon of the object you want to move",
       posHelp2: "Change order",
       section: "Section",
       nameSection: "Set section name",
       sec: "This step cannot be reversed afterwards.",
       empty: "Looks like you're the first one here! Click on the pencil icon at the top right corner to create a new wall.",
       deleteGym: "Delete gym",
       sec2: "Are you sure?",
       customScale: "Custom scale",
       officialScale: "Official scale",
       chooseTags: "Choose Tags"
    }
  }
};
