export const profile = {
  state: {
    de:{
      profilbild: 'Profilbild',
      switch: 'Emoji statt Foto verwenden',
      switch2: 'Foto statt Emoji verwenden',
      beginner: 'Anfänger',
      name: 'Dein Name',
      errorName: 'Bitte gib einen gültigen Namen ein.',
      stats: 'STATISTIK',
      statsEmpty: 'Trage deine erste Route ein um Auswertungen zu sehen'
    },
    en:{
      profilbild: 'Profile image',
      switch: 'Use emoji instead of image',
      switch2: 'Use image instead of emoji',
      beginner: 'Beginner',
      name: 'Your name',
      errorName: 'Please enter a valid name.',
      stats: 'STATISTICS',
      statsEmpty: 'Log your first route to see statistics'

    }
  }
};
