export const home = {
  state: {
    de:{
      last:'ZULETZT BESUCHT',
      welcome: 'WILLKOMMEN',
      info: 'Klicke unten auf',
      info2: 'um deine Halle zu suchen.',
      newGym: "Neue Halle",
      location: "Ort auswählen"
    },
    en:{
      last:'LAST VISITED',
      welcome: 'WELCOME',
      info: 'Click on',
      info2: 'to find your gym.',
      newGym: "New Gym",
      location: "Pick location"
    }
  }
};
