export const map = {
  state: {
    de:{
      search: 'Halle suchen',
      noResult1: 'Es wurde keine Halle',
      noResult2: 'gefunden, möchtest du diese Halle anlegen?',
      noResult3: 'Es wurde kein Ort zu',
      noResult4: 'gefunden.',
      createGym: 'Neue Halle anlegen',
      cancel:' Abbrechen',
      results1: 'Ergebnisse gefunden.',
      results2: 'Es gibt',
      results3: 'Hallen in dieser Region.',
      searchResults:'Suchergebnisse',
      searchAddress:'Suche nach einer Adresse',
      searchAddress2:'oder Klicke lange auf einen Punkt, um einen Ort hinzuzufügen.',
      location:'Ort auswählen',
      newGym:'Neue Halle'
    },
    en:{
      search: 'Search',
      noResult1: 'No gym',
      noResult2: 'found. Do you want to create one?',
      noResult3: 'No location for',
      noResult4: 'found.',
      createGym: 'Create new gym',
      cancel:' Cancel',
      results1: 'results',
      results2: 'There are',
      results3: 'gyms in this region',
      searchResults:'Search results',
      searchAddress:'Search for an address',
      searchAddress2:'or long-press anywhere on the map to create a location.',
      location:'Pick location',
      newGym:'New gym'
    }
  }
};
