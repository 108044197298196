import { createStore } from 'vuex'
import { data } from './data/index.js'
import { lang } from './lang/index.js'


export default createStore({
  modules: {
    data: data,
    lang: lang
  }
})
