<template>
  <div>
    
    
    <!-- HEADING -->
    <div class="mt-20 mb-5 mx-9">
      <div v-if="gym.name" class="w-3/4 font-ageo font-semibold text-2xl text-gray-800 leading-7">{{lang.ranking}} {{checkGymName(gym.name)}}</div>
      <div v-else class="font-ageo font-normal text-lg text-gray-400 text-start">{{lang.noGym}}</div>
    </div>
    <!-- HEADING -->
    
    
    <!-- RANKING SELF -->
    <div v-if="ranking && userIndex > 1" class="w-full px-9 mb-10">
      <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-2">{{lang.score}}</div>
      <div v-if="gym.activeUsers[user.fId]" class="mt-4 rounded-2xl shadow-c px-4 py-3 flex flex-row justify-between items-center" :class="{'transition duration-500 ease-in-out ring-2 ring-yellow-300' : toggleSelf}" @click="toggleSelf = !toggleSelf">
        <div v-if="user.profile == 'emoji'" class="w-10 h-10 rounded-xl bg-yellow-200 flex-shrink-0 mr-5 flex flex-row justify-center items-center">{{user.emoji}}</div>
        <div v-else class="w-10 h-10 bg-local bg-center bg-no-repeat bg-cover rounded-xl flex-shrink-0 mr-5 border border-gray-100"
            :style="{ backgroundImage: 'url(' +  user.image  + ')' }"></div>
        <div v-if="userList && userIndex !== -1" class="flex flex-row justify-between items-center flex-grow">
          <div class="flex flex-col">
            <div class="font-ageo font-semibold text-gray-800 text-base leading-5">#{{userIndex +1}}</div>
            <div class="font-ageo font-normal text-gray-800 text-base leading-5">{{user.name}}</div>
          </div>
            <div v-if="sorting == 'points'" class="font-ageo font-semibold text-purple-600 text-base mr-3">{{userList[userIndex].points}} Pkt.</div>
            <div v-if="sorting == 'totalRoutes'" class="font-ageo font-semibold text-purple-600 text-base mr-3">{{userList[userIndex].topped + userList[userIndex].flashed}} {{lang.routes}}</div>
        </div>
      </div>
      
      
        <!-- RANKING DETAILS SELF -->
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="toggleSelf" class="mt-5 px-2">
            <div class="flex flex-row">
              <div class="font-ageo font-semibold text-2xl mr-3">{{flashRate(userList[userIndex].flashed, userList[userIndex].topped + userList[userIndex].flashed)}}%</div>
              <div class="relative h-1 rounded-lg w-full bg-gray-200 mb-9 mt-5">
                <div class="absolute w-full inset-0 -top-5 flex flex-row justify-between items-center mb-1">
                  <div class="font-ageo font-normal text-gray-800 text-sm">{{lang.flashrate}}</div>
                  <div class="font-ageo font-normal text-gray-800 text-sm">{{userList[userIndex].topped + userList[userIndex].flashed}}</div>
                </div>
                <div class="absolute inset-0 -top-0.5 w-full flex flex-row">
                  <div v-if="userList[userIndex].flashed > 0" class="h-2 rounded-lg bg-purple-600 mx-1" :class="[width(userList[userIndex].flashed, userList[userIndex].topped + userList[userIndex].flashed)]">
                    <div class="font-ageo font-semibold text-purple-600 text-base text-center mt-2.5">{{userList[userIndex].flashed}} {{lang.flash}}</div>
                  </div>
                  <div v-if="userList[userIndex].topped > 0" class="h-2 rounded-lg bg-yellow-300 mx-1" :class="[width(userList[userIndex].topped, userList[userIndex].topped + userList[userIndex].flashed)]">
                    <div class="font-ageo font-semibold text-yellow-300 text-base text-center mt-2.5">{{userList[userIndex].topped}} {{lang.top}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- RANKING DETAILS SELF -->
        
        
    </div>
    <!-- RANKING SELF -->
  
  
    <!-- RANKING -->
    <div v-if="ranking" class="px-9">
      <div class="flex flex-row justify-between items-center mb-2">
        <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest">{{lang.rankingType}} {{rankingText}}</div>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="switchRanking()">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
        </svg>
      </div>
      <div v-for="(singleUser,index) in userList" class="w-full" @click="toggleSelectedUser(index)">
        <div v-if="gym.activeUsers[singleUser.id]" class="mt-4 rounded-2xl shadow-c px-4 py-3 flex flex-row justify-between items-center" :class="{'transition duration-500 ease-in-out ring-2 ring-yellow-300' : selectedUser == index}">
          <div v-if="gym.activeUsers[singleUser.id].profile == 'emoji'" class="w-10 h-10 rounded-xl bg-yellow-200 flex-shrink-0 mr-5 flex flex-row justify-center items-center">{{gym.activeUsers[singleUser.id].emoji}}</div>
          <div v-else-if="userImage[userImage.findIndex(x => x.user === singleUser.id)]" class="w-10 h-10 bg-local bg-center bg-no-repeat bg-cover rounded-xl flex-shrink-0 mr-5 border border-gray-100"
              :style="{ backgroundImage: 'url(' +  userImage[userImage.findIndex(x => x.user === singleUser.id)].image  + ')' }"></div>
          <div class="flex flex-row justify-between items-center flex-grow">
            <div class="flex flex-col">
              <div class="font-ageo font-semibold text-gray-800 text-base leading-5">#{{index +1}}</div>
              <div class="font-ageo font-normal text-gray-800 text-base leading-5">{{gym.activeUsers[singleUser.id].name}}</div>
            </div>
              <div v-if="sorting == 'points'" class="font-ageo font-semibold text-purple-600 text-base mr-3">{{singleUser.points}} {{lang.points}}</div>
              <div v-if="sorting == 'totalRoutes'" class="font-ageo font-semibold text-purple-600 text-base mr-3">{{singleUser.topped + singleUser.flashed}} {{lang.routes}}</div>
          </div>
        </div>
      
        <!-- RANKING DETAILS -->
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="selectedUser == index" class="mt-5 px-2">
            <div class="flex flex-row">
              <div class="font-ageo font-semibold text-2xl mr-3">{{flashRate(singleUser.flashed, singleUser.topped + singleUser.flashed)}}%</div>
              <div class="relative h-1 rounded-lg w-full bg-gray-200 mb-9 mt-5">
                <div class="absolute w-full inset-0 -top-5 flex flex-row justify-between items-center mb-1">
                  <div class="font-ageo font-normal text-gray-800 text-sm">{{lang.flashrate}}</div>
                  <div class="font-ageo font-normal text-gray-800 text-sm">{{singleUser.topped + singleUser.flashed}}</div>
                </div>
                <div class="absolute inset-0 -top-0.5 w-full flex flex-row">
                  <div v-if="singleUser.flashed > 0" class="h-2 rounded-lg bg-purple-600 mx-1" :class="[width(singleUser.flashed, singleUser.topped + singleUser.flashed)]">
                    <div class="font-ageo font-semibold text-purple-600 text-base text-center mt-2.5">{{singleUser.flashed}} {{lang.flash}}</div>
                  </div>
                  <div v-if="singleUser.topped > 0" class="h-2 rounded-lg bg-yellow-300 mx-1" :class="[width(singleUser.topped, singleUser.topped + singleUser.flashed)]">
                    <div class="font-ageo font-semibold text-yellow-300 text-base text-center mt-2.5">{{singleUser.topped}} {{lang.top}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- RANKING DETAILS -->
      
      </div>
    </div>
    <!-- RANKING -->
    
    
    <!-- RANKING EMPTY -->
    <div v-else class="px-9 font-ageo font-normal text-lg text-gray-400">{{lang.empty1}} {{checkGymName(gym.name)}} {{lang.empty2}}</div>
    <!-- RANKING EMPTY -->
    
 
    <!-- BOTTOM SPACE -->
    <div class="h-24 mt-24"></div>
    <!-- BOTTOM SPACE -->
  
  
  </div>
</template>

<script>
import { db } from '../firestore.js';
import { storage } from '../firestore.js';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'Ranking',
  data(){
    return{
      userImage:[],
      selectedUser:null,
      toggleSelf: false,
      sorting: "points",
      rankingText: ""
    };
  },
  computed: {
    lang(){
      return this.$store.state.lang.ranking[this.$store.state.lang.currentLang];
    },
    detach(){
      return this.$store.state.data.detach;
    },
    user () {
      return this.$store.state.data.user
    },
    gym(){
        return this.$store.state.data.homeGym;
    },
    ranking(){
      return this.$store.state.data.ranking
    },
    userIndex(){
      return this.userList.findIndex(x => x.id === this.user.fId)
    },
    userList(){
      var userList = [];
      
      for (var key in this.ranking) {
        //route stats
       var points = parseInt(0);
       var total = this.ranking[key].flashedBy.length + this.ranking[key].toppedBy.length;
       var flashRate = Math.round((100 / (total) * this.ranking[key].flashedBy.length)*100)/100;
       if (total !== 0){
        points = Math.round(1000 / total);
         var flashPoints = Math.round(points * (1 - flashRate / 100));
       }
       
       
        //scanning flasher
        var flasher = this.ranking[key].flashedBy;
        flasher.forEach(element => {
          
          if (!userList.some(user => user.id === element)){
            //add user
            userList.push({
              id: element,
              points: flashPoints,
              flashed: 1,
              topped: 0
            })
          } else {
            //only update points
            var foundIndex = userList.findIndex(x => x.id == element);
            var found = userList.find(x => x.id == element);
            userList[foundIndex].points = parseInt(found.points) + flashPoints;
            userList[foundIndex].flashed = found.flashed + 1;
            
          }
        });
        
        //scanning topper
        var topper = this.ranking[key].toppedBy;
        topper.forEach(element => {
          if (!userList.some(user => user.id === element)){
            //add user
            userList.push({
              id: element,
              points: points,
              flashed: 0,
              topped: 1
            })
          } else {
            //only update points
            var foundIndex = userList.findIndex(x => x.id == element);
            var found = userList.find(x => x.id == element);
            userList[foundIndex].points = parseInt(found.points) + points
            userList[foundIndex].topped = found.topped + 1;
            
          }
        });
    }
    if(this.sorting == "points"){
      var userListSorted = userList.sort((a, b) => (a.points < b.points) ? 1 : -1);
      return userListSorted
    }
    
    if(this.sorting == "totalRoutes"){
      var userListSorted = userList.sort((a, b) => ((a.flashed + a.topped) < (b.flashed + b.topped)) ? 1 : -1);
      return userListSorted
    }
    
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    this.rankingText = this.lang.punkte;
    this.$store.dispatch('loadRanking', db.collection("Gyms/"+ this.gym.id +"/ranking"));
    if(this.gym.activeUsers){
      const stack = this.gym.activeUsers;
      var that = this;
      Object.keys(stack).forEach(function (key, index) {
       var item = stack[key];
           var pathReference = storage.ref(item.image);
             pathReference.getDownloadURL()
              .then((url) => {
                that.userImage.push({
                  user: item.user,
                  image: url
                });
              })
              .catch((error) => {
                console.log("Possible case of deleted user fragments");
                that.$store.dispatch('checkUserFragments', {fId: item.user, gymId: that.gym.id});
              });
     });
    }
  },
  beforeUnmount(){
    this.$store.commit('detachRanking');
  },
  methods: {
    
    //ANIMATIONS
    //
    
    fadeInGrowEnter(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: [0,1],
        'max-height': '500px',
        complete: done
      });
    },
    fadeInGrowLeave(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: 0,
        'max-height': '0px',
        complete: done
      });
    },
    
    toggleSelectedUser(index){
      if(this.selectedUser !== index){
        this.selectedUser = index;
      } else {
        this.selectedUser = null;
      }
    },
    
    //FUNCTIONS
    //
    
    checkGymName(name){
      if(name && name == 'Neue Halle'){
        return this.lang.newGym;
      } else {
        return name
      }
    },
    
    switchRanking(){
      if(this.sorting == "points"){
        this.sorting = "totalRoutes";
        this.rankingText = this.lang.begehungen;
      } else {
        this.sorting = "points";
        this.rankingText = this.lang.punkte;
      }
    },
    
    width(value, total){
      if (typeof value == 'undefined'){
        value = 0;
      }
      if (typeof total == 'undefined'){
        total = 0;
      }
      if (total !== 0 && total > 0 && value > 0){
        var progress = Math.round(100 / total * value * 1.2 / 10);
        if(progress == 12){
          return 'w-full';
        } else {
        return 'w-' + progress + '/12';
        }
      } else {
        return 'w-0';
      }
    },
    
    flashRate(flash, total){
      return Math.round(100 / total * flash);
    }
    
  }
};
  
</script>

<style>
</style>
