export const auth = {
  state: {
    de:{
      intro: 'Die erste Kletter- & Boulderapp, die komplett von der Community verwaltet wird.',
      error1: 'Bitte fülle alle Felder aus.',
      success1: 'Du bekommst in Kürz eine Email, mit der du dein Passwort zurücksetzen kannst.',
      login1: 'Mit Google anmelden',
      email: 'Email Adresse',
      passwort: 'Passwort',
      login2: 'Mit Email anmelden',
      login3: 'Einloggen',
      account: 'Account erstellen',
      reset: 'Passwort zurücksetzen',
      create1: 'Du bist neu hier?',
      create2: 'Account erstellen',
      back: 'Zurück',
      passwordForgot: 'Passwort vergessen?'
    },
    en:{
      intro: 'The first climbing and bouldering app that is completely managed by the community.',
      error1: 'Please fill in all fields.',
      success1: 'You will receive an email shortly with a link to reset your password.',
      login1: 'Login with Google',
      email: 'Email address',
      passwort: 'Password',
      login2: 'Login with email',
      login3: 'Login',
      account: 'Create account',
      reset: 'Reset password',
      create1: 'No account?',
      create2: 'Create account',
      back: 'Go back',
      passwordForgot: 'Forgot password?'
    }
  }
};
