export const navbar = {
  state: {
    de:{
      editMode:'Bearbeitungsmodus beenden' 
    },
    en:{
      editMode:'End edit mode' 
    }
  }
};
