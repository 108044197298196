<template>
<div>

  <div class="mt-20 mb-5 mx-9">
    <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-2">{{lang.intro}}</div>
    <div class="w-3/4 font-ageo font-semibold text-2xl text-gray-800 leading-7">{{lang.sub}}</div>
  </div>



</div>
</template>

<script>
export default {
  name: 'Community',
  computed: {
    lang(){
      return this.$store.state.lang.community[this.$store.state.lang.currentLang];
    }
  }
}
</script>

<style>

</style>
