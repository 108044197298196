<template>
  <div>
      
      
    <!-- BACK BUTTON -->
    <div class="fixed top-0 left-0 ml-4 mt-4 w-9 h-9 z-20 flex flex-row justify-center items-center" @click="this.$router.replace({ path: '/User' })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 stroke-current stroke-2 text-purple-600">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </div> 
    <!-- BACK BUTTON -->
    
    <div id="inside">
      
      <!-- HEADING -->
      <div class="mt-20 mb-5 mx-9">
        <div class="w-3/4 font-ageo font-semibold text-2xl text-gray-800 leading-7">{{lang.einstellungen}}</div>
      </div>
      <!-- HEADING -->
  
  
      <!-- SETTINGS WRAPPER -->
      <div class="mx-5">
        
        
        <!-- NOTIFICATIONS -->
        <div class="relative w-full pl-4 mt-2 font-ageo font-semibold text-purple-600 text-xs tracking-widest">{{lang.benachrichtigungen}}</div>
        <div v-if="permission">
          
          
          <!-- SETTING -->
          <div class="flex flex-row justify-between items-center mt-3 px-4" >
            <div class="font-ageo font-normal">{{lang.routen}}</div>
              <div class="w-12 h-7 px-0.5 rounded-full bg-gray-200 flex flex-row items-center" ref="routeNotifications" @click="notificationSwitch('routeNotifications', 'routeNotificationsSwitch', user.routeNotifications, user.homeGym)">
                  <div ref="routeNotificationsSwitch" class="w-6 h-6 rounded-full bg-white shadow-sm"></div>
              </div>
          </div>
          <!-- SETTING -->
          
          <!-- SETTING -->
          <div class="flex flex-row justify-between items-center mt-3 px-4" >
            <div class="font-ageo font-normal">{{lang.updates}}</div>
              <div class="w-12 h-7 px-0.5 rounded-full bg-gray-200 flex flex-row items-center" ref="updateNotifications" @click="notificationSwitch('updateNotifications', 'updateNotificationsSwitch', user.updateNotifications, 'updates')">
                  <div ref="updateNotificationsSwitch" class="w-6 h-6 rounded-full bg-white shadow-sm"></div>
              </div>
          </div>
          <!-- SETTING -->
          
          
        </div>
        <div v-else class="px-4 font-ageo font-normal mt-3">{{lang.benachrichtigungen2}}</div>
        <!-- NOTIFICATIONS -->
        
        
        <!-- FEEDBACK -->
        <div class="relative w-full pl-4 mt-8 font-ageo font-semibold text-purple-600 text-xs tracking-widest">{{lang.feedback}}</div>
        
          <!-- SETTING -->
          <div class="flex flex-row justify-between items-center mt-3 px-4" @click="feedback = !feedback">
            <div class="font-ageo font-normal">{{lang.sendFeedback}}</div>
          </div>
          <transition name="fadeFast" mode="out-in">
            <div v-if="feedback" class="mt-3 px-4">
              <textarea v-model="message" :placeholder="lang.feedbackPlaceholer" rows="5" 
                class="w-full appearance-none outline-none px-5 py-5 h-auto inset-0 font-ageo font-normal text-normal text-gray-900 bg-white border border-gray-600 rounded"></textarea>
              <div class="w-full mt-2 h-16 bg-gray-900 rounded-lg shadow-sm">
                <div class="h-full flex flex-col justify-center items-center" @click="sendFeedback(message)">
                  <div class="font-ageo font-semibold text-lg text-white">{{lang.sendFeedback}}</div>
                </div>
              </div>
            </div>
          </transition>
          <div v-if="messageSend !== ''" class="font-ageo font-normal mt-3 px-4">{{messageSend}}</div>
          <!-- SETTING -->
        <!-- FEEDBACK -->
        
        
        
        <!-- ACCOUNT -->
        <div class="relative w-full pl-4 mt-8 font-ageo font-semibold text-purple-600 text-xs tracking-widest">{{lang.account}}</div>
        
        
          <!-- SETTING -->
          <div class="flex flex-row justify-start items-center mt-3 px-4">
            <div class="font-ageo font-normal">{{lang.sprache}}</div>
            <div class="relative font-ageo font-normal text-purple-600 ml-3">
              <div class="absolute">{{currentFullLang.sprache}}</div>
              <select v-model="selectLang" class="absolut w-full h-full inset-0 appearance-none opacity-0">
                <option disabled value="">{{lang.spracheSel}}</option>
                <option v-for="lang in languages" :value="lang.code">
                  {{ lang.sprache }}
                </option>
              </select>
            </div>
          </div>
          <!-- SETTING -->
          
          
          <!-- SETTING -->
          <div class="flex flex-row justify-between items-center mt-3 px-4">
            <div v-if="user.auth" class="font-ageo font-regularitalic italic text-gray-500">{{lang.loggedIn}} <br> {{user.auth.email}}</div>
          </div>
          <div class="px-4">
            <div v-if="user.provider == 'google'" class="flex flex-row items-center">
              <div class="font-ageo font-regularitalic italic text-gray-500 mr-3">{{lang.loggedIn2}}</div>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" class="w-6 h-6">
                <defs>
                  <path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/>
                </defs>
                <clipPath id="b">
                  <use xlink:href="#a" overflow="visible"/>
                </clipPath>
                <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/>
                <path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/>
                <path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/>
                <path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>
              </svg>
            </div>
            <div v-if="user.provider == 'apple'" class="flex flex-row items-center">
              <div class="font-ageo font-regularitalic italic text-gray-500 mr-3">{{lang.loggedIn3}}</div>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1200 1200" class="w-6 h-6 text-gray-900 mb-0.5">
                <path fill-rule="evenodd" d="M702 960c-54.2 52.6-114 44.4-171 19.6-60.6-25.3-116-26.9-180 0-79.7 34.4-122 24.4-170-19.6-271-279-231-704 77-720 74.7 4 127 41.3 171 44.4 65.4-13.3 128-51.4 198-46.4 84.1 6.8 147 40 189 99.7-173 104-132 332 26.9 396-31.8 83.5-72.6 166-141 227zM423 237C414.9 113 515.4 11 631 1c15.9 143-130 250-208 236z"/>
              </svg>
            </div>
          </div>
          <!-- SETTING -->
          
          
          <!-- SETTING -->
          <div class="flex flex-row justify-between items-center mt-3 px-4" @click="logout()">
            <div class="font-ageo font-normal">{{lang.ausloggen}}</div>
          </div>
          <!-- SETTING -->
          
          <!-- SETTING -->
          <div class="flex flex-col justify-start items-start mt-3 px-4">
            <div class="font-ageo font-normal" @click="toggleDelete = !toggleDelete">{{lang.delete}}</div>
            <transition name="fadeFast" mode="out-in">
            <div v-if="toggleDelete" class="w-full">
              <div v-if="user.provider == ''" class="w-full h-16 mt-3 border border-gray-900 flex flex-row justify-center items-center bg-white rounded-lg shadow-sm">
                <input type="password" v-model="pw" :placeholder="lang.passwortPlaceholer" 
                class="mx-5 w-full appearance-none outline-none font-ageo font-normal text-gray-900 placeholder-gray-600">
              </div>
              <div v-if="!user.provider == '' && user.session == null" class="w-full mt-3 h-16 bg-gray-900 rounded-lg shadow-sm">
                <div class="h-full flex flex-col justify-center items-center"
                  @click="reauth()">
                <div class="font-ageo font-semibold text-lg text-white">{{lang.reLogin}}</div>
              </div>
              </div>
              <div v-if="user.provider == '' || user.session !== null" class="w-full mt-3 h-16 bg-gray-900 rounded-lg shadow-sm">
                <div class="h-full flex flex-col justify-center items-center"
                  @click="deleteAccount(pw)">
                <div class="font-ageo font-semibold text-lg text-white">{{lang.delete}}</div>
              </div>
              </div>
              <div v-if="!user.provider == '' && user.session == null" class="font-ageo font-normal mt-3">{{lang.delete2}}</div>
              <div v-if="deleteError !== ''" class="font-ageo font-normal mt-3">{{deleteError}}</div>
            </div> 
            </transition>
          </div>
          <!-- SETTING -->
        <!-- ACCOUNT -->
        
        
      </div>
      <!-- SETTINGS WRAPPER -->
  
    </div>
  </div>
</template>
  
<script>
import { db } from '../firestore.js';
import anime from 'animejs/lib/anime.es.js';
import { auth } from '../firestore.js';
import { firebaseRoot } from '../firestore.js';

export default {
  name: 'Settings',
  data(){
    return {
      selectLang: '',
      permission: true,
      toggleDelete: false,
      pw:'',
      deleteError:'',
      message:'',
      messageSend:'',
      feedback: false
    };
  },
  computed:{
    lang(){
      return this.$store.state.lang.settings[this.$store.state.lang.currentLang];
    },
    currentLang(){
      return this.$store.state.lang.currentLang
    },
    currentFullLang(){
      var langIndex = this.$store.state.lang.availableLang.findIndex(x => x.code === this.$store.state.lang.currentLang);
      return this.$store.state.lang.availableLang[langIndex];
    },
    languages(){
      return this.$store.state.lang.availableLang;
    },
    moveBackwards(){
      return this.$store.state.data.moveBackwards;
    },
    user(){
      return this.$store.state.data.user;
    }
  },
  watch: {
    
    selectLang: function(){
      if(this.currentLang !== this.selectLang){
        this.$store.dispatch('updateLang', {fId: this.user.fId, lang: this.selectLang});
      }
    },
    
    moveBackwards: function(){
      if(this.moveBackwards){
        this.$router.push({ path: '/User' });
        }
    }
  },
  mounted(){
    this.selectLang = this.currentLang
    
    this.$store.commit('resetMoveBackwards');
    window['checkNativePermission'] = (permission)=>{
    this.checkNativePermission(permission);
    };
    if(window.webkit && window.webkit.messageHandlers){
      window.webkit.messageHandlers.checkPermission.postMessage('Checking Permission..');
    }
    if(window.Android){
      window.Android.checkPermission("Checking Permission..");
    }
    if(this.user.routeNotifications){
      this.notificationSwitch('routeNotifications', 'routeNotificationsSwitch', this.user.routeNotifications, null, true);
    }
    if(this.user.updateNotifications){
      this.notificationSwitch('updateNotifications', 'updateNotificationsSwitch', this.user.routeNotifications, null, true);
    }
  },
  methods:{
    //----------------------------------------------//
    //------------------ANIMATIONS------------------//
    //----------------------------------------------//
    notificationSwitch(ref, ref2, setting, topic, options){
      if(this.user.fcm){
        if(!setting || options){
        anime({
          targets: this.$refs[ref2],
          easing: 'easeInOutCubic',
          duration: 250,
          translateX: '1.25rem',
        });
      anime({
          targets: this.$refs[ref],
          easing: 'easeInOutCubic',
          duration: 250,
          backgroundColor: '#FCD34D'
        });
        db.collection("Users/").doc(this.user.fId).update({
          [ref]: true
        })
        .then(() => {
          if(!options){
          this.$store.dispatch('subscribeTopic', {fcm: this.user.fcm, topic: topic});
          }
        });
      } else {
        anime({
          targets: this.$refs[ref2],
          easing: 'easeInOutCubic',
          duration: 250,
          translateX: 0,
        });
      anime({
          targets: this.$refs[ref],
          easing: 'easeInOutCubic',
          duration: 250,
          backgroundColor: '#E5E7EB'
        });
        db.collection("Users/").doc(this.user.fId).update({
          [ref]: false
        })
        .then(() => {
          if(!options){
          this.$store.dispatch('unsubscribeTopic', {fcm: this.user.fcm, topic: topic});
          }
        });
      }
      } else {
        console.log("NO PERMISSION");
        //POPUP für Benachrichtigungsabfrage
      }
      
    },
    //----------------------------------------------//
    //-------------------FUNCTIONS------------------//
    //----------------------------------------------//
    
    
     sendFeedback(message){
      if(message){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          "name":this.user.name,
          "id":this.user.fId,
          "message":message
        });
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch("https://hook.integromat.com/ga193f0wc66dfacu1uxb5s842xnd3kyk", requestOptions)
          .then(response => response.text())
          .then(result => {
            this.messageSend = "Danke für das Feedback!";
            this.feedback = false;
          })
          .catch(error => {
            console.log('error', error);
            this.messageSend = error;
          });
      } else {
        console.log("No Message");
      }
    },
    
    
    checkNativePermission(permission){
      console.log("permission -> " + permission);
      if(permission == "false"){
        this.permission = false;
      } else if(permission == "true"){
        this.permission = true;
      }
    },
    
    
    logout(){
      this.$store.commit('detachHomeGym');
      this.$store.commit('detachGyms');
      this.$store.commit('detachWalls');
      this.$store.commit('detachWall');
      this.$store.commit('detachRanking');
      this.$store.commit('detachUser');
      auth.signOut().then(() => {
        this.$router.push({name: 'Auth' });
      }).catch((error) => {
        console.log(error);
      });
    },
    
    
    reauth(){
      if(this.user.provider == 'google' && this.user.session == null){
        auth.currentUser.reauthenticateWithRedirect(new firebaseRoot.auth.GoogleAuthProvider());
      }
      if(this.user.provider == 'apple' && this.user.session == null){
        auth.currentUser.reauthenticateWithRedirect(new firebaseRoot.auth.OAuthProvider('apple.com'));
      }
    },
    
    
    deleteAccount(pw){
      var user = auth.currentUser;
      var credential;
      if (this.user.provider == ''){
        credential = firebaseRoot.auth.EmailAuthProvider.credential(
            user.email, 
            pw
          );
      } else {
        credential = this.user.session;
      }
      user.reauthenticateWithCredential(credential)
      .then(() => {
        this.$store.commit('detachHomeGym');
        this.$store.commit('detachGyms');
        this.$store.commit('detachWalls');
        this.$store.commit('detachWall');
        this.$store.commit('detachRanking');
        this.$store.commit('detachUser');
        db.collection("Users/").doc(this.user.fId).delete().then(() => {
            user.delete().then(() => {
              this.$router.push({name: 'Auth' });
            });
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
      }).catch((error) => {
       this.deleteError = error.message;
      });
    }
  }
};
</script>