<template>
  <div v-if="this.$route.name !== 'Update' && this.$route.name !== 'Settings'">
<transition name="fade" mode="out-in">
  <div v-if="this.$route.name !== 'Auth' && this.$route.name !== 'Map' && this.$route.name !== 'Community' && this.$route.name !== 'Intro' && this.$route.name !== 'Settings' && this.$route.name !== 'Home' && this.$route.name !== 'Update' && this.$route.name !== 'Ranking'" class="fixed top-0 right-0 mr-4 mt-4 flex flex-row justify-center items-center">
    
    
    
    <!-- EDIT MODE BUTTON -->
    <div class="fixed top-0 right-0 mr-4 mt-4 min-w-9 h-9 z-20 flex flex-row justify-center items-center" @click="editModeToggle()">
      <transition name="fade" mode="out-in">
        <div v-if="!editMode">
          <svg class="w-6 h-6 transition duration-500 ease-in-out text-purple-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
        </div>
        <div v-else class="h-7 px-2 text-pink-600 font-ageo font-normal bg-pink-200 rounded-lg flex flex-row justify-center items-center">
          <div>{{lang.editMode}}</div>
        </div>
      </transition>
    </div> 
    <!-- EDIT MODE BUTTON -->
    
    <div v-if="this.$route.name == 'Settings'" class="w-9 h-9 rounded-xl p-2" @click="logout()">
    <svg class="transition duration-500 ease-in-out text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
    </svg>
    </div>
  </div>
</transition>

  <div v-if="this.$route.name !== 'Auth' && this.$route.name !== 'Intro'"
  class="h-24 fixed bottom-0 flex flex-row w-screen justify-between items-start px-12 pt-5 z-50 bg-white shadow-t rounded-t-3xl">


      <router-link :to="{name: 'Home' }">
        <div class="iconWrapper">
          <div class="w-6 h-6">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="svgInactive"
              :class="{ 'svgActive' : this.$route.name == 'Home' || this.$route.name == 'Gyms' || this.$route.name == 'Walls' || this.$route.name == 'Routes'}">
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
          </div>
        </div>
      </router-link>



<div @click="validateRoute('Explore')">
  <div class="iconWrapper">
    <div class="w-6 h-6">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="svgInactive"
      :class="{ 'svgActive' : this.$route.name == 'Explore'}">
        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
      </svg>
    </div>
  </div>
</div>

<div @click="validateRoute('Profile')">
<div v-if="user.profile == 'foto'" class="rounded-full w-14 h-14 shadow-lg -mt-10 bg-purple-200 flex flex-row justify-center items-center overflow-hidden bg bg-local bg-center bg-no-repeat bg-cover"
 :style="{ backgroundImage: 'url(' +  user.image  + ')' }">
  
</div>
<div v-else class="rounded-full w-14 h-14 shadow-lg -mt-10 bg-yellow-200 flex flex-row justify-center items-center overflow-hidden bg bg-local bg-center bg-no-repeat bg-cover">
<div class="font-ageo text-3xl pt-2">{{user.emoji}}</div>
</div>
</div>

      <div @click="validateRoute('Ranking')">
        <div class="iconWrapper">
          <div class="w-6 h-6">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="svgInactive"
            :class="{ 'svgActive' : this.$route.name == 'Ranking'}">
            <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
          </svg>
        </div>
    </div>
  </div>



  <div @click="validateRoute('Community')">
    <div class="iconWrapper">
      <div class="w-6 h-6">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"  class="svgInactive" :class="{ 'svgActive' : this.$route.name == 'Community'}">
          <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
        </svg>
        </div>
        </div>
      </div>

  </div>
  </div>
</template>

<script>
import { auth } from '../firestore.js'

export default {
  name: 'navbar',
  computed: {
    lang(){
      return this.$store.state.lang.navbar[this.$store.state.lang.currentLang];
    },
    userVersion(){
      return this.$store.state.data.user.version
    },
    version(){
      return this.$store.state.data.version.version
    },
    editMode () {
      return this.$store.state.data.editMode
    },
    orientation(){
      return this.$store.state.data.orientation
    },
    user () {
      return this.$store.state.data.user
    },
    detach(){
      return this.$store.state.data.detach
    }
  },
  watch: {
        version(newVersion) {
            console.log(`Serverversion changed: ${newVersion}`);
            //Check if User Version is different
            if (typeof this.version !== 'undefined'){
            if (this.userVersion !== this.version && this.userVersion !== '' && this.version !== ''){
              //Move to refresh page
              this.$router.push({ name:'Update' })
            }
          }

        },
        userVersion(newVersion) {
            console.log(`Userversion changed: ${newVersion}`);
            //Check if User Version is different
            if (typeof this.userVersion !== 'undefined'){
              if (this.userVersion !== this.version && this.userVersion !== '' && this.version !== ''){
                //Move to refresh page
                this.$router.push({ name:'Update' })
              }
            }


        }
    },
  methods: {
    
    validateRoute(route){
      console.log(route)
      console.log(this.$route.meta.utility)
      if(route == "Community" || route =="Explore" || route == "Profile" || route == "Ranking"){
        if(this.$route.meta.utility){
          this.$router.replace({ name: route })
        } else {
          this.$router.push({name: route})
        }
      }
      
    },
    
     logout(){
        //Detach all listeners
        this.$store.commit('detachHomeGym');
        this.$store.commit('detachGyms');
        this.$store.commit('detachWalls');
        this.$store.commit('detachWall');
        this.$store.commit('detachUser');
      auth.signOut().then(() => {
        this.$router.push({ path: '/' })
      }).catch((error) => {
        console.log(error);
      });
    },
    editModeToggle: function(){
      if (this.editMode == false){
        //activate edit Mode
        console.log("activating");
        this.$store.commit('toggleEditMode', 'on');
      } else {
        //deactivate edit Mode
        console.log("deactivating");
        this.$store.commit('toggleEditMode', 'off');
      }
    }
  }
}
</script>

<style>



.test {
  color: black;
  padding: env(safe-area-inset-top);
}

.showNavText{
  @apply  transition duration-500 ease-in-out visible opacity-100
}
.editInactive{
  @apply transition duration-500 ease-in-out stroke-current stroke-2 text-purple-600
}
.svgInactive{
  @apply transition duration-500 ease-in-out stroke-current text-gray-300 stroke-0
}
.svgActive{
  @apply transition duration-500 ease-in-out text-purple-400
}
.iconWrapper{
  @apply flex flex-col justify-around items-center
}


</style>
