import { db } from '../../firestore.js'
import { base } from '../../firestore.js'
import { getImageUrlFromStorage } from '../../functions.js'
import router from '../../router'
import { storage } from '../../firestore.js'
import { firebaseRoot } from '../../firestore.js'
import { functions } from '../../firestore.js';

export const data = {
  state: {
    moveBackwards: false,
    version:'',
    counter: 0,
    map: false,
    user:{
      session:null,
      signedIn: false,
      provider:'',
      name:'',
      auth:null,
      version:'',
      fId:'',
      fcm:'',
      firstVisit: false,
      homeGym:'',
      image:'',
      thumbnail:'',
      profile:'',
      emoji:'',
      stats:[]
    },
    routes:[],
    walls:[],
    gyms:[],
    cluster:{},
    moreToLoad: '',
    last: null,
    homeGym:{},
    mapGym:{},
    homeWall:{},
    ranking:{},
    basics: {
      scales:[],
      levels:[],
      customLevels:[],
      types:[],
      holdColors:[],
      gymTags:[],
      emojis:[]
    },
    editMode: false,
    uploadingImage: false,
    uploadProgress: '',
    detach:{
      homeGym:null,
      gyms:null,
      walls:null,
      wall:null,
      user:null,
      ranking:null
    },
    orientation:{
      currentWallIndex:null,
      currentRouteIndex:null
    }
  },
  mutations:{

    //----------------------------------------------//
    //-----------------DETACHER---------------------//
    //----------------------------------------------//

    addDetachHomeGym(state, unsubscribe){
      state.detach.homeGym = unsubscribe;
    },

    addDetachGyms(state, unsubscribe){
      state.detach.gyms = unsubscribe;
    },

    addDetachWalls(state, unsubscribe){
      state.detach.walls = unsubscribe;
    },

    addDetachWall(state, unsubscribe){
      state.detach.wall = unsubscribe;
    },
    
    addDetachUser(state, unsubscribe){
      state.detach.user = unsubscribe;
    },
    
    addDetachRanking(state, unsubscribe){
      state.detach.ranking = unsubscribe;
    },
    

    detachHomeGym(state){
      if (state.detach.homeGym !== null){
      state.detach.homeGym();
      state.detach.homeGym = null;
      }
    },

    detachGyms(state){
      state.gyms = [];
      state.moreToLoad = '';
    },

    detachWalls(state){
      if (state.detach.walls !== null){
      state.detach.walls();
      state.detach.walls = null;
      }
    },

    detachWall(state){
      if (state.detach.wall !== null){
      state.detach.wall();
      state.detach.wall = null;
      }
    },
    
    detachUser(state){
      if (state.detach.user !== null){
      state.detach.user();
      state.detach.user = null;
      }
    },
    
    detachRanking(state){
      if (state.detach.ranking !== null){
      state.detach.ranking();
      state.detach.ranking = null;
      }
    },

    //----------------------------------------------//
    //-----------------ORIENTATION------------------//
    //----------------------------------------------//

    orientationSetCurrentWallIndex(state, index){
      state.orientation.currentWallIndex = index
    },
    
    setLastRouteIndex(state, index){
      state.orientation.currentRouteIndex = index
    },
    

    //----------------------------------------------//
    //-----------------INIT RELATED-----------------//
    //----------------------------------------------//

    setGymTags(state, tags){
      state.basics.gymTags = tags.tags
    },

    setTypes(state, types){
      state.basics.types = types.names
    },

    setHolds(state, holds){
      state.basics.holdColors = holds.colors
    },
    
    setEmojis(state, emojis){
      state.basics.emojis = emojis;
    },

    setMap(state){
      state.map = true;
    },
    updateCluster(state, payload){
      console.log("payload")
      console.log(payload)
      var clusterIndex = state.cluster.findIndex(x => x.refId === payload.gymId);
      var refId = payload.gymId;
      var newName;
      var newGeo;
      var newCluster = [];

      if (payload.name == null){
        newName = state.cluster[clusterIndex].name
      } else {
        newName = payload.name;
      }

      if (payload.geo == null){
        newGeo = state.cluster[clusterIndex].geo
      } else {
      newGeo =  new base.GeoPoint(payload.geo.latitude, payload.geo.longitude)
      }

      //replacing old Cluster Item
      state.cluster.splice(clusterIndex, 1, {
        geo: newGeo,
        name: newName,
        refId: refId
      })

      //creating new Cluster Array from Old One
      var i = 0;
      for (i = 0; i < state.cluster.length; i++) {
        newCluster.push({
          geo: state.cluster[i].geo,
          name: state.cluster[i].name,
          refId: state.cluster[i].refId
        })
      }

      //Write Cluster to FB
      return db.collection("LocationCluster").doc("locationCluster").update({
        locations: newCluster
      })
      .then(() => {
        console.log("Document successfully updated!");
        //only on geo change
        if (payload.geo !== null){
        router.push({name: 'Gyms', params:{ gymId: payload.gymId }})
      }
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
    },
    
    
    addRankingStats(state, ranks){
      console.log("RANKS");
      console.log(ranks)
     state.ranking = ranks.activeRoutes;
    },
    
    addCluster(state, cluster){
      console.log("adding cluster")
      console.log(cluster)
      state.cluster = cluster;
    },

    toggleUploading(state){
      state.uploadingImage = !state.uploadingImage
    },
    
    
    uploadProgress(state, progress){
      state.uploadProgress = progress
    },

    updateLastVisible(state, last){
      state.last = last;
    },

    moreToLoad(state, size){
      state.moreToLoad = Number(state.moreToLoad) + Number(size);
    },
    
    signedIn(state, payload){
      state.user.signedIn = payload.signedIn;
      state.user.provider = payload.provider;
    },
    updateUser(state, payload){
      state.user.version = payload.version;
      state.user.name = payload.name;
      state.user.fId = payload.fId;
      state.user.firstVisit = payload.firstVisit;
      state.user.homeGym = payload.homeGym;
      state.user.image = payload.image;
      state.user.thumbnail = payload.thumbnail;
      state.user.emoji = payload.emoji;
      state.user.profile = payload.profile;
      state.user.stats = payload.stats;
      state.user.fcm = payload.fcm;
      state.user.routeNotifications = payload.routeNotifications;
      state.user.updateNotifications = payload.updateNotifications;
      state.user.language = payload.language;
    },
    
    updateUserSession(state, credential){
      if(typeof credential !== 'undefined'){
        state.user.session = credential;
      }
    },
    
    
    updateActiveUser(state, payload){
      if (state.homeGym.activeUsers && state.homeGym.activeUsers.hasOwnProperty(state.user.fId) && payload.update == false){
      console.log("User already listed")
      
      } else {
        //add user
        console.log("updating activer user..")
        var aim = "activeUsers." + state.user.fId;
        return db.collection("Gyms/").doc(payload.gym).update({
          [aim]: {
            user: state.user.fId,
            profile: payload.profile || state.user.profile,
            emoji: payload.emoji || state.user.emoji,
            image: payload.image || state.user.thumbnail,
            name: payload.name ||state.user.name
          }
        })  
      }
    },
    
    updateVersion(state, payload){
      console.log("updating version to -> " + payload.version);
      state.version = payload;
    },
    authStateChanged(state, user){
      state.user.auth = user;
    },
    navigateToNewGym(state, gymId){
      router.push({name: 'Gyms', params:{ gymId: gymId, newGym: true }});
    },
    addGym(state, newGym){
      console.log("adding Gym");
      state.gyms.push(newGym);
    },
    removeGym(state, id){
    console.log("removing gym..");
    var gymIndex = state.gyms.findIndex(x => x.id === id);
    state.gyms.splice(gymIndex, 1);
    },
    
    addHomeGym(state, newGym){
      console.log("Mutation -> Adding homeGym");
      state.homeGym = newGym;
    },
    
    addMapGym(state, mapGym){
      state.mapGym = mapGym;
    },
    
    moveBackwards(state){
      state.moveBackwards = true;
    },
    
    resetMoveBackwards(state){
      state.moveBackwards = false;
    },
    
    clearMapGym(state){
      state.mapGym = {};
    },
    
    setMapGym(state, payload){
      state.mapGym = {
        name: payload.name,
        add: payload.add,
        geo: payload.geo
      }
    },
    
    toggleEditMode(state, toggle){
      if (toggle == "on"){
        state.editMode = true
      } else {
        state.editMode = false
      }
    },
    clearRoutes(state){
      state.routes = [];
    },
    clearWalls(state){
      state.walls = [];
    },
    clearGyms(state){
      state.gyms = [];
    },
    clearHomeGym(state){
      state.homeGym = [];
    },
    setScales(state, scales){
      console.log("adding scales");
      if (scales.type == 'custom'){
        state.basics.customLevels.push(scales)
      } else {
        state.basics.scales.push(scales.name);
        state.basics.levels.push(scales);
      }

    },
    addRoute(state, newRoute){
      state.routes.push(newRoute);
      state.routes.sort((a, b) => (a.order > b.order) ? 1 : -1);
    },
    addWall(state, newWall){
      console.log("adding Wall");
      state.walls.push(newWall);
      state.walls.sort((a, b) => (a.order > b.order) ? 1 : -1);
    },
    modifyGym(state, modifiedGym){
      var gymIndex = state.gyms.findIndex(x => x.id === modifiedGym.id);
      var oldObj = state.gyms[gymIndex];
      var newObj = modifiedGym;
      var mergedObj = {...oldObj, ...newObj };
      state.gyms[gymIndex] = mergedObj;
    },
    modifyWall(state, modifiedWall){
      var wallIndex = state.walls.findIndex(x => x.id === modifiedWall.id);
      var oldObj = state.walls[wallIndex];
      var newObj = modifiedWall;
      var mergedObj = {...oldObj, ...newObj };
      state.walls[wallIndex] = mergedObj;
      state.walls.sort((a, b) => (a.order > b.order) ? 1 : -1);
    },
    modifyRoute(state, modifiedRoute){
      var routeIndex = state.routes.findIndex(x => x.id === modifiedRoute.id);
      var oldObj = state.routes[routeIndex];
      var newObj = modifiedRoute;
      var mergedObj = {...oldObj, ...newObj };
      state.routes[routeIndex] = mergedObj;
      state.routes.sort((a, b) => (a.order > b.order) ? 1 : -1);
    },
    deleteRoute(state, id){
      var routeIndex = state.routes.findIndex(x => x.id === id);
       state.routes.splice(routeIndex, 1);
    },
    deleteWall(state, id){
      var wallIndex = state.walls.findIndex(x => x.id === id);
       state.walls.splice(wallIndex, 1);
    },
    checkDeletedWall(state, id){
      if(id == router.currentRoute.value.params.wallId){
        console.log("treffer")
        router.push({ path: '/gyms/' + router.currentRoute.value.params.gymId })
      }
    },
     checkDeletedGym(state, id){
      if(id == router.currentRoute.value.params.gymId){
        console.log("treffer")
        state.homeGym = {};
        router.push({ path: '/' })
      }
    },
    modifyRouteStatus(state, modifiedRoute){
      var routeIndex = state.routes.findIndex(x => x.id === modifiedRoute.routeRefID);
      if (routeIndex !== -1 ) {
        state.routes[routeIndex].status = modifiedRoute.status;
        state.routes[routeIndex].visited = modifiedRoute.id;
      }
    }
  },
  actions: {

//----------------------------------------------//
//-----------------INIT RELATED-----------------//
//----------------------------------------------//

    //Gets current App Version from FS
    //Firestore Realtime Listener
    getAppVersion({commit}){
      var newPayload = {};
      db.collection("Version").doc("hw6VatNR4pmX6NRWvYkB")
      .onSnapshot((doc) => {
        console.log("New Version: ", doc.data());
        newPayload = {
          version: doc.get("version"),
          bugFixes: doc.get("bugFixes"),
          improvements:doc.get("improvements"),
          features:doc.get("features"),
          upcoming:doc.get("upcoming")
        }
       commit('updateVersion', newPayload);
    }, (error) => {
        console.log(error);
    });
    },


    //Loads Hold Colors, Route Types & Levels
    //Firestore Read Data Once
    loadBasics({commit}){


      db.collection("Scales").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
        commit('setScales', doc.data());
        });
      });

      db.collection("Routes").doc("types").get().then((doc) => {
        if (doc.exists) {
          commit('setTypes', doc.data());
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });

      db.collection("Routes").doc("holds").get().then((doc) => {
        if (doc.exists) {
          commit('setHolds', doc.data());
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });

      db.collection("Filter").doc("gymTags").get().then((doc) => {
        if (doc.exists) {
          commit('setGymTags', doc.data());
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
      
      db.collection("Emojis").doc("profile").get().then((doc) => {
        if (doc.exists) {
          commit('setEmojis', doc.data());
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
      
      },
      
      
//----------------------------------------------//
//-----------------NOTIFICATIONS----------------//
//----------------------------------------------//

  
    //Notification Popup + FCM Token Request
    //Cloud Messaging
    getFCMPermission({state, commit}){
      //APPLE FCM 
      if(window.webkit && window.webkit.messageHandlers){
        console.log("WORKING ON IOS");
        window.webkit.messageHandlers.getPermission.postMessage('Getting Permission..');
        window.webkit.messageHandlers.getFCM.postMessage('Getting FCM..');
      } else {
        console.log("NOT IOS");
      }
      
      //ANDROID FCM
      if(window.Android){
        console.log("WORKING ON ANDROID")
        window.Android.getFCM("Getting FCM..");
      } else {
        console.log("NOT ANDROID")
      }
    },
    
    
    //Subscribes user to topic
    //Cloud Messaging
    subscribeTopic({state, commit}, payload){
    if(window.webkit || window.Android){
       var subscribeTopic = functions.httpsCallable('subscribeTopic'); 
       subscribeTopic({ 
            fcm: payload.fcm,
            topic: payload.topic
          })
          .then((result) => {
            console.log(result.data);
          });
    } else {
      console.log("subscription failed, not IOS or Android")
    }
    },
    
    
    //Unsubscribes user from topic
    //Cloud Messaging
    unsubscribeTopic({state, commit}, payload){
    if(window.webkit || window.Android){
     var unsubscribeTopic = functions.httpsCallable('unsubscribeTopic'); 
     unsubscribeTopic({ 
          fcm: payload.fcm,
          topic: payload.topic
        })
        .then((result) => {
          console.log(result.data);
        });
    } else {
      console.log("subscription failed, not IOS or Android")
    }
    },


//----------------------------------------------//
//-----------------USER RELATED-----------------//
//----------------------------------------------//

    //Deletes old user fragments
    //Cloud Functions
    checkUserFragments({state, commit}, payload){
     var checkUserFragments = functions.httpsCallable('checkUserFragments'); 
     checkUserFragments({ 
          fId: payload.fId,
          gymId: payload.gymId
        })
        .then((result) => {
          console.log(result.data);
        });
    },
    
    
    //Creates a new User after signup
    //Firestore Add
    createNewUser({commit}, payload){
      db.collection("Users").add({
        name: "New User",
        version: payload.version,
        uid: payload.uid,
        firstVisit: true,
        homeGym:'',
        profile:'emoji',
        emoji:'👻',
        thumbnail:"placeholder_400x400.jpg"
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        commit('updateUser', {
          version:payload.version,
          fId:docRef.id,
          name:payload.name,
          firstVisit: true,
          homeGym:'',
          profile:'emoji',
          emoji:'👻'
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
    },


    //gets the current User after Login
    //Firestore Realtime Listener
    getUser({commit}, user){
      const unsubscribe = db.collection("Users").where("uid", "==", user.uid)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var currentChangeObject = {};
            console.log("user found");
            function addUser(url) {
                    currentChangeObject = {
                    version: doc.get("version"),
                    fId: doc.id,
                    name: doc.get("name"),
                    firstVisit: doc.get("firstVisit"),
                    homeGym:doc.get("homeGym"),
                    profile: doc.get("profile"),
                    emoji: doc.get("emoji"),
                    stats: doc.get("stats"),
                    image: url,
                    thumbnail: doc.get("thumbnail"),
                    fcm: doc.get("fcm"),
                    routeNotifications: doc.get("routeNotifications"),
                    updateNotifications: doc.get("updateNotifications"),
                    language: doc.get("language")
                    };
                 
                  commit('updateUser', currentChangeObject);
                }
                getImageUrlFromStorage(doc.get("thumbnail"), addUser, doc.get("fallback"));

            commit('authStateChanged', user);
        });
    });
    commit('addDetachUser', unsubscribe);
    },


    //Changes the current Users first Visit to false
    //Firestore Update
    changeUserFirstVisit({commit}, fId){
      return db.collection("Users").doc(fId).update({
        firstVisit: false,
      })
    },


    //Changes the default Gym for the current User
    setUserHomeGym({state, dispatch, commit}, payload){
      var oldGym = state.user.homeGym;
      var userRef = db.collection("Users").doc(payload.fId);
      return userRef.update({
        homeGym: payload.gymId
      })
      .then(() => {
        console.log("Firestore homeGym changed");
        if(state.user.routeNotifications){
        dispatch('subscribeTopic', {fcm: state.user.fcm, topic: payload.gymId});
        dispatch('unsubscribeTopic', {fcm: state.user.fcm, topic: oldGym});
        } else {
          console.log("Settings dont allow subscription");
        }
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
    },


    //Updates the current User Version to the current Server Version
    //Firestore Update
    updateUserVersion({commit}, payload){
      var userRef = db.collection("Users").doc(payload.fId);
      return userRef.update({
        version: payload.version
      })
      .then(() => {
        location.reload();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
    },
    
   
    //Reads ranking data when on ranking page
    //Firestore Realtime Listener
    loadRanking({commit}, aim){
    const unsubscribe = aim.doc("rank")
    .onSnapshot((doc) => {
      var currentChangeObject = {
        activeRoutes: doc.get("activeRoutes")
      };
        commit('addRankingStats', currentChangeObject);
    });
    commit('addDetachRanking', unsubscribe);
    },

//---------------------------------------------//
//-----------------GYM RELATED-----------------//
//---------------------------------------------//

    //Creates a new Gym
    //Firestore Add
    createNewGym({dispatch, state, commit}){
      db.collection("Gyms").add({
        name: "Neue Halle",
        city: "Ort auswählen",
        thumbnail:"placeholder_400x400.jpg",
        scale:"Offizielle Skala",
        customScale:[]
      })
      .then(function(docRef) {
        console.log("Firestore New Gym created");
        dispatch('addNewGymToCluster', docRef.id);
        dispatch('setUserHomeGym', {gymId: docRef.id, fId: state.user.fId});
        dispatch('loadHomeGym', docRef.id);
        commit('navigateToNewGym', docRef.id);
      })
    },


    //Creates a new Gym at Cluster
    //Firestore Update
    addNewGymToCluster({dispatch, commit}, id){
      return db.collection("LocationCluster").doc("locationCluster").update({
        locations: base.FieldValue.arrayUnion({
          geo:null,
          name:"Neue Halle",
          refId: id
        })
      })
      .then(() => {
        console.log("Gym added to Cluster");
        dispatch('loadCluster');
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
    },


    //Loads all Gyms
    //Firestore Once Paginated
    loadGyms({commit}, last){
      var currentChangeObject = {};
      db.collection("Gyms")
        .orderBy('name')
        .startAfter(last)
        .limit(10)
        .get()
        .then((querySnapshot) => {
          console.log("dispatch running, size -> " + querySnapshot.size)
          commit('moreToLoad', querySnapshot.size);
          commit('updateLastVisible', querySnapshot.docs[querySnapshot.docs.length-1]);
          
            querySnapshot.forEach((doc) => {
                function addGym(url) {
                    currentChangeObject = {
                    name: doc.get("name"),
                    short: doc.get("name").match(/\b(\w)/g).join(''),
                    image: url,
                    city: doc.get("city"),
                    id: doc.id,
                    scale: doc.get("scale"),
                    customScale: doc.get("customScale"),
                    tags: doc.get("tags"),
                    activeUsers: doc.get("activeUsers")
                  };
                  commit('addGym', currentChangeObject);
                }
                getImageUrlFromStorage(doc.get("thumbnail"), addGym, doc.get("fallback"));
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
    },
    
    //Loads the selected Gym in Map View
    //Firestore Once
    loadMapGym({state, commit}, gymId){
      var currentObject = {};
      db.collection("Gyms").doc(gymId).get().then((doc) => {
          if (doc.exists) {
              function addMapGym(url) {
                currentObject = {
                name: doc.get("name"),
                image: url,
                city: doc.get("city"),
                id: doc.id,
                scale:doc.get("scale"),
                tags:doc.get("tags")
              };
              commit('addMapGym', currentObject);
            }
            getImageUrlFromStorage(doc.get("thumbnail"), addMapGym, doc.get("fallback"));
          } else {
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    },

    //Loads the last visited Gym
    //Firestore Realtime Listener
    loadHomeGym({state, commit}, gymId){
      console.log("FIRESTORE -> REALTIME -> LOADING HOME GYM")
      if (state.detach.homeGym !== null){
        console.log("ERROR -> Alter Listener exisitert noch")
      }
      var currentObject = {};

        const unsubscribe = db.collection("Gyms").doc(gymId)
          .onSnapshot((doc) => {
          function addHomeGym(url) {
            currentObject = {
            name: doc.get("name"),
            short:doc.get("name").match(/\b(\w)/g).join(''),
            image: url,
            city: doc.get("city"),
            id: doc.id,
            scale:doc.get("scale"),
            tags:doc.get("tags"),
            customScale:doc.get("customScale"),
            activeUsers:doc.get("activeUsers")
          };
          commit('addHomeGym', currentObject);
        }
        if (typeof doc.data() !== 'undefined'){
          //Nur, wenn es das HomeGym noch gibt
        getImageUrlFromStorage(doc.get("thumbnail"), addHomeGym, doc.get("fallback"));
      } else {
        commit('checkDeletedGym', doc.id);
      }

      });
      commit('addDetachHomeGym', unsubscribe);
    },


    //Uploades an image to the current Gym
    //Firestore Add
    changeGymImage({commit, dispatch}, payload){
      var file = payload.e.target.files[0];
      var storageRef = storage.ref(payload.path + payload.ms + "_" + payload.ref + ".jpg");
      var uploadTask = storageRef.put(file);
      uploadTask.on('state_changed',(snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        commit('uploadProgress', progress);
      },
      (error) => {
        console.log(error);
        commit('toggleUploading');
      },
      () => {
        commit('toggleUploading');
        dispatch('removeOldImages', {path: payload.path, newImage: payload.ms + "_" + payload.ref});
        if (payload.updateUser){
        commit('updateActiveUser', {gym: payload.userGym, update:true, image: payload.path + payload.ms + "_" + payload.ref + "_400x400.jpg"});
        }
          return payload.aim.update({
            thumbnail: payload.path + payload.ms + "_" + payload.ref + "_400x400.jpg",
            fallback: payload.path + payload.ms + "_" + payload.ref + ".jpg"
          })
    });
  },
  
  
    //Uploades an image to the current Gym
    //Firestore Add
    deleteImage({commit}, payload){
      //Remove document image
      return payload.aim.update({
            thumbnail: '',
            fallback: ''
          })
          .then(() => {
          console.log("Document successfully updated!");
          //Delete Image from Storage
          var storageRef = storage.ref(payload.path)
          storageRef.listAll()
          .then((res) => {
            res.items.forEach((itemRef) => {
              storageRef.child(itemRef.name).delete()
            })
          })
          })
    },
  
  
  
    //Removes old Images from Storage after upload
    removeOldImages({commit, dispatch}, payload){
      var storageRef = storage.ref(payload.path);
      storageRef.listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            if(itemRef.name !== payload.newImage + "_400x400.jpg" && itemRef.name !== payload.newImage + ".jpg" ){
              storageRef.child(itemRef.name).delete()
              .catch((error) => {
                console.log(error)
              });
            }
          });
        }).catch((error) => {
          console.log(error)
        });
    },


    //Gets alls Geo Data from Gyms
    //Firestore Once
    loadCluster({commit}, updateOptions){
    db.collection("LocationCluster").doc("locationCluster").get()
    .then((doc) => {
      if (doc.exists) {
        commit('addCluster', doc.get('locations'));
        if (updateOptions){
        commit('updateCluster', {gymId: updateOptions.gymId, geo: updateOptions.geo, name: updateOptions.name});
      }
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  },

//----------------------------------------------//
//-----------------WALL RELATED-----------------//
//----------------------------------------------//

    //Loads all Walls for current Gym
    //Firestore Realtime Listener
    loadWallsforCurrentGym({ state, commit }, aim){
      console.log("FIRESTORE -> REALTIME -> LOADING ALL WALLS")
      if (state.detach.walls !== null){
        console.log("ERROR -> Alter Listener exisitert noch")
      }
      const unsubscribe = aim.onSnapshot(function(querySnapshot) {
        var size = querySnapshot.size
        querySnapshot.docChanges().forEach(function(change) {
          var currentChangeObject = {};

          if (change.type === "added") {
              console.log("Wall added");

              function pushWall(url) {
               currentChangeObject = {
                 name: change.doc.get("name"),
                 total: change.doc.get("routesTotal"),
                 image: url,
                 id: change.doc.id,
                 order:change.doc.get("order"),
                 section: change.doc.get("section")
               };
               commit('addWall', currentChangeObject);
              }


              if (change.doc.get("section") == false){
                getImageUrlFromStorage(change.doc.get("thumbnail"), pushWall, change.doc.get("fallback"));
              } else {
                currentChangeObject = {
                  name: change.doc.get("name"),
                  order: change.doc.get("order"),
                  section:change.doc.get("section"),
                  id: change.doc.id
                };
                commit('addWall', currentChangeObject);
              }


            }

          if (change.type === "modified") {
              console.log("Wall modified");
              function updateWall(url) {
                currentChangeObject = {
                  name: change.doc.get("name"),
                  total: change.doc.get("routesTotal"),
                  image: url,
                  id: change.doc.id,
                  order:change.doc.get("order"),
                  section: change.doc.get("section")
              };
              commit('modifyWall', currentChangeObject);
            }
            if (change.doc.get("section") == false){
              getImageUrlFromStorage(change.doc.get("thumbnail"), updateWall, change.doc.get("fallback"));
            } else {
              currentChangeObject = {
                name: change.doc.get("name"),
                order: change.doc.get("order"),
                section:change.doc.get("section"),
                id: change.doc.id
              };
              commit('modifyWall', currentChangeObject);
            }

          }
          if (change.type === "removed") {
            console.log("Wall removed");
            commit('checkDeletedWall', change.doc.id);
            commit('deleteWall', change.doc.id);
        }
        })
      })
      commit('addDetachWalls', unsubscribe);
    },


    //Deletes a wall
    //Firestore Delete
    deleteWall({commit}, payload){
      console.log(payload.gymId);
      payload.aim.doc(payload.doc)
      .delete().then(() => {
        console.log("Document successfully deleted!");
        router.push({ path: '/gyms/' + payload.gymId })
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });
    },


    //Deletes a section
    //Firestore Delete
    deleteSection({commit}, payload){
      payload.aim.doc(payload.section)
      .delete().then(() => {
        console.log("Document successfully deleted!");
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });
    },


    //Adds either a wall or section into entry Point
    //Firestore Add
    addWallOrSection({state, commit}, payload){

      var drunter = payload.drunter;
      var drueber = payload.drueber;

      function calculateNewOrderNo(drunter, druber){
        if (drunter.length == druber.length){
          addNewRouteOrSection(drunter.concat(1));
        }
        if (drunter.length < druber.length){
          let dif = druber.length - drunter.length;
          let zeros = "";
          for (var i = 0; i< dif; i++){
            zeros = zeros.concat(0);
          }
          addNewRouteOrSection(drunter.concat(zeros,1));
        }
        if (drunter.length > druber.length){
          addNewRouteOrSection(drunter.concat(1));
        }
      }

      function addNewRouteOrSection(newOrder){
        let newObj = {};
        if (payload.type == "wall") {
          newObj = {
            section: false,
            order: newOrder,
            name: "Neue Wand",
            thumbnail: "placeholder_400x400.jpg"
          }
        }
        if (payload.type == "route") {
          newObj = {
            section: false,
            order: newOrder,
            type: "Typ auswählen",
            holdColor: "Weiß",
            level: "Level",
            levelScale: [],
            toppedBy:[],
            flashedBy:[],
            createdBy: state.user.fId,
            created: firebaseRoot.firestore.FieldValue.serverTimestamp()
            
          }
        }
        if (payload.type == "section") {
          newObj = {
            section: true,
            name:"Neuer Abschnitt",
            order: newOrder
          }
        }
        payload.aim.add(newObj)
        .then(function(docRef) {
          console.log("Document successfully written!");
          if(payload.aim2 && payload.type == "route"){
            console.log("updating Route total")
            return payload.aim2.update({
              routesTotal: firebaseRoot.firestore.FieldValue.increment(1)
            })
            .then(() => {
              console.log("Document successfully updated!");
              //goto route
              router.push({name: 'Routes', params:{ gymId: payload.gym, wallId: payload.wall, routeId: docRef.id, currentRouteIndex: payload.index }})
            })
          } else if (payload.type == "wall"){
            //goto wall
            //Neue Wand laden
            commit('orientationSetCurrentWallIndex', payload.index);
            router.push({name: 'Walls', params:{ gymId: payload.gym, wallId: docRef.id, newWall: true }})
          }
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        })
      }
      calculateNewOrderNo(drunter,drueber);
    },


    //Moves either a wall or section up & down
    //Firestore Add
    moveWallorSection({commit}, payload){
      return payload.aim.doc(payload.id).update({
        order: payload.order
      })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });

    },


//-----------------------------------------------//
//-----------------ROUTE RELATED-----------------//
//-----------------------------------------------//

    //Updates Route Rating
    //Firestore Update
    updateRating({commit}, payload){
      var aim = payload.aim
      return payload.ref.update({
          [aim]: {
              user: payload.user,
              rating: payload.rating
            }
        })
    },
    
    
    //Updates Route Tendenz
    //Firestore Update
    updateTendenz({commit}, payload){
      var aim = payload.aim
      return payload.ref.update({
          [aim]: {
              user: payload.user,
              tendenz: payload.tendenz
            }
        })
    },


    //Loads all Routes for current Wall
    //Firestore Realtime Listener
   loadRoutesforCurrentWall({ state, commit, dispatch }, aim){
     console.log("FIRESTORE -> REALTIME -> LOADING ALL ROUTES")
     if (state.detach.wall !== null){
       console.log("ERROR -> Alter Listener exisitert noch")
     }
     const unsubscribe = aim.onSnapshot(function(querySnapshot) {
        var size = querySnapshot.size
        querySnapshot.docChanges().forEach(function(change) {
          var currentChangeObject = {};

              if (change.type === "added") {
                  console.log("route added");

                   if (change.doc.get("section") == false){
                     
                     function addRoute(url) {
                    currentChangeObject = {
                       type: change.doc.get("type"),
                       order: change.doc.get("order"),
                       section: change.doc.get("section"),
                       id: change.doc.id,
                       color: change.doc.get("holdColor"),
                       level: change.doc.get("level"),
                       levelScale: change.doc.get("levelScale"),
                       totalFlashed: change.doc.get("totalFlashed"),
                       totalTopped: change.doc.get("totalTopped"),
                       toppedBy:change.doc.get("toppedBy"),
                       flashedBy:change.doc.get("flashedBy"),
                       tags: change.doc.get("tags"),
                       discussion: change.doc.get("discussion"),
                       name: change.doc.get("name"),
                       created: change.doc.get("created"),
                       createdBy: change.doc.get("createdBy"),
                       rating: change.doc.get("rating"),
                       tendenz: change.doc.get("tendenz"),
                       holdNames: change.doc.get("holdNames"),
                       kids:change.doc.get("kids"),
                       routeImage: url
                     };
                     commit('addRoute', currentChangeObject);
                     }
                     getImageUrlFromStorage(change.doc.get("thumbnail"), addRoute, change.doc.get("fallback"));


                   } else {
                     currentChangeObject = {
                       name: change.doc.get("name"),
                       order: change.doc.get("order"),
                       section:change.doc.get("section"),
                       id: change.doc.id
                     };
                     commit('addRoute', currentChangeObject);
                   }

              }
              if (change.type === "modified") {
                  console.log("route modified");
                  
                  function addRoute(url) {
                    currentChangeObject = {
                  type: change.doc.get("type"),
                  order: change.doc.get("order"),
                  section: change.doc.get("section"),
                  id: change.doc.id,
                  color: change.doc.get("holdColor"),
                  level: change.doc.get("level"),
                  levelScale: change.doc.get("levelScale"),
                  toppedBy:change.doc.get("toppedBy"),
                  flashedBy:change.doc.get("flashedBy"),
                  name:change.doc.get("name"),
                  tags: change.doc.get("tags"),
                  discussion: change.doc.get("discussion"),
                  name: change.doc.get("name"),
                  created: change.doc.get("created"),
                  createdBy: change.doc.get("createdBy"),
                  rating: change.doc.get("rating"),
                  tendenz: change.doc.get("tendenz"),
                  holdNames: change.doc.get("holdNames"),
                  kids:change.doc.get("kids"),
                  routeImage: url
                };
                
                commit('modifyRoute', currentChangeObject);
                }
                
                getImageUrlFromStorage(change.doc.get("thumbnail"), addRoute, change.doc.get("fallback"));
                  

              }

              if (change.type === "removed") {
                console.log("route removed");
                commit('deleteRoute', change.doc.id);
            }


          });

        });
        commit('addDetachWall', unsubscribe);
    },
    loadStatusforCurrentVisitedWall({ commit }, aim){
      aim.onSnapshot(function(querySnapshot) {
        var currentChangeObject = {};
        querySnapshot.forEach((doc) => {
                  currentChangeObject = {
                    status: doc.get("status"),
                    routeRefID: doc.get("routeRefID"),
                    id: doc.id
                  };
                  commit('modifyRouteStatus', currentChangeObject);
              })
            })
    }
  },
  modules: {
  }
}
