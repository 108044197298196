<template>
  <div>


    <!-- INTRO -->
    <div class="mt-20 mb-5 mx-9">
      <div class="font-ageo font-semibold text-purple-600 text-xs tracking-widest mb-2">HOME</div>
      <div class="w-3/4 font-ageo font-semibold text-2xl text-gray-800 leading-7">Hello {{user.name}}, <br> let's climb.</div>
    </div>
    <!-- INTRO -->
  
    
    <!-- HOMEGYM -->
    <div v-if="homeGym && homeGym.name" class="mr-5">
      <div class="relative w-full pl-9 mt-2 font-ageo font-semibold text-purple-600 text-xs tracking-widest">{{lang.last}}</div>
      <router-link :to="{name: 'Gyms', params:{ gymId: homeGym.id, newGym: false }}">
        <div class="mt-4 ml-9 flex">
          <div class="w-16 h-16 mr-5 rounded-2xl flex flex-row flex-shrink-0 justify-center items-center shadow-c bg-center bg-cover bg-no-repeat" v-bind:style="{ backgroundImage: 'url(' + homeGym.image  + ')' }">
            <div v-if="homeGym.image.includes('placeholder')" class="w-full h-full flex flex-row justify-center items-center font-ageo font-semibold text-xl text-gray-800  defaultBg">
              <div>{{homeGym.short}}</div>
            </div>
          </div>
          <div class="flex flex-col justify-center">
            <div class="font-ageo font-semibold text-lg text-gray-800 leading-5"> {{checkGymName(homeGym.name)}} </div>
            <div class="font-light text-gray-400 leading-5">{{checkLocation(homeGym.city)}} </div>
          </div>
        </div>
      </router-link>
    </div>
    <div v-else>
      <div class="relative w-full pl-9 mt-2 font-ageo font-semibold text-purple-600 text-xs tracking-widest">{{lang.welcome}}</div>
      <div class="px-9 mt-2 leading-6 mb-5 w-full font-ageo font-normal text-lg text-gray-400 text-start">
        <div class="flex flex-row items-center">
          <p>{{lang.info}}</p>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="svgActive w-4 h-4 mx-1">
            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
          </svg>
        </div>
        <p>{{lang.info2}}</p>
      </div>
    </div>
    <!-- HOMEGYM -->


  </div>
</template>


<script>

export default {
  name: 'Home',
  data(){
    return{
      firstVisitLoaded: false,
      homeGymLoaded: false
    }
  },
  computed: {
    lang(){
      return this.$store.state.lang.home[this.$store.state.lang.currentLang];
    },
    homeGym(){
      return this.$store.state.data.homeGym;
    },
    detach(){
      return this.$store.state.data.detach;
    },
    user(){
      return this.$store.state.data.user;
    },
    firstVisit(){
      return this.$store.state.data.user.firstVisit;
    },
    userHomeGym(){
      return this.$store.state.data.user.homeGym;
    }
  },
  watch:{
    firstVisit(){
      this.checkFirstVisit();
    },
    userHomeGym(){
      this.checkHomeGym();
    }
  },
  mounted(){
    document.body.style.backgroundColor = "white";
    this.$store.commit('resetMoveBackwards');
    this.checkFirstVisit();
    this.checkHomeGym();
  },
  methods:{
    
    checkGymName(name){
      if(name && name == 'Neue Halle'){
        return this.lang.newGym
      } else {
        return name
      }
    },
    
    checkLocation(add){
      if(add && add == 'Ort auswählen'){
        return this.lang.location
      } else {
        return add
      }
    },
  
    checkFirstVisit(){
      if (this.user.firstVisit && this.user.firstVisit == true && !this.firstVisitLoaded){
          this.$router.push({ name:'Intro' });
          this.firstVisitLoaded = true;
      }
    },
    
    checkHomeGym(){
      if(this.user.homeGym && this.detach.homeGym == null && !this.homeGymLoaded){
        this.$store.dispatch('loadHomeGym', this.user.homeGym);
        this.homeGymLoaded = true;
      }
    }
    
  }
}

</script>

