import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Profile from '../views/Profile.vue'
import Intro from '../views/Intro.vue'
import Update from '../views/Update.vue'
import Ranking from '../views/Ranking.vue'
import Home from '../views/Home.vue'
import Explore from '../views/Explore.vue'
import Community from '../views/Community.vue'
import Map from '../views/Map.vue'
import Gyms from '../views/Gyms.vue'
import Walls from '../views/Walls.vue'
import Routes from '../views/Routes.vue'
import Settings from '../views/Settings.vue'
import Auth from '../views/Auth.vue'


const routes = [
  {
    path: '/hello',
    name: 'Intro',
    component: Intro,
    props: true,
    meta:{requiresAuth : false}
  },{
    path: '/User',
    name: 'Profile',
    component: Profile,
    props: true,
    meta:{requiresAuth : true, utility: true}
  },{
    path: '/Settings',
    name: 'Settings',
    component: Settings,
    props: true,
    meta:{requiresAuth : true}
  },{
    path: '/ranking',
    name: 'Ranking',
    component: Ranking,
    props: true,
    meta:{requiresAuth : true, utility: true}
  },{
    path: '/Update',
    name: 'Update',
    component: Update,
    props: true,
    meta:{requiresAuth : false}
  },{
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    meta:{requiresAuth : true}
  },{
    path: '/Community',
    name: 'Community',
    component: Community,
    props: true,
    meta:{requiresAuth : true, utility: true}
  },{
    path: '/Explore',
    name: 'Explore',
    component: Explore,
    props: true,
    meta:{requiresAuth : true, utility: true}
  },{
    path: '/map',
    name: 'Map',
    component: Map,
    props: true,
    meta:{requiresAuth : true}
  },{
    path: '/gyms/:gymId',
    name: 'Gyms',
    component: Gyms,
    props: true,
    meta:{requiresAuth : true}
  },{
    path: '/gyms/:gymId/walls/:wallId',
    name: 'Walls',
    component: Walls,
    props: true,
    meta:{requiresAuth : true}
  },
  {
    path: '/gyms/:gymId/walls/:wallId/routes/:routeId',
    name: 'Routes',
    component: Routes,
    props: true,
    meta:{requiresAuth : true}
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    props: true,
    meta:{requiresAuth : false},

  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  
  if (to.meta.requiresAuth){
    console.log("Router -> Auth required");
    if(store.state.data.user.auth == null){
      console.log("Router -> Not logged in")
      next({ replace: false, name: 'Auth' })
    } else {
      console.log("Router ->Logged in")
      if(store.state.data.user.version !== store.state.data.version.version && store.state.data.user.version !== '' && store.state.data.version.version !== ''){
        next({replace: false, name: 'Update'});
      } else {
        next()
      }
    }
  } else {
    console.log("Router -> NO Auth required");
    next()
  }
})

export default router
