export const walls = {
  state: {
    de:{
      newGym: "Neue Halle",
      newWall: "Neue Wand",
      newSection: "Neuer Abschnitt",
      wallName:"Wandname",
      nameWall:"Wand benennen",
      error1:"Dieser Schritt kann danach nicht mehr rückgängig gemacht werden.",
      error2:"Die Wand kann nicht gelöscht werden, solange noch Routen vorhanden sind.",
      error3: "Bitte gib einen gültigen Namen ein.",
      routes:"Routen",
      filter:"Filter",
      todo:"Noch offen",
      holdColor:"Grifffarbe",
      scale: ' Skala',
      resetFilter:"Filter Zurücksetzen",
      level:"Schwierigkeit",
      addRoute:"Route hinzufügen",
      addSection:"Abschnitt hinzufügen",
      changePosition:"Reihenfolge ändern",
      addRoute2:"Klicke auf ein Plus-Icon um eine neue Route einzufügen.",
      addRoute3:"Route einfügen",
      addSection2:"Klicke auf ein Plus-Icon um einen neuen Abschnitt einzufügen.",
      addSection3:"Abschnitt einfügen",
      changePosition2:"Wähle die Wand oder den Abschnitt über das kleine Icon aus, den du verschieben möchtest.",
      changePosition3:"Reihenfolge ändern",
      new:"NEU",
      selectType:"Typ auswählen",
      flash: "Flash",
      top: "Top",
      todo: "Offen",
      section:"Abschnitt",
      nameSection:"Abschnitt benennen",
      sec: "Sicher?",
      sec2:"Dieser Schritt kann nicht rückgängig gemacht werden.",
      empty:"Du bist der erste hier! Klicke oben rechts auf das Bearbeiten-Icon um eine neue Route hinzuzufügen.",
      noResults:"Nichts gefunden..",
      deleteWall:"Wand löschen",
      sec3:"Ganz sicher?"
    },
    en:{
      newGym: "New gym",
      newWall: "New wall",
      newSection: "New section",
      wallName:"Wall name",
      nameWall:"Set wall name",
      error1: "This step cannot be reversed afterwards.",
      error2:"This wall cannot be deleted if there are still routes existing.",
      error3: "Please enter a valid name.",
      routes:"Routes",
      filter:"Filter",
      todo:"Undone",
      holdColor:"Hold color",
      scale: ' scale',
      resetFilter:"Reset filter",
      level:"Difficulty",
      addRoute:"Add route",
      addSection:"Add section",
      changePosition:"Change order",
      addRoute2:"Klick on a plus icon to create a new route",
      addRoute3:"Add route",
      addSection2:"Klick on a plus icon to create a new section",
      addSection3:"Add section",
      changePosition2:"Klick on the icon of the object you want to move",
      changePosition3:"Change order",
      new:"NEW",
      selectType:"Select type",
      flash: "Flash",
      top: "Top",
      todo: "Undone",
      section:"Section",
      nameSection:"Set section name",
      sec: "Sure?",
      sec2:"This step cannot be reversed afterwards.",
      empty:"Looks like you're the first one here! Click on the pencil icon at the top right corner to create a new route.",
      noResults:"Nothing found..",
      deleteWall:"Delete wall",
      sec3:"Are you sure?"
    }
  }
};
