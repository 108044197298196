<template>
  <div>
    
    
    <!-- BACK BUTTON -->
    <div class="fixed top-0 left-0 ml-4 mt-4 w-9 h-9 z-20 flex flex-row justify-center items-center" @click="this.$router.push({ path: '/gyms/' + this.$route.params.gymId })">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-6 h-6 stroke-current stroke-2 text-purple-600">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </div> 
    <!-- BACK BUTTON -->
    
    <div id="inside">
      <div v-if="wall" class="mt-20 flex flex-col justify-center items-center">
        
        
        <!-- WALL IMAGE -->
        <div class="relative w-36 h-36 mb-6" :class="{ 'animate-pulse': uploading }">
          <div v-if="wall.image" class="absolute w-full h-full bg-local bg-center bg-no-repeat bg-cover rounded-full shadow-md"
              v-bind:style="{ backgroundImage: 'url(' +  wall.image  + ')' }">
              <div v-if="wall.image && wall.image.includes('placeholder')" class="w-full h-full flex flex-row justify-center items-center font-ageo font-semibold text-xl text-gray-800 defaultBg rounded-full shadow-md"></div>
              <input type="file" accept="image/*" class="uploadImage" @change="changeImage($event)" :class="{ 'block': editMode }">
          </div>
          <div class="transition duration-500 ease-in-out w-full h-full rounded-full" 
              :class="{ ' transition duration-500 ease-in-out ring-3 ring-offset-12 ring-yellow-300': editMode }"></div>
        </div>
        <!-- WALL IMAGE -->
    
  
        <div class="w-screen flex flex-col justify-center items-center px-6">
            <div class="h-16 relative font-ageo font-normal text-4xl text-gray-800 text-center w-full mt-5" >
              
              
        <!-- WALL NAME EDIT -->
        <transition @enter="wallNameEnter" @leave="wallNameLeave" :css="false" mode="out-in">
        <div v-if="wall.name && !editMode" class="font-ageo font-normal text-2xl text-gray-800">
          <div class="font-ageo font-normal text-4xl text-gray-800">{{checkWallName(wall.name)}}</div>
          <div class="font-ageo font-normal text-lg text-center text-gray-400">{{ checkGymName(gym.name) }}</div>
        </div>
        <div v-else-if="editMode" class="w-full h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 bg-white ring-2 ring-yellow-300 rounded mb-5 overflow-hidden">
          <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.wallName}}</div>
          <div class="flex flex-row justify-between items-center h-full">
            <input v-model="newWallName" :placeholder="wall.name || lang.nameWall" type="text" 
              class="h-full px-4 w-full appearance-none outline-none text-base text-purple-600 placeholder-purple-600 text-center"
              :class="{'w-4/6 text-left' : newWallName !== ''}"
              @keyup.enter="changeWallName(newWallName)">
            <div v-if="newWallName !== ''" class="flex flex-row items-center">
              <div class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="changeWallName(newWallName)">
                <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="newWallName = ''; errorMessage = ''">
                <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        
        </transition>
        <div v-if="editMode" class="text-red-400 font-ageo font-normal text-base mt-2"> {{ errorMessage }} </div>
        <!-- WALL NAME EDIT -->
        
        </div>
        </div>
    
    
      </div>
      <div class="mt-12">
          
          
        <!-- ROUTE LIST HEADING -->
        <div  class="w-full flex flex-row justify-between px-4 items-center">
          <div class="ml-3 mr-6">
            <svg class="w-5 h-5 text-purple-600 transform -rotate-90" viewBox="0 0 120 120">
              <circle class="opacity-25" cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12"/>
              <circle cx="60" cy="60" r="54" fill="none" stroke="currentColor" stroke-width="12" stroke-dasharray="339.292" :stroke-dashoffset='circleIndicator' stroke-linecap="round"/>
            </svg>
          </div>
          <div class="flex-grow font-ageo font-normal text-lg text-gray-800">
            <p v-if="!toggleFilter">{{lang.routes}}</p>
            <p v-else>{{lang.filter}}</p>
            </div>
          <div @click="toggleFilter = !toggleFilter">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-purple-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
            </svg>
          </div>
          <div class="font-ageo font-normal text-lg text-gray-400 mr-3"> {{routesDone}} /  {{ routesTotal }}</div>
        </div>
        <!-- ROUTE LIST HEADING -->
        
        
        <!-- ROUTE FILTER -->
        <div class="pl-7 pr-6 mt-2">
          <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="toggleFilter" class="flex flex-row justify-between items-center font-ageo text-purple-600 text-base">
            <div class="tag" :class="{ 'bg-green-200' : filterOffen }" @click="filterOffen = !filterOffen">{{lang.todo}}</div>
            <div class="tag relative" :class="{ 'bg-green-200' : filterHolds !== 'Grifffarbe' }">
              <div>{{lang.holdColor}}</div>
              <select v-model="filterHolds" class="w-full absolute inset-0 appearance-none opacity-0">
                <option value="Grifffarbe">{{lang.resetFilter}}</option>
                <option v-for="color in holdColors" :value="color">
                {{ translateBasics('Colors', color) }}
                </option>
              </select>
            </div>
            <div class="tag relative" :class="{ 'bg-green-200' : filterLevel !== 'Schwierigkeit' }">
              <div>{{lang.level}}</div>
              <select v-model="filterLevel" class="w-full absolute inset-0 appearance-none opacity-0">
                <option value="Schwierigkeit">{{lang.resetFilter}}</option>
                <optgroup v-if="customlevels.length !== 0" :label="gym.name + lang.scale">
                  <option v-for="customlevel in customlevels" :value="{scale: 'custom', level: customlevel}">
                  {{ translateBasics('Colors', customlevel) }}
                  </option>
                </optgroup>
                <optgroup v-if="levels.length !== 0" :label="gym.scale">
                  <option v-for="level in levels" :value="{scale: gym.scale, level: level}">
                  {{ level }}
                  </option>
                </optgroup>
              </select>
              </div>
          </div>
          </transition>
        </div>
        <!-- ROUTE FILTER -->
    
    
        <div  class="flex flex-col mt-4 mr-5 ml-9 border-l border-dashed border-gray-200">
    
    
          <!-- ROUTE EDITOR -->
          <transition @enter="editButtonsEnter" @leave="editButtonsLeave" :css="false">
            <div v-if="editMode && entryType == ''">
            <section class="flex flex-row mt-4 ml-9" @click="entryType = 'route'">
              <div class="w-16 h-16 mr-5 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center shadow-c">
                <svg class="w-12 h-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <div class="font-ageo font-semibold text-lg text-gray-800">{{lang.addRoute}}</div>
              </div>
            </section>
            <section class="flex flex-row mt-4 ml-9" @click="entryType = 'section'">
              <div class="w-16 h-16 mr-5 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center shadow-c" >
                <svg class="w-12 h-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <div class="font-ageo font-semibold text-lg text-gray-800">{{lang.addSection}}</div>
              </div>
            </section>
            <section class="flex flex-row mt-4 ml-9" @click="entryType = 'move'">
              <div class="w-16 h-16 mr-5 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center shadow-c" >
                <svg class="w-10 h-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
                </svg>
              </div>
              <div class="flex flex-col justify-center">
                <div class="font-ageo font-semibold text-lg text-gray-800">{{lang.changePosition}}</div>
              </div>
            </section>
          </div>
        </transition>
        <!-- ROUTE EDITOR -->
    
    
        <!-- ROUTE EDITOR STEP 2 -->
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="editMode && entryType == 'route'"
          class="relative mt-2 ml-9 mr-5 p-2 px-4 ring-1 ring-gray-800 rounded font-ageo font-normal text-base text-gray-800">
          {{lang.addRoute2}}
            <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.addRoute3}}</div>
            <div class="absolute -right-5 -top-3" @click="entryType = ''">
              <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3">
                <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
          </div>
        </transition>
    
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="editMode && entryType == 'section'"
          class="relative mt-2 ml-9 mr-5 p-2 px-4 ring-1 ring-gray-800 rounded font-ageo font-normal text-base text-gray-800">
          {{lang.addSection2}}
            <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.addSection3}}</div>
            <div class="absolute -right-5 -top-3" @click="entryType = ''">
              <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3">
                <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
          </div>
        </transition>
    
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="editMode && entryType == 'move'"
          class="relative mt-2 ml-9 mr-5 p-2 px-4 ring-1 ring-gray-800 rounded font-ageo font-normal text-base text-gray-800">
          {{lang.changePosition2}}
            <div class="absolute left-2 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.changePosition3}}</div>
            <div class="absolute -right-5 -top-3" @click="entryType = '', currentIndex = null">
              <div class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3">
                <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
          </div>
        </transition>
        <!-- ROUTE EDITOR STEP 2 -->
    
    
        <!-- ROUTE EDITOR START BUTTON -->
        <transition name="fade" mode="out-in">
          <div v-if="editMode && entryType !== '' && entryType !== 'move'" class="relative">
            <div class="absolute -bottom-5 -left-3 w-6 h-6 rounded-full bg-white border-2 border-yellow-300 flex flex-row justify-center items-center shadow-c z-20">
              <div class="w-full h-full font-ageo font-normal flex flex-row justify-center items-center" @click="addObj('start')">
                <svg class="w-5 h-5 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </div>
            </div>
          </div>
        </transition>
        <!-- ROUTE EDITOR START BUTTON -->
    
    
        <!-- ROUTE LIST -->
        <div v-for="(r, index) in filteredRoutes" class="relative">
    
      
          <!-- ROUTE ITEM -->
          <div v-if="!r.section"  class="mt-4 ml-9 flex relative" :class="{ 'pointer-events-none opacity-75': editMode && entryType !== ''}">
            <div id="info" :data-id="r.id" class="absolute w-16 h-16 bg-yellow-300 rounded-2xl flex flex-row justify-center items-center z-0 opacity-0">
              <transition name="fade" mode="out-in">
                <div v-if="topFlash !== ''" class="font-ageo font-bold text-lg text-white pr-5 mr-5">{{topFlash}}</div>
              </transition>
            </div>
            <div id="route" class="flex"
            @touchstart.passive="routeDragStart" @touchend.passive="routeDragEnd" @touchmove="routeDrag"
            @mousedown.passive="routeDragStart" @mouseup.passive="routeDragEnd" @mousemove="routeDrag">
             
            <div class="relative w-16 h-16 mr-5 rounded-2xl flex flex-row flex-shrink-0 justify-center items-center shadow-c bg-center bg-cover bg-no-repeat" 
            v-bind:style="{ backgroundImage: 'url(' + require('../assets/holds/' + umlaut(r.color) + '.jpg') + ')' }" 
            :class="{'ring-2 ring-yellow-200' : r.created && age(r.created) == 'new'}"
            @click="goToRoute(r.id, index)">
              <div v-if="r.level != 'Level' && r.levelScale !== 'custom'" class="absolute -bottom-2 -right-2 w-6 h-6 rounded-full shadow-c ring-2 ring-purple-200 bg-white flex flex-row justify-center items-center">
                <div class=" font-ageo font-semibold text-xs text-center text-purple-600">{{ r.level }}</div>
              </div>
              <div v-else-if="r.level != 'Level' && r.levelScale == 'custom' && customColors.findIndex(x => x.color === r.level) !== -1" class="absolute -bottom-1 -right-1 w-4 h-4 rounded-full shadow-c flex flex-row justify-center items-center"
                :style='{backgroundColor : customColors[customColors.findIndex(x => x.color === r.level)].hex }'>
              </div>
              <div v-if="r.level != 'Level' && r.levelScale == 'custom' && customColors.findIndex(x => x.color === r.level) == -1" class="absolute -bottom-2 -right-2 w-6 h-6 rounded-full shadow-c ring-2 ring-purple-200 bg-white flex flex-row justify-center items-center">
                <div class=" font-ageo font-semibold text-xs text-center text-purple-600">{{ r.level }}</div>
              </div>
            </div>
            <div class="flex flex-col justify-center">
              <div v-if="r.created && age(r.created) == 'new'" class="font-ageo font-semibold text-yellow-300 text-xs tracking-widest leading-snug">{{lang.new}}</div>
              <div class="flex flex-row flex-wrap items-center">
                <div v-if="r.tags && !r.name" v-for="(tag,tagIndex) in r.tags" class="font-ageo font-semibold text-lg text-gray-800 leading-5" @click="goToRoute(r.id, index)">
                  <span v-if="tagIndex < 2 && showTags !== index" >{{ translateBasics('RouteTags', tag) }}</span>
                  <span v-if="tagIndex < 1 && r.tags.length > 1 && showTags !== index">,&nbsp;</span>
                  <span v-if="tagIndex == 2 && r.tags.length >= 2 && showTags !== index">..</span>
                  <span v-if="showTags == index" >{{ translateBasics('RouteTags', tag) }}</span>
                  <span v-if="tagIndex < r.tags.length-1 && showTags == index">,&nbsp;</span>
                </div>
                <div v-else-if="r.name" class="font-ageo font-semibolditalic italic text-lg text-gray-600 leading-5">{{r.name}}</div>
                <div v-if="r.tags && r.tags.length > 2 && showTags !== index && !r.name" class="font-ageo text-lg font-normal ml-3 leading-5 text-pink-600" @click="showTags = index">+{{r.tags.length - 2}}</div>
                <div v-if="!r.tags" class="font-ageo font-semibold text-lg text-gray-800 leading-5" @click="goToRoute(r.id, index)">{{lang.selectType}}</div>
              </div>
              <div v-if="r.flashedBy.includes(user.fId)" class="font-ageo font-normal text-lg text-purple-600" @click="goToRoute(r.id, index)">{{lang.flash}}</div>
              <div v-else-if="r.toppedBy.includes(user.fId)" class="font-ageo font-normal text-lg text-purple-600" @click="goToRoute(r.id, index)">{{lang.top}}</div>
              <div v-else class="font-ageo font-normal text-lg text-gray-400" @click="goToRoute(r.id, index)">{{lang.todo}}</div>
            </div>
            </div>
            
          </div>
          <!-- ROUTE ITEM -->
    
    
          <!-- SECTION ITEM -->
          <div v-if="r.section">
            <div class="flex flex-row items-center relative mt-7 mb-2" :class="{ 'pointer-events-none opacity-75': editMode && entryType !== '' && entryType !=='section'}">
              <div class="absolute -left-1 w-2 h-2 rounded-full bg-purple-500 shadow"></div>
                <transition name="fade" mode="out-in">
                  <div v-if="currentIndex == index && currentIndex !== null && entryType !== 'move' && editMode"
                        class="w-full ml-9 h-9 inset-0 font-ageo font-semibold text-lg text-purple-500 ring-2 ring-yellow-300 rounded overflow-hidden">
                    <div class="absolute left-11 -top-3 bg-white px-2 text-sm font-ageo font-normal text-gray-300">{{lang.section}}</div>
                    <div class="flex flex-row justify-between items-center h-full">
                      <input v-model="r.newName" :placeholder="r.name || lang.nameSection" type="text" 
                        class="h-full pl-4 w-4/6 appearance-none outline-none placeholder-purple-600 text-base"
                        @keyup.enter="hideSectionEdit(r.newName, r.id)">
                      <div class="flex flex-row items-center">
                        <div v-if="!toggleDeleteSection" class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="hideSectionEdit(r.newName, r.id)">
                          <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <div v-if="!toggleDeleteSection" class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="currentIndex = null; r.newName = ''">
                          <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                        <div v-if="!toggleDeleteSection" class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="toggleDeleteSection = !toggleDeleteSection; r.newName = lang.sec">
                          <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </div>
                        <div v-if="toggleDeleteSection" class="rounded-full bg-purple-200 h-5 w-5 p-1 text-center mr-3" @click="toggleDeleteSection = !toggleDeleteSection; currentIndex = null; r.newName = ''">
                          <svg class="text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </div>
                        <div v-if="toggleDeleteSection" class="rounded-full bg-red-200 h-5 w-5 p-1 text-center mr-3" @click="deleteSection(index); toggleDeleteSection = !toggleDeleteSection; currentIndex = null; r.newName = ''">
                          <svg class="text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="section" :class="{ editModeTextColor: editMode && entryType =='' || editMode && entryType =='section' }">
                      <div>{{r.name}}</div>
                      <div class="absolute w-full h-full inset-0 z-10" @click="editSection(index)"></div>
                    </div>
                  </div>
                </transition>
              </div>
              <div v-if="toggleDeleteSection && currentIndex == index && currentIndex !== null && entryType !== 'move' && editMode" class="font-ageo text-red-400 pl-8 text-center font-normal mt-3">{{lang.sec2}}</div>
            </div>
            <!-- SECTION ITEM -->
            
            
            <!-- ROUTE EDITOR BUTTONS -->
            <transition name="fade" mode="out-in">
              <div v-if="editMode && entryType !== '' && entryType !== 'move'" class="relative">
                <div class="absolute -bottom-5 -left-3 w-6 h-6 rounded-full bg-white border-2 border-yellow-300 flex flex-row justify-center items-center shadow-c z-20">
                  <div class="w-full h-full font-ageo font-normal flex flex-row justify-center items-center" @click="addObj(index)">
                    <svg class="w-5 h-5 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                  </div>
                </div>
              </div>
        
              <div v-else-if="editMode && entryType == 'move' && currentIndex == null" class="relative">
                <div class="absolute bottom-4 -left-3 w-6 h-6 rounded-full bg-white border-2 border-yellow-300 flex flex-row justify-center items-center shadow-c z-20">
                  <div class="w-full h-full font-ageo font-normal flex flex-row justify-center items-center" @click="currentIndex = index">
                    <svg class="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </div>
                </div>
              </div>
        
              <div v-else-if="editMode && entryType == 'move' && currentIndex == index" class="relative">
                <div class="absolute bottom-0 -left-3 w-6 h-16  flex flex-col justify-between items-center  z-20">
                  <div class="w-6 h-6 rounded-full bg-white border-2 border-yellow-300 shadow-c font-ageo font-normal flex flex-row justify-center items-center"
                        :class="{ 'opacity-25 pointer-events-none' : currentIndex == 0}" @click="moveObj(index, -1)">
                    <svg class="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                  </div>
                  <div class="w-6 h-6 rounded-full bg-white border-2 border-yellow-300 shadow-c font-ageo font-normal flex flex-row justify-center items-center"
                        :class="{ 'opacity-25 pointer-events-none' : currentIndex == routesLength-1}" @click="moveObj(index, +1)">
                    <svg class="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>
            </transition>
            <!-- ROUTE EDITOR BUTTONS -->
    
    
        </div>
        <!-- ROUTE LIST -->
    
    
      </div>
      
      
      <!-- ROUTE LIST EMPTY TEXT -->
      <div class="mt-8 px-12 w-full font-ageo font-normal text-lg text-gray-400 text-center">
        <span v-if="filteredRoutes.length == 0 && !toggleFilter">{{lang.empty}}</span>
        <span v-else-if="filteredRoutes.length == 0 && toggleFilter">{{lang.noResults}}</span>
      </div>
      <!-- ROUTE LIST EMPTY TEXT -->
    
    
      <!-- ROUTE DELETE -->
      <transition name="fade" mode="out-in">
        <div v-if="!toggleDelete && editMode" class="w-screen px-9">
          <div class="w-full h-16 flex flex-row items-center justify-center bg-yellow-300 rounded-xl mt-5" @click="toggleDelete = true">
            <div class="font-ageo font-semibold text-lg">{{lang.deleteWall}}</div>
          </div>
        </div>
        <div v-else-if="toggleDelete && editMode" class="w-screen px-9">
          <div class="w-full h-16 flex flex-row items-center justify-around bg-white ring-2 ring-yellow-300 rounded-xl mt-5">
            <div class="font-ageo font-semibold text-lg text-purple-600">{{lang.sec3}}</div>
            <div class="flex flex-row">
              <div class="w-7 h-7 rounded-full bg-purple-200 flex flex-row justify-center items-center" @click="toggleDelete = false">
                <svg class="text-purple-600 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              <div class="w-7 h-7 ml-6 rounded-full bg-red-200 flex flex-row justify-center items-center" @click="deleteWall()">
                <svg class="text-red-500 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </div>
            </div>
          </div>
          <div class="font-ageo text-red-400 px-4 text-center font-normal mt-3">{{ errorMessageWall || lang.error1 }}</div>
        </div>
      </transition>
      <!-- ROUTE DELETE -->
    
    
    </div>
    
    
    <!-- BOTTOM SPACE -->
    <div class="h-24 mt-24"></div>
    <!-- BOTTOM SPACE -->
  
    </div>
  </div>
</template>

<script>
import { db } from '../firestore.js';
import { functions } from '../firestore.js';
import anime from 'animejs/lib/anime.es.js';
import { firebaseRoot } from '../firestore.js';

const options = {
  delay: (el, i) =>  i * 100,
  duration: 400,
  easing: "easeOutCubic"
}

export default {
  name: 'Wall',
  props:['wallId', 'newWall'],
  data(){
    return{
      toggleFilter: false,
      activeDragDirection: '',
      draggedRoute: '',
      draggedRouteId:'',
      fadeIn:false,
      topFlash: '',
      dragItem: '',
      dragItem2:'',
      currentX: '',
      currentY: '',
      initialX: '',
      initialY: '',
      filterOffen: false,
      filterLevel: 'Schwierigkeit',
      filterHolds:'Grifffarbe',
      entryType: '',
      currentIndex: null,
      newWallName:'',
      editWallName: false,
      newWallName: '',
      errorMessage:'',
      toggleDelete: false,
      toggleDeleteSection: false,
      showTags: '-1',
      errorMessageWall:''
      }
  },
  watch: {
    moveBackwards: function(){
      if(this.moveBackwards){
        this.$router.push({ path: '/gyms/' + this.$route.params.gymId });
        }
    }
  },
  computed: {
    lang(){
      return this.$store.state.lang.walls[this.$store.state.lang.currentLang];
    },
    langBasicColors(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].colors;
    },
    langBasicRouteTags(){
      return this.$store.state.lang.basics[this.$store.state.lang.currentLang].routeTags;
    },
    currentRating(){
      if (this.routes[this.draggedRoute].rating && this.routes[this.draggedRoute].rating[this.user.fId]){
      return this.routes[this.draggedRoute].rating[this.user.fId].rating;
      } else {
        return 0;
      }
    },
    currentTendenz(){
      if (this.routes[this.draggedRoute].tendenz && this.routes[this.draggedRoute].tendenz[this.user.fId]){
      return this.routes[this.draggedRoute].tendenz[this.user.fId].tendenz;
      } else {
        return '';
      }
    },
    moveBackwards(){
      return this.$store.state.data.moveBackwards;
    },
    
    customColors(){
      var colorIndex = this.$store.state.data.basics.customLevels.findIndex(x => x.name === "Farben")
      return this.$store.state.data.basics.customLevels[colorIndex].level
    },
    routesDone(){
      var i;
      var x = 0;
      for (i = 0; i < this.filteredRoutes.length; i++) {
        if (this.filteredRoutes[i].flashedBy && this.filteredRoutes[i].flashedBy.includes(this.user.fId) || this.filteredRoutes[i].toppedBy && this.filteredRoutes[i].toppedBy.includes(this.user.fId)){
          x++
        }
      }
      return x
    },
    routesTotal(){
      var i;
      var x = 0;
      for (i = 0; i < this.filteredRoutes.length; i++) {
        if (this.filteredRoutes[i].section == false){
          x++
        }
      }
      return x
    },
    circleIndicator(){
      return 339.292 * (1 - ((100 / this.routesTotal * this.routesDone)/100))
    },
    uploading(){
      return this.$store.state.data.uploadingImage
    },
    detach(){
      return this.$store.state.data.detach
    },
    user () {
      return this.$store.state.data.user
    },
    routes () {
      return this.$store.state.data.routes
    },
    filteredRoutes(){
      let fRoutes = this.routes
      if (this.toggleFilter){
        if (this.filterHolds !== 'Grifffarbe'){
          fRoutes = fRoutes.filter(route => route.color && route.color == this.filterHolds)
        }
        if (this.filterOffen){
          fRoutes = fRoutes.filter(route => route.flashedBy && !route.flashedBy.includes(this.user.fId))
          fRoutes = fRoutes.filter(route => route.toppedBy && !route.toppedBy.includes(this.user.fId))
        }
        if (this.filterLevel !== 'Schwierigkeit'){
          fRoutes = fRoutes.filter(route => route.color && route.level == this.filterLevel.level)
        }
      }
      
        
        
      return fRoutes 
    },
    routesLength () {
      return this.$store.state.data.routes.length
    },
     holdColors(){
      return this.$store.state.data.basics.holdColors;
    },
    levels(){
      var index = this.$store.state.data.basics.levels.findIndex(x => x.name === this.gym.scale);
      if (index !== -1){
        return this.$store.state.data.basics.levels[index].level;
      } else {
        return [];
      }
    },
    customlevels(){
      return this.$store.state.data.homeGym.customScale;
    },
    wall(){
      return this.$store.state.data.walls[this.$store.state.data.orientation.currentWallIndex]
    },
    gym(){
        return this.$store.state.data.homeGym
    },
    editMode(){
      return this.$store.state.data.editMode
    }
  },
  mounted(){
    this.$store.commit('resetMoveBackwards');
    if (this.newWall == 'true'){
    //Neue Wand
      if (this.detach.wall !== null){
      this.$store.commit('detachWall');
      this.$store.commit('clearRoutes');
      }

    this.$store.dispatch('loadRoutesforCurrentWall', db.collection("Gyms/"+ this.gym.id +"/walls/" + this.wallId + "/routes"));

  } else {
    if (this.routesLength == 0){
      if (this.detach.wall == null){
      this.$store.dispatch('loadRoutesforCurrentWall', db.collection("Gyms/"+ this.gym.id +"/walls/" + this.wallId + "/routes"));
    }
    }
  }
  },
  methods:{
    
    //----------------------------------------------//
    //-----------------ANIMATIONS-------------------//
    //----------------------------------------------//
    
    fadeInGrowEnter(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: [0,1],
        'max-height': '500px',
        complete: done
      });
    },
    fadeInGrowLeave(el, done){
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: 0,
        'max-height': '0px',
        complete: done
      });
    },
    
    
    wallNameEnter(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: [0,1],
        complete: done
      });
    },
    wallNameLeave(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 250,
        opacity: 0,
        complete: done
      });
    },
    
    editButtonsEnter(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
      var tl = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 250
      });
      tl
      .add({
        targets: el,
        'max-height': '500px',
      })
      .add({
        targets: sections,
        opacity: [0,1],
        delay: anime.stagger(100),
      })
    },
    editButtonsLeave(el, done) {
      const sections = Array.from(el.querySelectorAll("section"))
       var tl = anime.timeline({
          easing: 'easeInOutCubic',
          duration: 250
        });
        tl
        .add({
          targets: sections.reverse(),
          opacity: 0,
          delay: anime.stagger(100),
        })
        .add({
          targets: el,
          'max-height': '0px',
          complete: done
        })
    },
    
    //----------------------------------------------//
    //----------------TOUCH ACTIONS-----------------//
    //----------------------------------------------//
    
    routeDragStart(e){
      
      this.dragItem = e.srcElement.closest("#route");
      this.dragItem2 = this.dragItem.parentNode.querySelector("#info");
      
      
      if (e.type === "touchstart") {
        this.initialX = e.touches[0].clientX;
        this.initialY = e.touches[0].clientY;
      } else {
        this.initialX = e.clientX;
        this.initialY = e.clientY;
      }
    },
    
    routeDragEnd(event){
      var that = this;
      this.activeDragDirection = ''
      this.fadeIn = false;
      this.draggedRouteId = this.dragItem2.dataset.id;
      this.draggedRoute = this.routes.findIndex(x => x.id === this.dragItem2.dataset.id);
      
      if(this.topFlash !== ''){
        if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.vibrateSuccess){
              window.webkit.messageHandlers.vibrateSuccess.postMessage('vibrating..');
            }
      }
      //bounce back
          anime({
            targets: this.dragItem,
            easing: 'easeInOutCubic',
            duration: 250,
            translateX: 0,
            complete: function(anim) {
              if(this.topFlash !== ''){
              that.click(that.topFlash);
              }
            }
          });
          
          anime({
            targets: this.dragItem2,
            easing: 'easeInOutCubic',
            duration: 250,
            opacity:[1,0],
            background:'#FCD34D',
            width:0
          });
    },
    
    routeDrag(e){
      
      if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
      }
      
      let distance = Math.abs(this.currentX) - Math.abs(this.currentY) 
     
      if(Math.abs(distance) > 10 && this.activeDragDirection == ''){
        if(Math.abs(this.currentX) > Math.abs(this.currentY) ){
          this.activeDragDirection = 'horizontal';
        } else {
          this.activeDragDirection = 'vertical';
        }
      }
      
      if(this.activeDragDirection == 'horizontal' && this.currentX > 0 ){
        e.preventDefault();
        
        anime.set(this.dragItem, {
            translateX: this.currentX
          });
          
        anime.set(this.dragItem2, {
            width: this.currentX + 50
          });
          
        if(!this.fadeIn && this.currentX > 35){
          this.fadeIn = true;
          anime({
            targets: this.dragItem2,
            easing: 'easeInOutCubic',
            duration: 250,
            opacity:[0,1],
          });
        }
        
        if(this.currentX > this.dragItem.offsetWidth * 0.5 && this.topFlash == ''){
          this.topFlash = "Top"
          if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.vibrateLight){
            window.webkit.messageHandlers.vibrateLight.postMessage('vibrating..');
          }
          anime({
            targets: this.dragItem2,
            easing: 'easeInOutCubic',
            duration: 250,
            background: '#A78BFA'
          });
        } else if(this.currentX > this.dragItem.offsetWidth && this.topFlash == 'Top'){
          this.topFlash = 'Flash'
          if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.vibrateLight){
            window.webkit.messageHandlers.vibrateLight.postMessage('vibrating..');
          }
          anime({
            targets: this.dragItem2,
            easing: 'easeInOutCubic',
            duration: 250,
            background: '#7C3AED'
          });
        } else if(this.currentX < this.dragItem.offsetWidth && this.topFlash == 'Flash'){
          this.topFlash = 'Top'
          if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.vibrateLight){
            window.webkit.messageHandlers.vibrateLight.postMessage('vibrating..');
          }
          anime({
            targets: this.dragItem2,
            easing: 'easeInOutCubic',
            duration: 250,
            background: '#A78BFA'
          });
        } else if(this.currentX < this.dragItem.offsetWidth * 0.5 && this.topFlash !== ''){
          this.topFlash = ''
          if(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.vibrateLight){
            window.webkit.messageHandlers.vibrateLight.postMessage('vibrating..');
          }
          anime({
            targets: this.dragItem2,
            easing: 'easeInOutCubic',
            duration: 250,
            background: '#FCD34D'
          });
        }
      }
    },
    
    //----------------------------------------------//
    //------------------FUNCTIONS-------------------//
    //----------------------------------------------//
    
    translateBasics(aim, item){
      let tag = item.toLowerCase();
      tag = tag.replace('ü', 'ue');
      tag = tag.replace('ä', 'ae');
      tag = tag.replace('ö', 'oe');
      tag = tag.replace('ß', 'ss');
      tag = tag.replace(' ', '_');
      if(this['langBasic' + aim][tag]){
      return this['langBasic' + aim][tag]
      } else {
        return item
      }
    },
    
    checkGymName(name){
      if(name && name == 'Neue Halle'){
        return this.lang.newGym;
      } else {
        return name
      }
    },
    
    checkWallName(name){
      if(name && name == 'Neue Wand'){
        return this.lang.newWall;
      } else {
        return name
      }
    },
    
    checkSectionName(name){
      if(name && name == 'Neuer Abschnitt'){
        return this.lang.newSection;
      } else {
        return name
      }
    },
    
    click(status) {
      this.topFlash = '';
      this.$store.commit('updateActiveUser', {gym: this.gym.id, update:false});
      if (status == "Flash"){
        if (this.routes[this.draggedRoute].flashedBy.includes(this.user.fId)){
          //schon geflashed -> Offen
          return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId).update({
             flashedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
           })
           .then(() => {
            //remove rating & tendenz
            if(this.currentRating !== 0){
              var aim = "rating." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId);
              this.$store.dispatch('updateRating', {aim:aim, ref:ref, user: this.user.fId, rating: 0});
            }
            if(this.currentTendenz !== ''){
              var aim = "tendenz." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId)
              this.$store.dispatch('updateTendenz', {aim:aim, ref:ref, user: this.user.fId, tendenz: ''});
            }
            //add user stats
            var updateObj = {};
            if (this.routes[this.draggedRoute].tags){
            for(var i = 0; i < this.routes[this.draggedRoute].tags.length; i++){
             updateObj['stats.' + this.routes[this.draggedRoute].tags[i] + '.flashed'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
            }
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj);
           
         });
        } else {
          var preTopped = false;
           if (this.routes[this.draggedRoute].toppedBy.includes(this.user.fId)){
              preTopped = true;
            }
        return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId).update({
           flashedBy: firebaseRoot.firestore.FieldValue.arrayUnion(this.user.fId),
           toppedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
         })
         .then(() => {
            //add user stats
            var updateObj = {};
            if (this.routes[this.draggedRoute].tags){
            for(var i = 0; i < this.routes[this.draggedRoute].tags.length; i++){
             updateObj['stats.' + this.routes[this.draggedRoute].tags[i] + '.flashed'] = firebaseRoot.firestore.FieldValue.increment(1) ;
            }
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj)
            .then(() => {
              if (preTopped == true){
                //Remove 'topped' stats if it was topped before
              var updateObj = {};
              if (this.routes[this.draggedRoute].tags){
              for(var i = 0; i < this.routes[this.draggedRoute].tags.length; i++){
              updateObj['stats.' + this.routes[this.draggedRoute].tags[i] + '.topped'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
              }
              }
              return db.collection("Users").doc(this.user.fId).update(updateObj);
              }
            });
            
           
         });
       }
      }

      if (status == "Top"){
        if (this.routes[this.draggedRoute].toppedBy.includes(this.user.fId)){
          //schon getoppt -> Offen
          return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId).update({
             toppedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
           })
           .then(() => {
             //remove rating & tendenz
            if(this.currentRating !== 0){
              var aim = "rating." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId);
              this.$store.dispatch('updateRating', {aim:aim, ref:ref, user: this.user.fId, rating: 0});
            }
            if(this.currentTendenz !== ''){
              var aim = "tendenz." + this.user.fId;
              var ref = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId);
              this.$store.dispatch('updateTendenz', {aim:aim, ref:ref, user: this.user.fId, tendenz: ''});
            }
            //add user stats
            var updateObj = {};
            for(var i = 0; i < this.routes[this.draggedRoute].tags.length; i++){
             updateObj['stats.' + this.routes[this.draggedRoute].tags[i] + '.topped'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj);
           
         });
        } else {
          var preFlashed = false;
           if (this.routes[this.draggedRoute].flashedBy.includes(this.user.fId)){
              preFlashed = true;
            }
        return db.collection("Gyms/" + this.gym.id + "/walls/" + this.wall.id + "/routes").doc(this.draggedRouteId).update({
           toppedBy: firebaseRoot.firestore.FieldValue.arrayUnion(this.user.fId),
           flashedBy: firebaseRoot.firestore.FieldValue.arrayRemove(this.user.fId)
         })
         .then(() => {
            //add user stats
            var updateObj = {};
            for(var i = 0; i < this.routes[this.draggedRoute].tags.length; i++){
             updateObj['stats.' + this.routes[this.draggedRoute].tags[i] + '.topped'] = firebaseRoot.firestore.FieldValue.increment(1) ;
            }
            return db.collection("Users").doc(this.user.fId).update(updateObj)
            .then(() => {
              if (preFlashed == true){
                //Remove 'flashed' stats if it was flashed before
              var updateObj = {};
              for(var i = 0; i < this.routes[this.draggedRoute].tags.length; i++){
              updateObj['stats.' + this.routes[this.draggedRoute].tags[i] + '.flashed'] = firebaseRoot.firestore.FieldValue.increment(-1) ;
              }
              return db.collection("Users").doc(this.user.fId).update(updateObj)
              }
            });
           
         });
       }
      }
    },
    
    age(r){
      var days = Math.ceil((r.toDate() - new Date()) / (1000 * 3600 * 24 * -1));
      if (days < 7){
        return "new";
      } else {
        return "old";
      }
    },
    
    umlaut(value){
      var newColor = value.replace('ü', 'ue');
      newColor = newColor.replace('ä', 'ae');
      newColor = newColor.replace('ö', 'oe');
      newColor = newColor.replace(' ', '_');
     return newColor;
    },
    previewTags(tags){
      var str = '';
      if (typeof tags !== 'undefined'){
      for (var i = 0; i < tags.length && i < 2; i++) {
        if (i == tags.length-1){
          str = str + tags[i]
        } else {
          if (i == 2){
            str = str + tags[i] + "..."
          } else {
            str = str + tags[i] + ", "
          }

        }
      }
      return str
    }
    },
    deleteWall(){
      
      var deleteWall = functions.httpsCallable('deleteWall');
      deleteWall({ 
        gymId: this.gym.id,
        wallId: this.wallId
      })
      .then((result) => {
        if(result.data){
          this.errorMessageWall = this.lang.error2
          //result.data.text
        }
      });

    },
    goToRoute(routeId, index){
     this.$router.push({name: 'Routes', params:{ gymId: this.gym.id, wallId: this.wallId, routeId: routeId, currentRouteIndex: index }})
    },
    changeWallName(newWallName){
      var check = newWallName.replace(/\s/g,'').length;
      if (check == 0 || newWallName == ''){
        this.errorMessage = this.lang.error3
      } else {
         var currentWall = db.collection("Gyms/" + this.gym.id + "/walls");
          return currentWall.doc(this.wallId).update({
            name: newWallName
          })
          .then(() => {
            this.newWallName = '';
          })
      }
    },
    deleteSection(index){
      var currentWall = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wallId + "/routes");
      var currentSection = this.routes[index].id;
      this.$store.dispatch('deleteSection', {aim:currentWall, section:currentSection});
      this.currentIndex = '';

    },
    editSection(index){
     if (this.editMode == true){
        this.currentIndex = index;
      }
    },
    hideSectionEdit(newName, id){
      var check = newName.replace(/\s/g,'').length;
      if (check !== 0 && newName !== '' && typeof newName !== 'undefined'){
      var currentWall = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wallId + "/routes");
      return currentWall.doc(id).update({
        name: newName
      })
      .then(() => {
        this.currentIndex = null;
      })
      }
    },
    moveObj(index, direction){
      var selectedOrder = this.routes[index].order;
      var selectedId = this.routes[index].id;
      var moveToOrder = this.routes[index+direction].order;
      var moveToId = this.routes[index+direction].id;
      var aim = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wallId + "/routes");
      //Erstes Objekt ändern
      this.$store.dispatch('moveWallorSection', {
        order: selectedOrder,
        id: moveToId,
        aim: aim
      });
      //Zweites Objekt ändern
      this.$store.dispatch('moveWallorSection', {
        order: moveToOrder,
        id: selectedId,
        aim: aim
      });
      this.currentIndex = this.currentIndex + direction;
    },
    addObj(index){
      var newIndex = 0;
      if (index == "start"){
      //Entrypoint Neue Leere Halle
      if (typeof this.routes[0] === 'undefined'){
        var drunter = "0";
        var drueber = "1";
      } else {
        //Entrypoint Start
        var drunter = "0";
        var drueber = this.routes[0].order;
      }

    } else {
      if (typeof this.routes[index+1] === 'undefined'){
        //Entrypoint Ende
        var drunter = this.routes[index].order;
        var drueber = this.routes[index].order;
      } else {
        //Entrypoint Mitte
        var drunter = this.routes[index].order;
        var drueber = this.routes[index+1].order;
      }
      newIndex = index + 1
    }
      var aim = db.collection("Gyms/" + this.gym.id + "/walls/" + this.wallId + "/routes");
      var aim2 = db.collection("Gyms/" + this.gym.id + "/walls").doc(this.wall.id);
      this.$store.commit('updateActiveUser', {gym: this.gym.id, update:false});
      this.$store.dispatch('addWallOrSection', {drunter:drunter, drueber:drueber, type:this.entryType, aim:aim, aim2: aim2, gym:this.gym.id, wall: this.wall.id, index:newIndex});
    },
    changeImage(e){
      var d = new Date();
      var ms = d.getMilliseconds();
      var aim = db.collection("Gyms/" + this.gym.id + "/walls").doc(this.wallId);
      var path = this.gym.id + "/" + this.wallId + "/"
      this.$store.commit('toggleUploading');
      this.$store.dispatch('changeGymImage', {e:e, ref:this.wallId, ms:ms, aim:aim, path:path});
    }
  }
}

</script>

<style>
.defaultBg{
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%237c3aed' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.section{
  @apply relative w-full h-9 ml-9 font-ageo font-normal text-lg text-gray-800 flex flex-row justify-between items-center
}

.uploadImage{
  @apply absolute w-full h-full inset-0 appearance-none hidden opacity-0
}

</style>
